import request from '@/plugins/requests'
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        original: {
            id: null,
            site_id: null,
            data_center_id: null,
            name: null,
            remark: null,
            admin_id: null,
            members_id: []
        },
        project: {
            id: null,
            site_id: null,
            data_center_id: null,
            name: null,
            remark: null,
            admin_id: null,
            members_id: []
        }
    },
    getters: {
        original: (state) => state.original,
        project: (state) => state.project,
    },
    mutations: {
        setProject: (state, payload) => (state.project = payload),
        setOriginal: (state, payload) => (state.original = payload),
        setProjectAttr: (state, { key, val }) => {
            state.project[key] = val
        },
        add_member: (state, id) => {
            if (!state.project['members_id'].includes(id))
                state.project['members_id'].push(id);
        },
        remove_member: (state, id) => {
            let index = state.project['members_id'].indexOf(id)
            if (~index)
                state.project['members_id'].splice(index, 1);
        },
    },
    actions: {
        fetchUserProjects: ({ commit }, userId) => {
            request
                .get(`api/user/${userId}/involved-projects`)
                .then(({ data }) => {
                    commit('setUserProjects', data);
                })
        },
    }
}