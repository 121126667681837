<template>
  <v-overlay :key="keyOp" :z-index="999" :absolute="absolute" v-model="overlay">
    <div class="mx-auto xflex xflex-col xjustify-center xitems-center">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
      <span class="xtext-white xmy-2 xtext-[18px] xfont-bold">
        {{ message }}
      </span>
    </div>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      keyOp: 1,
      overlay: false,
      absolute: false,
      message: "Processing...",
    };
  },
  mounted() {
    this.$event.$on("global_loader", (open, message, absolute) => {
      this.keyOp++;
      this.overlay = open;
      this.absolute = absolute || false;
      this.message = message || this.message;
    });
  },
};
</script>

<style lang="scss" scoped></style>
