<template>
  <v-container fluid class="xm-0 xp-0 xmin-h-screen xbg-[#F3F3F3]">
    <router-view :key="$route.fullPath"></router-view>
    <HomeFooter></HomeFooter>
    <LegalitiesModal></LegalitiesModal>
  </v-container>
</template>

<script>
import HomeFooter from "@/views/GlobalComponents/Headers/HomeFooter.vue";
import LegalitiesModal from "@/views/GlobalComponents/Modals/LegalitiesModal.vue";
export default {
  components: { HomeFooter, LegalitiesModal },
};
</script>

<style lang="scss" scoped></style>
