var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","min-height":"80vh","color":"transparent"}},[_c('v-card-title',{staticClass:"xflex xflex-row xjustify-between xgap-[0.5em] xitems-center xw-full xpx-0"},[_c('div',{staticClass:"xflex xflex-row xitems-center xgap-[0.5em]",class:[
        _vm.mdUp || _vm.tablet
          ? '!xflex-row xflex-nowrap xjustify-between'
          : '!xflex-col xw-full' ]},[_c('KirbyMonthPicker',{class:[_vm.mdUp ? '' : 'xw-full'],attrs:{"value":_vm.selectedYearMonth,"no-future":""},on:{"input":_vm.navigate}}),_c('v-select',{staticClass:"xw-full md:xmax-w-[200px]",attrs:{"outlined":"","dense":"","hide-details":"auto","item-text":"text","item-value":"value","items":[
          { text: 'All', value: '' },
          { text: 'Approved', value: 'approved' },
          { text: 'Pending', value: 'pending' },
          { text: 'Denied', value: 'denied' } ]},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(_vm.user.is_paying)?_c('btn-tooltip',{staticClass:"text-none",attrs:{"tip":"Add Paid Time Off","color":"primary","block":_vm.smDown,"disabled":false,"loading":false},on:{"click":_vm.addPto}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add PTO ")],1):_vm._e()],1),(_vm.items.length)?_c('app-table',{attrs:{"loading":_vm.fetching,"headings":_vm.header,"items":_vm.items},scopedSlots:_vm._u([{key:"date",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.date,"ll")))])]}},{key:"range",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("secToHuman")(item.duration_seconds," hours", " minutes")))])]}},{key:"created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.created_at,"ll")))])]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.status == 'approved')?_c('chip-in',{attrs:{"small":"","text":"Approved"}}):(item.status == 'pending')?_c('chip-out',{attrs:{"small":"","text":"Pending"}}):_c('chip-brb',{attrs:{"small":"","text":"Denied"}})]}},{key:"handler",fn:function(ref){
        var item = ref.item;
return [(item.denied_user)?_c('router-link',{attrs:{"to":{
          name: 'app-user-record-monthly',
          params: {
            id: item.denied_user.id,
            year_month: _vm.yearMonth,
          },
        }}},[_c('avatar',{attrs:{"size":"36","user":item.denied_user,"with-name":""}})],1):(item.approved_user)?_c('router-link',{attrs:{"to":{
          name: 'app-user-record-monthly',
          params: {
            id: item.approved_user.id,
            year_month: _vm.yearMonth,
          },
        }}},[_c('avatar',{attrs:{"size":"36","user":item.approved_user,"with-name":""}})],1):_c('span',[_vm._v("None")])]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[(_vm.$can.and('manage_user_ptos') && item.status !== 'approved')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.approvePTO(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-clock-check-outline")]),_vm._v(" Approve PTO ")],1)],1):_vm._e(),(_vm.$can.and('manage_user_ptos') && item.status !== 'denied')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.denyPTO(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-clock-remove-outline")]),_vm._v(" Deny PTO ")],1)],1):_vm._e(),(item.status !== 'approved')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.deletePTO(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-clock-alert-outline")]),_vm._v(" Delete PTO ")],1)],1):_vm._e()],1)],1)]}}],null,false,3946837125)}):(_vm.fetching)?_c('app-empty',{staticClass:"xmin-h-[300px] xh-full",attrs:{"text":"Loading PTO...","color":"transparent"}}):_c('app-empty',{staticClass:"xmin-h-[300px] xh-full",attrs:{"text":"No PTO found.","color":"transparent"}}),(_vm.next_page_url)?_c('v-card-actions',{staticClass:"xw-full xflex xflex-col xjustify-center xitems-center",class:[_vm.mdUp ? 'xh-[60px]' : '']},[_c('btn-tooltip',{attrs:{"tip":"Load more","color":"primary","outlined":"","loading":_vm.feching_more,"disabled":_vm.feching_more},on:{"click":_vm.fetchMore}},[_vm._v(" Load more ")])],1):_vm._e(),_c('AddPTOModal',{attrs:{"disable-select-user":"","user":_vm.user},model:{value:(_vm.add_pto_modal),callback:function ($$v) {_vm.add_pto_modal=$$v},expression:"add_pto_modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }