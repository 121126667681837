<template>
  <v-datetime-picker
    v-bind="$attrs"
    label=""
    :dialog-width="350"
    :text-field-props="textFieldProps"
    :date-picker-props="datePickerProps"
    :time-picker-props="timePickerProps"
    clear-text="Cancel"
    time-format="HH:mm:ss"
    ok-text="Set"
    :value="value"
    @input="(v) => $emit('input', v)"
  >
    <template v-slot:dateIcon>
      <v-icon>mdi-calendar-month-outline</v-icon>
    </template>
    <template v-slot:timeIcon>
      <v-icon>mdi-clock-time-five-outline</v-icon>
    </template>
  </v-datetime-picker>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: [String, Object], default: null },
    label: { type: String, default: "" },
    min: { type: [String, Object], default: undefined },
    max: { type: [String, Object], default: undefined },
  },
  data() {
    return {
      textFieldProps: {
        outlined: true,
        dense: true,
        hideDetails: "auto",
        color: "primary",
        label: this.label,
        appendIcon: "mdi-calendar-clock",
      },
      datetime: null,
      datePickerProps: {
        min: this.min,
        max: this.max,
      },
      timePickerProps: {
        ampmInTitle: true,
        min: this.min,
        max: this.max,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
