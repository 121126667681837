import Index from '@/views/AdminDashboard/Index.vue';
import Dashboard from '@/views/AdminDashboard/Dashboard/Dashboard.vue';
import Configs from '@/views/AdminDashboard/Configs/Configs.vue'
import Subscribers from '@/views/AdminDashboard/Subscribers/Subscribers.vue'
import Workspace from '@/views/AdminDashboard/Subscribers/Workspace.vue'
import Roles from '@/views/AdminDashboard/Roles/Roles.vue'
import Databases from '@/views/AdminDashboard/Databases/Databases.vue'
import Feedbacks from '@/views/AdminDashboard/Feedbacks/Feedbacks.vue'
import Supports from '@/views/AdminDashboard/Supports/Support.vue'
import Backlogs from '@/views/AdminDashboard/Backlogs/Backlogs.vue'

export const adminRoutes = {
    path: '/admin-dashboard',
    component: Index,
    children: [
        {
            path: '/',
            name: 'admin-dashboard',
            component: Dashboard,
            meta: { auth: true, super_admin: true, breads: [{ text: 'Admin Dashboard', disabled: true }] }
        },
        {
            path: 'configs',
            name: 'admin-configs',
            component: Configs,
            meta: { auth: true, super_admin: true, breads: [{ text: 'Admin Configuration', disabled: true }] }
        },
        {
            path: 'subscribers',
            component: Subscribers,
            children: [
                {
                    path: 'subscribed',
                    name: 'admin-subscribers-subscribed',
                    props: { filter: 'subscribed' },
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Teams.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: true }, { text: 'Subscribed', disabled: true }] }
                },
                {
                    path: 'cancelled',
                    name: 'admin-subscribers-cancelled',
                    props: { filter: 'cancelled' },
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Teams.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Cancelled', disabled: true }] }
                },
                {
                    path: 'exempted',
                    name: 'admin-subscribers-exempted',
                    props: { filter: 'exempted' },
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Teams.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Exempted', disabled: true }] }
                },
                {
                    path: 'disabled',
                    name: 'admin-subscribers-disabled',
                    props: { filter: 'disabled' },
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Teams.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Disabled', disabled: true }] }
                },
                {
                    path: 'ongoing-free-trial',
                    name: 'admin-subscribers-ongoing-free-trial',
                    props: { filter: 'ongoing-free-trial' },
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Teams.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Ongoing Free Trial', disabled: true }] }
                },
                {
                    path: 'expired-free-trial',
                    name: 'admin-subscribers-expired-free-trial',
                    props: { filter: 'expired-free-trial' },
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Teams.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Expired Free Trial', disabled: true }] }
                },
            ],
        },
        {
            path: 'workspace/:id',
            component: Workspace,
            children: [
                {
                    path: 'info',
                    name: 'admin-workspace-info',
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Info.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Team Info', disabled: true }] }
                },
                {
                    path: 'members',
                    name: 'admin-workspace-members',
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Members.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Team Members', disabled: true }] }
                },
                {
                    path: 'configs',
                    name: 'admin-workspace-configs',
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Configs.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Team Configs', disabled: true }] }
                },
                {
                    path: 'bot-responses',
                    name: 'admin-workspace-bot-responses',
                    component: () => import('@/views/AdminDashboard/Subscribers/subs/Responses.vue'),
                    meta: { auth: true, super_admin: true, breads: [{ text: 'Teams', disabled: false, route: { name: 'admin-subscribers-subscribed' } }, { text: 'Team Bot Responses', disabled: true }] }
                },
            ],
        },
        {
            path: 'database',
            name: 'admin-database',
            component: Databases,
            meta: { auth: true, super_admin: true, breads: [{ text: 'Database', disabled: true }] }
        },
        {
            path: 'roles',
            name: 'admin-roles',
            component: Roles,
            meta: { auth: true, super_admin: true, breads: [{ text: 'Roles', disabled: true }] }
        },
        {
            path: 'feedbacks',
            name: 'admin-feedbacks',
            component: Feedbacks,
            meta: { auth: true, super_admin: true, breads: [{ text: 'Feedbacks', disabled: true }] }
        },
        {
            path: 'supports',
            name: 'admin-supports',
            component: Supports,
            meta: { auth: true, super_admin: true, breads: [{ text: 'Support', disabled: true }] }
        },
        {
            path: 'backlogs',
            name: 'admin-backlogs',
            component: Backlogs,
            meta: { auth: true, super_admin: true, breads: [{ text: 'Back Logs', disabled: true }] }
        }

    ],
    meta: {
        auth: true,
        super_admin: true
    }
}
