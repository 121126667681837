<template>
  <v-menu
    v-model="popup"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    bottom
    right
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="masked_value"
        append-icon="mdi-clock"
        readonly
        placeholder="Select Time"
        dense
        :disabled="disabled"
        class="xtext-center"
        outlined
        hide-details="auto"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-card
      max-width="300"
      color="base-100"
      class="kirby-datetime-select-wrapper xflex xflex-col xflex-1"
    >
      <KirbyTimePicker
        :min="minimumTime"
        :max="maximumTime"
        v-model="time"
        :base-date="baseDate"
      ></KirbyTimePicker>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
import KirbyTimePicker from "./KirbyTimePicker.vue";
export default {
  components: { KirbyTimePicker },
  props: {
    value: { type: String, default: "00:00:00" }, //HH:mm:ss
    min: { type: String, default: "00:00:00" }, //HH:mm:ss
    max: { type: String, default: "23:59:00" }, //HH:mm:ss
    baseDate: { type: String, default: () => moment().format("YYYY-MM-DD") }, //YYYY-MM-DD
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    date: null,
    time: null,
    popup: false,
    format1: "HH:mm:ss",
    format2: "YYYY-MM-DD",
    format3: "YYYY-MM-DD HH:mm:ss",
  }),
  watch: {
    value: {
      handler: function (val) {
        this.time = val ? val : null;
      },
      immediate: true,
    },
    time: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  computed: {
    masked_value() {
      if (this.value) {
        return moment(`${this.baseDate} ${this.value}`, this.format3).format(
          this.format1
        );
      } else return "Select Time";
    },
    minimumTime() {
      if (!this.min || !this.baseDate) return null;
      return moment(`${this.baseDate} ${this.min}`, this.format3).format(
        this.format3
      );
    },
    maximumTime() {
      if (!this.max || !this.baseDate) return null;
      return moment(`${this.baseDate} ${this.max}`, this.format3).format(
        this.format3
      );
    },
  },
  methods: {
    cancel() {
      this.$emit("input", this.value);
      this.popup = false;
    },
  },
};
</script>

<style lang="scss">
.kirby-datetime-select-wrapper {
  overflow: hidden;
  .v-picker {
    border-radius: 0 !important;
    border: none !important;
  }
}
</style>
