<template>
  <v-card flat min-height="80vh" color="transparent" class="xmt-4">
    <app-table
      :loading="fetchingPending"
      :headings="header"
      :items="pendingEdits"
      v-if="pendingEdits.length"
    >
      <template v-slot:command="{ item }">
        <chip-in
          v-if="['in', 'switch', 'back'].includes(item.command)"
          :text="item.command"
        >
        </chip-in>
        <chip-brb v-else-if="item.command === 'brb'" :text="item.command">
        </chip-brb>
        <chip-out v-else :text="item.command"> </chip-out>
      </template>
      <template v-slot:old="{ item }">
        <span> {{ item.old_created_at | format("LLL") }} </span>
      </template>
      <template v-slot:new="{ item }">
        <span> {{ item.new_created_at | format("LLL") }} </span>
      </template>
      <template v-slot:diff="{ item }">
        <span> {{ getDifference(item) }} </span>
      </template>
      <template v-slot:status="{ item }">
        <span> {{ item.status ? item.status : "pending" | ucwords }} </span>
      </template>
      <template v-slot:actions="{ item, index }">
        <icon-list icon="mdi-dots-horizontal">
          <v-list dense>
            <v-list-item @click.stop="cancelEdit(item, index)">
              <v-list-item-title class="xtext-[#2E2E2E]">
                <v-icon color="#2E2E2E" left>mdi-trash-can-outline</v-icon>
                Cancel Edit
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="item.status != 'denied'"
              @click.stop="sendEdit(item)"
            >
              <v-list-item-title class="xtext-[#2E2E2E]">
                <v-icon color="#2E2E2E" left>mdi-file-move-outline</v-icon> Send
                Time Edit for Approval
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="$can.and('manage_user_time')"></v-divider>
            <v-list-item
              @click.stop="approvedEdit(item)"
              v-if="$can.and('manage_user_time')"
            >
              <v-list-item-content>
                <v-list-item-title class="xtext-[#2E2E2E]">
                  <v-icon color="#2E2E2E" left>mdi-file-check-outline</v-icon>
                  Self Approved
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                  class="xtext-[#2E2E2E] xtext-right xtext-[9px]"
                >
                  Admin/Manager Permission
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </icon-list>
      </template>
    </app-table>
    <app-empty
      text="Loading Pending Edits..."
      color="transparent"
      class="xmin-h-[300px] xh-full"
      v-else-if="fetchingPending"
    ></app-empty>
    <app-empty
      text="No Pending Edits"
      color="transparent"
      class="xmin-h-[300px] xh-full"
      v-else
    ></app-empty>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      header: [
        { text: "Command", value: "command", sortable: false },
        { text: "Old Time", value: "old", sortable: false },
        { text: "New Time", value: "new", sortable: false },
        {
          text: "Time Difference",
          value: "diff",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          width: 100,
          align: "right",
        },
      ],
      sending: false,
      pendingEdits: [],
      fetchingPending: false,
    };
  },
  created() {
    this.fetchPendingEdits();
    this.$event.$on("refetch-pending-edits", () => this.fetchPendingEdits());
  },
  methods: {
    sendEdit(item) {
      this.appConfirmation(
        `You're about to submit this time edit for approval to the Slack team admins. Do you want to proceed?`,
        () => {
          this.sending = true;
          this.$axios
            .post(`api/records/my/resend-for-approval/${item.id}`, {})
            .then(({ data }) => {
              this.appToast(data.message, data.type);
              this.open = false;
            })
            .finally(() => (this.sending = false));
        },
        null,
        "Continue"
      );
    },
    approvedEdit(item) {
      this.appConfirmation(
        `You're about to approve this time edit. No notification will be sent to Slack admins or managers. Do you want to continue?`,
        () => {
          this.sending = true;
          this.$axios
            .post(`api/records/my/self-approval/${item.id}`, {})
            .then(({ data }) => {
              this.appToast(data.message, data.type);
              this.open = false;
              this.fetchPendingEdits();
            })
            .finally(() => (this.sending = false));
        },
        null,
        "Continue"
      );
    },
    getDifference(item) {
      let old = moment(item.old_created_at);
      let newer = moment(item.new_created_at);
      return this.durationFormat(old, newer, "H [hours] m [minutes]");
    },
    fetchPendingEdits() {
      this.fetchingPending = true;
      this.$axios
        .get(`api/records/my/pending-edits`)
        .then(({ data }) => {
          this.pendingEdits = data;
        })
        .finally(() => (this.fetchingPending = false));
    },
    cancelEdit(item, index) {
      this.appConfirmation(
        "Are you sure you want to cancel this time edit?",
        () => {
          this.$axios
            .put(`api/records/my/pending-edits/${item.id}/cancel`, {})
            .then(({ data }) => {
              this.pendingEdits.splice(index, 1);
            });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
