<template>
  <v-menu
    v-model="popup"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    bottom
    right
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="masked_value"
        prepend-inner-icon="mdi-calendar-clock"
        readonly
        dense
        :disabled="disabled"
        class="xtext-center"
        outlined
        hide-details="auto"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-card
      max-width="530"
      color="base-100"
      :class="mobile ? `xgrid-cols-1`:`xgrid-cols-2`"
      class="kirby-datetime-wrapper xgrid "
    >
      <!-- :picker-date="year_month" -->
      <v-date-picker
        dense
        flat
        no-title
        show-adjacent-months
        :min="minimumDate"
        :max="maximumDate"
        v-model="date"
      >
      </v-date-picker>
      <v-sheet class="xh-full xflex xflex-col xjustify-center xitems-center">
        <div
          class="xflex xflex-col xfont-semibold xtext-center xpy-[10px] xjustify-center xitems-center"
        >
          {{ masked_datetime }}
        </div>
        <KirbyTimePicker
          :min="minimumTime"
          :max="maximumTime"
          v-model="time"
          :base-date="date"
        ></KirbyTimePicker>
        <div
          v-if="hasButtons"
          class="xflex xw-full xflex-row xgap-[5px] xjustify-center xitems-center"
        >
          <v-btn @click="cancel" outlined color="primary"> Cancel </v-btn>
          <v-btn @click="setDateTime" color="primary"> Set </v-btn>
        </div>
      </v-sheet>
      <!-- <div class="xcol-span-2">
        {{ year_month }}, {{ date }},
        {{ time }}
      </div> -->
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
import KirbyTimePicker from "./KirbyTimePicker.vue";
export default {
  components: { KirbyTimePicker },
  props: {
    value: { type: String, default: null },
    displayFormat: { type: String, default: "LLL" },
    outputFormat: { type: String, default: "YYYY-MM-DD HH:mm:ss" },
    min: { type: String, default: null }, //YYYY-MM-DD HH:mm:ss
    max: { type: String, default: null }, //YYYY-MM-DD HH:mm:ss
    hasButtons: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    noFuture: { type: Boolean, default: false },
    noPast: { type: Boolean, default: false },
  },
  data: () => ({
    date: null,
    time: null,
    popup: false,
    format1: "HH:mm:ss",
    format2: "YYYY-MM-DD",
    format3: "YYYY-MM-DD HH:mm:ss",
  }),
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.date = moment(val, this.format3).format(this.format2);
          this.time = moment(val, this.format3).format(this.format1);
        } else {
          this.date = null;
          this.time = null;
        }
      },
      immediate: true,
    },
    datetime: {
      handler: function (val) {
        if (!this.hasButtons) this.$emit("input", val);
      },
      immediate: true,
    },
  },
  computed: {
    // year_month() {
    //   if (this.value) {
    //     return moment(this.value, this.format3).format("YYYY-MM");
    //   } else return undefined;
    // },
    masked_value() {
      if (this.value) {
        return moment(this.value, this.format3).format(this.displayFormat);
      } else return "Select Date & Time";
    },
    masked_datetime() {
      if (this.date && this.time) {
        return moment(`${this.date} ${this.time}`, this.format3).format(
          this.displayFormat
        );
      } else return null;
    },
    datetime() {
      if (this.date && this.time) {
        return moment(`${this.date} ${this.time}`, this.format3).format(
          this.outputFormat
        );
      } else return null;
    },
    minimumDate() {
      if (!this.min) return null;
      return moment(this.min, this.format3).format(this.format2);
    },
    maximumDate() {
      if (!this.max) return null;
      return moment(this.max, this.format3).format(this.format2);
    },
    minimumTime() {
      if (!this.min) return null;
      return moment(this.min, this.format3).format(this.format3);
    },
    maximumTime() {
      if (!this.max) return null;
      return moment(this.max, this.format3).format(this.format3);
    },
  },
  methods: {
    cancel() {
      this.$emit("input", this.value);
      this.popup = false;
    },
    setDateTime() {
      this.$emit("input", this.datetime);
      this.popup = false;
    },
  },
};
</script>

<style lang="scss">
.kirby-datetime-wrapper {
  overflow: hidden;
  .v-picker {
    border-radius: 0 !important;
    border: none !important;
  }
}
</style>
