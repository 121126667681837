import request from '@/plugins/requests'
import moment from 'moment/moment';

export default {
    namespaced: true,
    state: {
        fetching: false,
        status: 'out',
        no_record: true,
        on_break: false,
        on_work: false,
        on_out: true,
        break_seconds: 0,
        work_seconds: 0,
        last_log_datetime: null,
        last_active_project: 'General Task',
        average_breaks: 0,
        average_works: 0,
        average_active_time: 0,
        as_of: moment().format('LL')
    },
    getters: {
        fetching: state => state.fetching,
        all: (state) => {
            return {
                status: state.status,
                no_record: state.no_record,
                on_break: state.on_break,
                on_work: state.on_work,
                on_out: state.on_out,
                break_seconds: state.break_seconds,
                work_seconds: state.work_seconds,
                last_log_datetime: state.last_log_datetime,
                last_active_project: state.last_active_project,
                average_breaks: state.average_breaks,
                average_works: state.average_works,
                average_active_time: state => state.average_active_time,
                as_of: state.as_of,
            }
        },
        status: state => state.status,
        no_record: state => state.no_record,
        on_break: state => state.on_break,
        on_work: state => state.on_work,
        on_out: state => state.on_out,
        break_seconds: state => state.break_seconds,
        work_seconds: state => state.work_seconds,
        last_log_datetime: state => state.last_log_datetime,
        last_active_project: state => state.last_active_project,
        average_breaks: state => state.average_breaks,
        average_works: state => state.average_works,
        average_active_time: state => state.average_active_time,
        as_of: state => state.as_of,
    },
    mutations: {
        set_fetching: (state, payload) => state.fetching = payload,
        set_status: (state, payload) => state.status = payload,
        set_no_record: (state, payload) => state.no_record = payload,
        set_on_break: (state, payload) => state.on_break = payload,
        set_on_work: (state, payload) => state.on_work = payload,
        set_on_out: (state, payload) => state.on_out = payload,
        set_work_seconds: (state, payload) => state.work_seconds = payload,
        increment_work_seconds: (state) => state.work_seconds++,
        set_break_seconds: (state, payload) => state.break_seconds = payload,
        increment_break_seconds: (state) => state.break_seconds++,
        set_last_log_datetime: (state, payload) => state.last_log_datetime = payload,
        set_last_active_project: (state, payload) => state.last_active_project = payload,
        set_average_breaks: (state, payload) => state.average_breaks = payload,
        set_average_works: (state, payload) => state.average_works = payload,
        set_average_active_time: (state, payload) => state.average_active_time = payload,
        set_as_of: (state, payload) => state.as_of = payload,
    },
    actions: {
        fetchCurrentStatus: ({ commit }) => {
            commit('set_fetching', true);
            request.get('sanctum/csrf-cookie')
                .then(() => {
                    request.get('api/records/my/status')
                        .then(({ data }) => {
                            commit('set_status', data.status);
                            commit('set_no_record', data.no_record);
                            commit('set_on_break', data.on_break);
                            commit('set_on_work', data.on_work);
                            commit('set_on_out', data.on_out);
                            commit('set_break_seconds', data.break_seconds);
                            commit('set_work_seconds', data.work_seconds);
                            commit('set_last_log_datetime', data.last_log_datetime);
                            commit('set_last_active_project', data.last_active_project);
                        })
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchAverageStatus: ({ commit }, id) => {
            request.get('sanctum/csrf-cookie')
                .then(() => {
                    request.get(`api/user-records/${id}/average/weekly`)
                        .then(({ data }) => {
                            commit('set_average_breaks', data.average_breaks);
                            commit('set_average_works', data.average_works);
                            commit('set_average_active_time', data.average_active_time);
                            commit('set_as_of', data.as_of);
                        })
                })
        }
    }
}