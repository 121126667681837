<template>
  <div
    v-bind="$attrs"
    class="xw-full xmb-[5px] xflex xgap-[10px] xpy-[5px] md:xflex-row xflex-col xjustify-start xitems-stretch"
  >
    <div class="xw-1/12 xflex xflex-col xjustify-center xitems-center">
      <icon-tooltip
        v-if="editableCommand"
        clickable
        color="#2E2E2E40"
        @click="$emit('remove')"
        icon="mdi-trash-can-outline"
        tip="Remove row"
      ></icon-tooltip>
      <v-icon color="#2E2E2E40" v-else>mdi-delete-off-outline</v-icon>
    </div>
    <div class="md:xw-2/12 xw-full">
      <CommandPicker
        v-if="!editableCommand"
        :value="command"
        :previous="previous ? previous.command : null"
        :editable="false"
      ></CommandPicker>
      <CommandPicker
        v-else
        :previous="previous ? previous.command : null"
        v-model="command"
        :editable="true"
      ></CommandPicker>
    </div>
    <div class="md:xw-5/12 xw-full">
      <KirbyDateTimePicker
        :min="minDatetime"
        :max="maxDatetime"
        v-model="datetime"
        display-format="lll"
      ></KirbyDateTimePicker>
    </div>
    <div class="md:xw-4/12 xw-full">
      <ProjectSelect
        placeholder="Task Required!"
        :rules="[requiredRule()]"
        has-create
        v-model="project"
        v-if="requiredProject"
      ></ProjectSelect>
      <ProjectSelect
        placeholder="Task Optional"
        v-model="project"
        has-create
        v-else-if="hasProject"
      ></ProjectSelect>
    </div>
  </div>
</template>

<script>
import CommandPicker from "./CommandPicker.vue";
import KirbyDateTimePicker from "./KirbyDateTimePicker.vue";
import ProjectSelect from "./ProjectSelect.vue";
import moment from "moment";
export default {
  inheritAttrs: false,
  components: { CommandPicker, KirbyDateTimePicker, ProjectSelect },
  props: {
    editableCommand: { type: Boolean, default: false },
    previous: { type: Object, default: null },
    next: { type: Object, default: null },
    value: {
      type: Object,
      default: () => {
        return {
          command: null,
          datetime: null,
          project: null,
        };
      },
    },
  },
  data() {
    return {
      hasProject: true,
      command: null,
      datetime: null,
      project: null,
      longFormat: "YYYY-MM-DD HH:mm:ss",
      max: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
  },
  watch: {
    command: {
      handler: function (val) {
        this.hasProject =
          val && ["in", "switch", "back"].includes(val) ? true : false;
        this.$emit("input", this.payload);
      },
      immediate: true,
    },
    datetime: {
      handler: function (val) {
        this.$emit("input", this.payload);
      },
      immediate: true,
    },
    project: {
      handler: function (val) {
        this.$emit("input", this.payload);
      },
      immediate: true,
    },
    value: {
      handler: function (val) {
        this.hasProject = ["in", "switch", "back"].includes(val.command);
        this.command = val.command;
        this.datetime = val.datetime || moment().format(this.longFormat);
        this.project = val.project;
      },
      immediate: true,
    },
  },
  computed: {
    requiredProject() {
      return !!(this.hasProject && this.command === "switch");
    },
    payload() {
      return {
        command: this.command,
        datetime: this.datetime,
        project: this.project,
        valid: this.valid,
      };
    },
    minDatetime() {
      if (!this.previous || !this.previous.datetime) return null;
      return moment(this.previous.datetime, this.longFormat)
        .add(1, "minute")
        .format(this.longFormat);
    },
    maxDatetime() {
      if (!this.next || !this.next.datetime) return this.max;
      return moment(this.next.datetime, this.longFormat)
        .subtract(1, "minute")
        .format(this.longFormat);
    },
    valid() {
      if (this.requiredProject && !this.project) return false;
      if (!this.command || !this.datetime) return false;
      return true;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
