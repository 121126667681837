<template>
  <div class="xflex x-space-x-2 xoverflow-hidden">
    <v-tooltip top color="primary" v-for="user in stacked" :key="user.id">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          rounded
          color="transparent"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="on"
        >
          <v-img contain :src="user.image_url" :alt="user.real_name" />
        </v-avatar>
      </template>
      <span>{{ user.real_name }}</span>
    </v-tooltip>
    <v-menu bottom left v-if="excess.length">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          rounded
          color="primary"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="on"
        >
          <span class="xtext-[#fff]">+ {{ excess.length }}</span>
        </v-avatar>
      </template>
      <v-list dense max-height="400" class="xoverflow-y-auto">
        <v-list-item v-for="(item, i) in excess" :key="i">
          <v-list-item-avatar rounded :size="30">
            <v-img contain :src="item.image_url" />
          </v-list-item-avatar>
          <v-list-item-title class="!xfont-bold">
            {{ item.real_name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  props: {
    users: { type: Array, default: () => [] },
    count: { type: Number, default: 50 },
  },
  computed: {
    stacked() {
      return _.cloneDeep(this.users).splice(0, this.count);
    },
    excess() {
      return _.cloneDeep(this.users).splice(this.count, this.users.length);
    },
  },
};
</script>

<style lang="scss" scoped>
.xavatar {
  border: 2px solid #fff;
  background-color: #393939;
  color: #fff;
}
</style>
