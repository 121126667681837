import Index from "@/views/AppDashboard/Index.vue";
import RecordIndex from "@/views/AppDashboard/MyRecord/Index.vue";
import Dashboard from "@/views/AppDashboard/Dashboard/Index.vue";
import MyRecord from "@/views/AppDashboard/MyRecord/subs/Records.vue";
import MyPending from "@/views/AppDashboard/MyRecord/subs/PendingEdits.vue";
import MyPTOs from "@/views/AppDashboard/MyRecord/subs/PTOs.vue";
import MySetting from "@/views/AppDashboard/MySetting/MySetting.vue";
import TeamRecord from "@/views/AppDashboard/TeamRecord/Index.vue";
import UserRecord from "@/views/AppDashboard/UserRecord/Index.vue";
import MemberReport from "@/views/AppDashboard/MemberReport/Index.vue";
import ProjectReport from "@/views/AppDashboard/ProjectReport/Index.vue";
import PTOs from "@/views/AppDashboard/PTOs/Index.vue";
import Settings from "@/views/AppDashboard/Settings/Index.vue";
import Subscription from "@/views/AppDashboard/Subscription/Index.vue";
import Payment from "@/views/AppDashboard/Payment/Index.vue";
import Checkout from "@/views/AppDashboard/Payment/subs/Checkout.vue";
import ThankYou from "@/views/AppDashboard/Payment/subs/ThankYou.vue";
import moment from "moment/moment";

function removeQueryParams(to) {
  if (Object.keys(to.query).length)
    return { path: to.path, query: {}, hash: to.hash };
}

function removeHash(to) {
  if (to.hash) return { path: to.path, query: to.query, hash: "" };
}

export const appRoutes = {
  path: "/dashboard",
  component: Index,
  children: [
    {
      path: "/",
      name: "app-dashboard",
      component: Dashboard,
      meta: {
        auth: true,
        exact: null,
        breads: [{ text: "Dashboard", disabled: true }],
      },
    },
    {
      path: "my-settings",
      name: "app-my-settings",
      component: MySetting,
      meta: {
        auth: true,
        exact: null,
        breads: [{ text: "My Settings", disabled: true }],
      },
    },
    {
      path: "my",
      component: RecordIndex,
      children: [
        {
          path: "records/:year_month",
          name: "app-my-record",
          component: MyRecord,
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [
              { text: "My Record", disabled: true },
              { text: "Monthly Record", disabled: true },
            ],
          },
        },
        {
          path: "pending-edits",
          name: "app-my-pending-edits",
          component: MyPending,
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [
              { text: "My Record", disabled: true },
              { text: "Pending Edits", disabled: true },
            ],
          },
        },
        {
          path: "ptos/:year_month",
          name: "app-my-ptos",
          component: MyPTOs,
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [
              { text: "My Record", disabled: true },
              { text: "Paid Time Off", disabled: true },
            ],
          },
        },
      ],
      meta: { auth: true, exact: null },
    },
    {
      path: "user-record/:id",
      component: UserRecord,
      children: [
        {
          path: "daily/:date",
          name: "app-user-record-daily",
          component: () =>
            import("@/views/AppDashboard/UserRecord/subs/Daily.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
        {
          path: "monthly/:year_month",
          name: "app-user-record-monthly",
          component: () =>
            import("@/views/AppDashboard/UserRecord/subs/Monthly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
        {
          path: "weekly/:start/:end",
          name: "app-user-record-weekly",
          component: () =>
            import("@/views/AppDashboard/UserRecord/subs/Weekly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
        {
          path: "custom/:start/:end",
          name: "app-user-record-custom",
          component: () =>
            import("@/views/AppDashboard/UserRecord/subs/Custom.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
      ],
      meta: { auth: true, exact: "view_team_record" },
    },
    {
      path: "team-record",
      component: TeamRecord,
      children: [
        {
          path: "daily/:date",
          name: "app-team-record-daily",
          component: () =>
            import("@/views/AppDashboard/TeamRecord/subs/Daily.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
        {
          path: "monthly/:year_month",
          name: "app-team-record-monthly",
          component: () =>
            import("@/views/AppDashboard/TeamRecord/subs/Monthly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
        {
          path: "weekly/:start/:end",
          name: "app-team-record-weekly",
          component: () =>
            import("@/views/AppDashboard/TeamRecord/subs/Weekly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
        {
          path: "custom/:start/:end",
          name: "app-team-record-custom",
          component: () =>
            import("@/views/AppDashboard/TeamRecord/subs/Custom.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
          },
        },
      ],
      meta: { auth: true, exact: "view_team_record" },
    },
    {
      path: "member-report",
      name: "app-member-report",
      component: MemberReport,
      meta: { auth: true, exact: "view_team_report", breads: [] },
    },
    {
      path: "project-reports",
      component: ProjectReport,
      props: true,
      children: [
        {
          path: "daily/:date",
          name: "app-project-report-daily",
          component: () =>
            import("@/views/AppDashboard/ProjectReport/subs/Daily.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
        {
          path: "monthly/:year_month",
          name: "app-project-report-monthly",
          component: () =>
            import("@/views/AppDashboard/ProjectReport/subs/Monthly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
        {
          path: "weekly/:start/:end",
          name: "app-project-report-weekly",
          component: () =>
            import("@/views/AppDashboard/ProjectReport/subs/Weekly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
      ],
      meta: {
        auth: true,
        exact: null,
        breads: [],
      },
    },
    {
      path: "projects/:project_id",
      component: () => import("@/views/AppDashboard/Project/Index.vue"),
      props: true,
      children: [
        {
          path: "daily/:date",
          name: "app-project-daily",
          component: () =>
            import("@/views/AppDashboard/Project/subs/Daily.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
        {
          path: "monthly/:year_month",
          name: "app-project-monthly",
          component: () =>
            import("@/views/AppDashboard/Project/subs/Monthly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
        {
          path: "weekly/:start/:end",
          name: "app-project-weekly",
          component: () =>
            import("@/views/AppDashboard/Project/subs/Weekly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
      ],
      meta: { auth: true, exact: null, breads: [] },
    },
    {
      path: "project-user-pto/:pid/:user_id",
      component: () => import("@/views/AppDashboard/ProjectUsers/Index.vue"),
      children: [
        {
          path: "custom/:start/:end",
          name: "app-user-pto-custom",
          component: () =>
            import("@/views/AppDashboard/ProjectUsers/subs/PTOCustom.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
        {
          path: "monthly/:year_month",
          name: "app-user-pto-monthly",
          component: () =>
            import("@/views/AppDashboard/ProjectUsers/subs/PTOMonthly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
      ],
      meta: { auth: true, exact: "view_team_report", breads: [] },
    },
    {
      path: "project-user-time/:pid/:user_id",
      component: () => import("@/views/AppDashboard/ProjectUsers/Index.vue"),
      children: [
        {
          path: "custom/:start/:end",
          name: "app-user-time-custom",
          component: () =>
            import("@/views/AppDashboard/ProjectUsers/subs/TSCustom.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
        {
          path: "monthly/:year_month",
          name: "app-user-time-monthly",
          component: () =>
            import("@/views/AppDashboard/ProjectUsers/subs/TSMonthly.vue"),
          props: true,
          meta: {
            auth: true,
            exact: null,
            breads: [],
            requiredModule: "projects",
          },
        },
      ],
      meta: { auth: true, exact: "view_team_report", breads: [] },
    },
    {
      path: "pto",
      component: PTOs,
      children: [
        {
          path: "all/monthly/:year_month",
          name: "app-pto-all-monthly",
          component: () => import("@/views/AppDashboard/PTOs/subs/Monthly.vue"),
          meta: {
            auth: true,
            exact: "manage_user_ptos",
            requiredModule: "ptos",
          },
        },
        {
          path: "all/weekly/:start/:end",
          name: "app-pto-all-weekly",
          component: () => import("@/views/AppDashboard/PTOs/subs/Weekly.vue"),
          meta: {
            auth: true,
            exact: "manage_user_ptos",
            requiredModule: "ptos",
          },
        },
        {
          path: "all/custom/:start/:end",
          name: "app-pto-all-custom",
          component: () => import("@/views/AppDashboard/PTOs/subs/Custom.vue"),
          meta: {
            auth: true,
            exact: "manage_user_ptos",
            requiredModule: "ptos",
          },
        },
        {
          path: "pending/monthly/:year_month",
          name: "app-pto-pending-monthly",
          component: () => import("@/views/AppDashboard/PTOs/subs/Monthly.vue"),
          meta: {
            auth: true,
            exact: "manage_user_ptos",
            requiredModule: "ptos",
          },
        },
        {
          path: "pending/weekly/:start/:end",
          name: "app-pto-pending-weekly",
          component: () => import("@/views/AppDashboard/PTOs/subs/Weekly.vue"),
          meta: {
            auth: true,
            exact: "manage_user_ptos",
            requiredModule: "ptos",
          },
        },
        {
          path: "pending/custom/:start/:end",
          name: "app-pto-pending-custom",
          component: () => import("@/views/AppDashboard/PTOs/subs/Custom.vue"),
          meta: {
            auth: true,
            exact: "manage_user_ptos",
            requiredModule: "ptos",
          },
        },
      ],
      meta: {
        auth: true,
        exact: "manage_user_ptos",
        breads: [],
        requiredModule: "ptos",
      },
    },
    {
      path: "settings", //info,preferences,bot-responses //users management
      component: Settings,
      children: [
        {
          path: "regions",
          name: "app-settings-general-regions",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/Regions.vue"),
          meta: {
            auth: true,
            some: "view_region|manage_region",
            breads: [],
            requiredModule: "regions",
          },
        },
        {
          path: "offices",
          name: "app-settings-general-sites",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/Sites.vue"),
          meta: {
            auth: true,
            some: "view_office|manage_office",
            breads: [],
            requiredModule: "sites",
          },
        },
        {
          path: "data-centers",
          name: "app-settings-general-data-centers",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/DataCenters.vue"),
          meta: {
            auth: true,
            some: "view_data_center|manage_data_center",
            breads: [],
            requiredModule: "data-centers",
          },
        },
        {
          path: "new-users",
          name: "app-settings-general-new-users",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/NewUsers.vue"),
          meta: {
            auth: true,
            exact: "view_team_info",
            breads: [],
          },
        },
        {
          path: "all-users",
          name: "app-settings-general-all-users",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/AllUsers.vue"),
          meta: {
            auth: true,
            exact: "view_team_info",
            breads: [],
          },
        },
        {
          path: "preferences",
          name: "app-settings-preferences",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/Preferences.vue"),
          meta: {
            auth: true,
            exact: "view_team_preferences",
            breads: [],
          },
        },
        {
          path: "bot-responses",
          name: "app-settings-bot-responses",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/BotResponses.vue"),
          meta: {
            auth: true,
            some: "view_bot_responses|manage_bot_responses",
            breads: [],
          },
        },
        {
          path: "user-management",
          name: "app-settings-user-management",
          component: () =>
            import("@/views/AppDashboard/Settings/subs/UserManagement.vue"),
          meta: {
            auth: true,
            some: "manage_roles_permissions|add_roles|delete_roles|update_roles|assign_roles|revoke_roles",
            breads: [],
          },
        },
      ],
      alias: [
        "settings/info",
        "settings/preferences",
        "settings/bot-responses",
        "settings/roles-permissions",
      ],
      meta: {
        auth: true,
        some: "view_office|manage_office|view_region|manage_region|view_data_center|manage_data_center|view_team_info|view_bot_responses|manage_bot_responses|view_team_preferences|update_team_preferences|manage_roles_permissions|add_roles|delete_roles|update_roles|assign_roles|revoke_roles",
      },
    },
    {
      path: "subscription",
      component: Subscription,
      children: [
        {
          path: "users",
          name: "app-subscription",
          component: () =>
            import("@/views/AppDashboard/Subscription/tabs/Users.vue"),
          meta: {
            auth: true,
            exact: "update_subscription",
            breads: [],
          },
        },
        {
          path: "history",
          name: "app-subscription-history",
          component: () =>
            import("@/views/AppDashboard/Subscription/tabs/History.vue"),
          meta: {
            auth: true,
            exact: "update_subscription",
            breads: [],
          },
        },
      ],
      meta: { auth: true, exact: "update_subscription" },
    },
    {
      path: "payment",
      component: Payment,
      children: [
        {
          path: "update-information",
          component: () =>
            import("@/views/AppDashboard/Payment/subs/UpdateInformation.vue"),
          name: "app-update-information",
          meta: { auth: true, exact: "update_subscription", homes: true },
        },
        {
          path: "checkout",
          component: Checkout,
          name: "app-checkout",
          meta: { auth: true, exact: "update_subscription", homes: true },
        },
        {
          path: "thank-you",
          component: ThankYou,
          name: "app-thank-you",
          meta: { auth: true },
        },
      ],
    },
  ],
  meta: {
    auth: true,
  },
};
