<template>
  <v-textarea
    v-bind="$attrs"
    hide-details="auto"
    outlined
    dense 
    color="primary"
    v-on="$listeners"
    @input="(v) => $emit('input', v)"
  >
    <template v-for="slot in parentSlots" #[slot]="props">
      <slot :name="slot" :props="props" />
    </template>
  </v-textarea>
</template>

<script>
export default {
  inheritAttrs: false,
  setup(props, { slots }) {
    const parentSlots = Object.keys(slots);
    return { parentSlots };
  },
};
</script>

<style scoped></style>
