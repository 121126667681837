<template>
  <v-menu offset-y content-class="arrow-menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        :loading="fetching"
        outlined
        dark
        class="text-none"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      >
        <v-icon left v-if="!fetching">mdi-download-outline</v-icon>
        <v-icon left v-else>mdi-spin mdi-spinner</v-icon>
        <span v-if="laptopUp">Export</span>
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item v-if="hasCsv && apiCsv" @click="fetchCsv">
        <v-list-item-title>
          <v-icon color="#2E2E2E" left>mdi-file-excel-box</v-icon>
          <span class="xtext-[#2E2E2E]">CSV</span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="hasPdf && apiPdf" @click="fetchPdf">
        <v-list-item-title>
          <v-icon color="#2E2E2E" left>mdi-file-pdf-box</v-icon>
          <span class="xtext-[#2E2E2E]">PDF</span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="hasJpg && apiJpg" @click="fetchJpg">
        <v-list-item-title>
          <v-icon color="#2E2E2E" left>mdi-file-jpg-box</v-icon>
          <span class="xtext-[#2E2E2E]">JPG</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    hasCsv: { type: Boolean, default: true },
    hasPdf: { type: Boolean, default: true },
    hasJpg: { type: Boolean, default: true },
    apiCsv: { type: String, default: undefined },
    apiPdf: { type: String, default: undefined },
    apiJpg: { type: String, default: undefined },
    filename: { type: String, default: "exported" },
  },
  data() {
    return {
      fetching: false,
    };
  },
  methods: {
    fetchCsv() {
      this.fetching = true;
      this.$axios
        .get(this.apiCsv, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.filename}.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appToast("No record found.", "error");
        })
        .finally(() => (this.fetching = false));
    },
    fetchJpg() {
      this.fetching = true;
      this.$axios
        .get(this.apiJpg, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.filename}.jpg`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appToast("No record found.", "error");
        })
        .finally(() => (this.fetching = false));
    },
    fetchPdf() {
      this.fetching = true;
      this.$axios
        .get(this.apiPdf, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.filename}.jpg`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appToast("No record found.", "error");
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
