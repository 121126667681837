<template>
  <app-modal
    valid
    :title="`${appName} Policies`"
    v-model="open"
    max-width="800"
    :has-footer="false"
  >
    <v-card flat>
      <Collapse
        expanded
        max-height="450px"
        class="xmb-1"
        title="Terms of Service"
      >
        <template v-slot:default>
          <div class="panel-body">
            <p class="separator">Terms of Service (“Terms”)</p>

            <p class="separator">Last updated: {{ lastUpdated }}</p>

            <p class="separator">
              Please read these Terms of Service carefully before using any
              {{ companyName }} services, including {{ appName }} or any other
              service, software, or product (the "Service") provided by
              {{ companyName }}
              ("us", "we", or "our").
            </p>

            <p class="separator">
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all users and others who access or use the Service.
            </p>

            <p class="separator">
              <strong>
                By accessing or using the Service you agree to be bound by these
                Terms. If you disagree with any part of the terms then you may
                not access the Service. These Terms of Service are provided in
                addition to the Master Subscription Agreement between you and
                {{ companyName }}. In the event of a conflict between these
                Terms and the Master Subscription Agreement, the Master
                Subscription Agreement shall control.
              </strong>
            </p>

            <h4 class="separator">Use of Service</h4>

            <p class="separator">
              You are at least 18 years old or you are under the supervision of
              a parent or guardian. {{ companyName }} grants you a limited and
              revocable license to use the Service. The Service may not be
              reproduced, duplicated, copied, sold, resold, visited, or
              otherwise exploited for any commercial purpose without our
              express, written consent. {{ companyName }} reserves the right to
              refuse service, terminate accounts, remove or edit content, or
              cancel orders or portions thereof, in our sole discretion, if
              {{ companyName }} determines that any client conduct violates
              applicable law or is harmful to the interests of
              {{ companyName }}. You are prohibited from uploading to, posting
              on or transmitting through {{ companyName }} any information or
              material that (a) violates or infringes the proprietary rights of
              any person; (b) is libelous, threatening, defamatory, obscene,
              indecent, pornographic or could give rise to any civil or criminal
              liability under any law; or (c) includes any viruses, worms,
              Trojan Horses or other harmful code or properties. If you use the
              Service, you are responsible for maintaining the confidentiality
              of your account information and password and for restricting
              access to your computer, and you agree to accept responsibility
              for all activities that occur using your account information.
            </p>

            <h4 class="separator">Intellectual property</h4>

            <p class="separator">
              All content on {{ companyName }}, including text, graphics, button
              icons, images and software belongs exclusively to
              {{ companyName }}. The compilation, arrangement, and assembly of
              all such text, graphics, button icons, images and software belong
              exclusively to {{ companyName }}. {{ companyName }}, its logos and
              trade names are registered trademarks of {{ companyName }} and its
              affiliates. The use of any {{ companyName }} trademark is
              prohibited without the prior written consent of {{ companyName }}.
              All other trademarks not owned by {{ companyName }} or its
              affiliates that appear on the Service are the property of their
              respective owners.
            </p>

            <h4 class="separator">Termination</h4>

            <p class="separator">
              We may terminate or suspend access to our Service immediately,
              without prior notice or liability, for any reason whatsoever,
              including without limitation if you breach the Terms. All
              provisions of the Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers,
              confidentiality provisions, and indemnity and limitations of
              liability.
            </p>

            <h4 class="separator">Subscriptions</h4>

            <p class="separator">
              Some parts of the Service are billed on a subscription basis
              ("Subscription(s)"). You will be billed in accordance with the
              terms of any such Subscription.
            </p>

            <h4 class="separator">Content</h4>

            <p class="separator">
              Our Service allows you to link, store, share, collate, organize,
              and otherwise make available certain information, text, graphics,
              financial information, or other material ("Content"). You are
              responsible for the content linked, stored, shared, or otherwise
              made available on the Service.
            </p>

            <h4 class="separator">Links to Other Web Sites</h4>

            <p class="separator">
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by {{ companyName }}.
            </p>

            <p class="separator">
              {{ companyName }} has no control over, and assumes no
              responsibility for, the content, privacy policies, or practices of
              any third party web sites or services. You further acknowledge and
              agree that {{ companyName }}
              shall not be responsible or liable, directly or indirectly, for
              any damage or loss caused or alleged to be caused by or in
              connection with use of or reliance on any such content, goods or
              services available on or through any such websites or services.
            </p>

            <h4 class="separator">Disclaimer and Limitation of Liability</h4>

            <p class="separator">
              Services provided by {{ companyName }} and the transactions
              conducted upon and through it are provided to you on an "as is"
              basis. {{ companyName }} makes no representations or warranties of
              any kind, express or implied, as to the operation of the Services
              or the information or content therein. To the extent allowed by
              law, {{ companyName }} disclaims all warranties, express or
              implied, including but not limited to, implied warranties of
              merchantability and fitness for a particular purpose,
              non-infringement, title, quiet enjoyment, data accuracy and
              systems integration. To the extent allowed by law,
              {{ companyName }}
              will not be liable for any damages of any kind arising from your
              use of the Site including, but not limited to, indirect,
              incidental, punitive, exemplary, special or consequential damages.
            </p>

            <h4 class="separator">Changes</h4>

            <p class="separator">
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days’ notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>

            <h4 class="separator">Contact Us</h4>

            <p class="separator">
              If you have any questions about these Terms, please contact us via
              {{ support_email }}
            </p>
          </div>
        </template>
      </Collapse>
      <Collapse max-height="450px" class="xmb-1" title="Privacy Policy">
        <template v-slot:default>
          <div class="panel-body">
            <p>
              <strong>
                PLEASE READ THIS POLICY CAREFULLY BEFORE USING
                {{ appName | uppercase }} SERVICES
              </strong>
            </p>

            <p>Last updated: May 25, 2018</p>

            <p>
              Please read this Privacy Policy carefully before using any
              {{ companyName }} services, including {{ appName }} or any other
              service, software, or product (the "Service") provided by
              {{ companyName }} ("us", "we", or "our").
            </p>

            <p>
              This Privacy Policy describes how and when {{ appName }} collects,
              uses and shares your information when you use our Services.
              {{ appName }} receives your information through the Slack APIs,
              buttons, widgets, and commerce services (the "Services" or "{{
                appName
              }}") and from our partners and other third parties. For example,
              you send us information when you use {{ appName }} from our
              website, or access {{ appName }} from an application. When using
              any of our Services you consent to the collection, transfer,
              manipulation, storage, disclosure and other uses of your
              information as described in this Privacy Policy. Irrespective of
              which country you reside in or supply information from, you
              authorize {{ appName }} to use your information in the United
              States and any other country where {{ companyName }} operates.
            </p>

            <p>
              Our Website contains links to third party websites. If you follow
              a link to any of those third party websites, please note that they
              have their own privacy policies and that we do not accept any
              responsibility or liability for their policies or processing of
              your personal information. Please check these policies before you
              submit any personal information to such third party websites.
            </p>

            <p>
              If you have questions or complaints regarding this privacy
              statement or our privacy practices, please contact us at
              {{ support_email }}.
            </p>

            <h3>Information Collection and Use</h3>

            <p><strong>Basic Account Information:</strong></p>
            <p>
              When you integrate {{ appName }} in your Slack team, the Slack API
              provides us information about your team and its members,
              specifically names, emails, usernames, timezones, profile
              pictures, team domains, and team names. This information is
              visible to the members of your Slack team and {{ companyName }},
              but not anyone else.
            </p>

            <p><strong>Additional Information: </strong></p>
            <p>
              You may also provide us with information during your conversations
              with {{ appName }} on Slack and inside {{ appName }}'s slash
              commands. If you email us, we may keep your message, email
              address, and contact information to respond to your request. The
              additional information described in this section is also private.
            </p>

            <p><strong>Cookies: </strong></p>
            <p>
              Like many websites, we may use cookies and similar technologies to
              collect additional website usage data and to improve our Services,
              but we do not require cookies for many parts of our Services such
              as searching and looking at public user profiles. A cookie is a
              small data file that is transferred to your computer's hard disk.
              {{ appName }} may use both session cookies and persistent cookies
              to better understand how you interact with our Services, to
              monitor aggregate usage by our users and web traffic routing on
              our Services, and to customize and improve our Services. Most
              Internet browsers automatically accept cookies. You can instruct
              your browser, by changing its settings, to stop accepting cookies
              or to prompt you before accepting a cookie from the websites you
              visit. However, some Services may not function properly if you
              disable cookies.
            </p>

            <p>
              The following cookies are set automatically when you visit our
              Website:
            </p>

            <p>
              <em><strong>Third Party Cookies</strong></em>
            </p>

            Name: Hotjar<br />
            Domain: hotjar.com<br />
            For more information, please refer to the following link:
            https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies<br /><br />

            Name: Facebook Pixel<br />
            Domain: facebook.com<br />
            {{ appName }} uses Facebook pixel to help us track conversions from
            Facebook ads, optimize ads based on collected data, build targeted
            audiences for future ads, and remarket to qualified leads.<br /><br />

            Name: Mixpanel<br />
            Domain: mixpanel.com<br />
            {{ appName }} uses Mixpanel for analytics, it tracks user
            interactions with web and mobile applications and provides tools for
            targeted communication. Data collected is used to build custom
            reports and measure user engagement and retention.<br /><br />

            <p><strong>Log Data: </strong></p>
            <p>
              When you use our Services, we may receive information such as your
              IP address, browser type, operating system, the referring web
              page, pages visited, location, your mobile carrier, device
              information (including device and application IDs), search terms,
              and cookie information ("Log Data"). We receive Log Data when you
              interact with our Services, for example, when you visit our
              website, sign into our Services, interact with our emails or
              interact with {{ appName }} on Slack. We may also receive Log Data
              when you click on, view or interact with a link on our Services to
              a third-party application, such as when you choose to install
              another application through {{ appName }}. {{ appName }}
              uses Log Data to provide, understand, and improve our Services.
            </p>

            <p><strong>Third-Parties: </strong></p>
            <p>
              {{ appName }} uses a variety of third-party services to help
              provide our Services, such as hosting our site and blog, and to
              help us understand and improve the use of our Services, such as
              Hotjar. These third-party service providers may collect
              information sent by your browser as part of a web page request,
              such as cookies or your IP address. Third-party ad partners may
              share information with us, like a browser cookie ID, website URL
              visited, mobile device ID, or cryptographic hash of a common
              account identifier (such as an email address).
            </p>

            <h3>Information Storage</h3>

            <p>
              When you integrate {{ appName }} in your Slack team, the Slack API
              provides us information about your team and its members. Your
              information specifically your name, email, username, timezone,
              profile pictures, team domain, and team name is saved in our
              database.
            </p>

            <p>
              We do not store your password, Slack handles the authentication
              when you sign in to our dashboard. We do not disclose your account
              details, postal or email addresses to anyone except when legally
              required to do so. It is your responsibility to keep your Slack
              password secure.
            </p>

            <p><strong>How long we retain your personal data</strong></p>

            <p>
              We keep you personal information even when you unsubscribe to our
              plan or when your free trial expires. However, the information in
              our database will no longer be updated if your team has
              unsubscribed or deleted {{ appName }} in your Slack App directory.
            </p>

            <h3>Information Sharing and Disclosure</h3>

            <p><strong>Your Consent: </strong></p>
            <p>
              We may share or disclose your information at your direction, such
              as when you authorize a third-party web client or application to
              access your {{ appName }} account.
            </p>

            <p><strong>Service Providers:</strong></p>
            <p>
              We engage service providers to perform functions and provide
              services to us in the United States and abroad. We may share your
              private personal information with such service providers subject
              to confidentiality obligations consistent with this Privacy
              Policy, and on the condition that the third parties use your
              private personal data only on our behalf and pursuant to our
              instructions.
            </p>

            <p><strong>Law and Harm: </strong></p>
            <p>
              Notwithstanding anything to the contrary in this Policy, we may
              preserve or disclose your information if we believe that it is
              reasonably necessary to comply with a law, regulation or legal
              request; to protect the safety of any person; to address fraud,
              security or technical issues; or to protect {{ appName }}'s rights
              or property. However, nothing in this Privacy Policy is intended
              to limit any legal defenses or objections that you may have to a
              third party’s, including a government’s, request to disclose your
              information.
            </p>

            <p><strong>Business Transfers and Affiliates: </strong></p>
            <p>
              In the event that {{ appName }} is involved in a bankruptcy,
              merger, acquisition, reorganization or sale of assets, your
              information may be sold or transferred as part of that
              transaction. This Privacy Policy will apply to your information as
              transferred to the new entity. We may also disclose information
              about you to our corporate affiliates in order to help provide,
              understand, and improve our Services and our affiliates’ services.
            </p>

            <p><strong>Non-Private or Non-Personal Information: </strong></p>
            <p>
              We may share or disclose your non-private, aggregated or otherwise
              non-personal information after we have removed any private
              personal information (such as your name or your company name).
            </p>

            <p><strong>Disclosure of your information:</strong></p>
            <p>
              We may disclose your personal information to potential employers
              and agents, as well as to our service providers and business
              partners to assist us in performing any contract we enter into
              with them or you, including providing the Website and the Services
              it enables, analytics providers (to assist us in the improvement
              and optimisation of the Website and Services) and/or a member of
              our group, which means our subsidiaries, our ultimate holding
              company and its subsidiaries, as defined in section 1159 of the UK
              Companies Act 2006. A list of such third parties and agents is as
              follows:
            </p>
            <ul class="!xlist-disc">
              <li>
                <i class="fa fa-hand-o-right"></i> Amazon Web Services: Hosting
                services.
              </li>
              <li>
                <i class="fa fa-hand-o-right"></i> Boomtrain: a customer
                messaging service used by us to provide customer support.
              </li>

              <li>
                <i class="fa fa-hand-o-right"></i> Hotjar: an analytics tool we
                use to understand your use of our Website.
              </li>

              <li>
                <i class="fa fa-hand-o-right"></i> MailChimp: used to send
                administration of service emails such as email verification,
                product updates, password reset and welcome email; and other
                potential marketing messages where you have given your explicit
                consent for us to do so.
              </li>

              <li>
                <i class="fa fa-hand-o-right"></i> Mixpanel: tracks user
                interactions with web and mobile applications and provides tools
                for targeted communication with them. Data collected is used to
                build custom reports and measure user engagement and retention.
              </li>

              <li>
                <i class="fa fa-hand-o-right"></i> Stripe: used by us to process
                online payments.
              </li>
            </ul>

            <h3>Your Access to and Control Over Information</h3>

            <p>
              You may opt out of any future contacts from us at any time. You
              can do the following at any time by contacting us via the email
              address given on our website:
            </p>

            <ul class="!xlist-disc">
              <li>
                <i class="fa fa-hand-o-right"></i> See what data we have about
                you, if any.
              </li>
              <li>
                <i class="fa fa-hand-o-right"></i> Ensure that your personal
                information is updated and correct.
              </li>
              <li>
                <i class="fa fa-hand-o-right"></i> Change/correct any data we
                have about you.
              </li>
              <li>
                <i class="fa fa-hand-o-right"></i> Ask us to stop processing
                your personal data at any time.
              </li>
              <li>
                <i class="fa fa-hand-o-right"></i> Request to provide you a copy
                of all your personal data.
              </li>
              <li>
                <i class="fa fa-hand-o-right"></i> Have us delete any data we
                have about you.
              </li>
              <li>
                <i class="fa fa-hand-o-right"></i> Express any concern you have
                about our use of your data.
              </li>
            </ul>

            <p>
              If you are a registered user of our Services, we may provide you
              with tools and account settings to access or modify the personal
              information you provided to us and associated with your account.
              The profile information that we receive from Slack can be modified
              through your Slack account, our records will be updated when you
              sync your team information in your Team Settings and none of the
              previous information will be saved. You can also permanently
              delete your {{ appName }} account by emailing us at
              {{ support_email }}.
            </p>

            <p>
              Please note that {{ companyName }} will not charge you in any
              request we receive to exercise any of your rights stated above.
            </p>

            <h3>Security over the internet</h3>

            <p>
              No data transmission over the internet or website can be
              guaranteed to be secure from intrusion. However, we maintain
              commercially reasonable physical, electronic and procedural
              safeguards to protect your personal information in accordance with
              data protection legislative requirements.
            </p>

            <p>
              All information you provide to us is stored on our secure servers,
              and accessed and used subject to our security policies and
              standards.
            </p>

            <p>
              Sensitive information between your browser and our Website is
              transferred in encrypted form using Secure Socket Layer (“SSL”).
              When transmitting sensitive information, you should always make
              sure that your browser can validate the {{ appName }}
              certificate.
            </p>

            <p>
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although {{ companyName }} will do its best
              to protect your personal data, we cannot guarantee the security of
              your data transmitted to our Website or through our Services. Any
              transmission is at your own risk. Once we have received your
              information, we will use strict procedures and security features
              in an effort to prevent any unauthorized access.
            </p>

            <h3>Changes to this Policy</h3>

            <p>
              We may revise this Privacy Policy from time to time. The most
              current version of the policy will govern our use of your
              information and will always be at
              {{ endUserAggrementLink }}. If we make a change to this policy
              that, in our sole discretion, is material, we will notify you via
              email to the email address associated with your account. By
              continuing to access or use the Services after those changes
              become effective, you agree to be bound by the revised Privacy
              Policy.
            </p>

            <h3>Questions and Concerns</h3>

            <p>
              If you have questions or concerns about this Privacy Policy or
              information handling processes, you may contact us at the
              following addresses:
            </p>

            <p>
              {{ companyName }} LLC<br />
              2430 Victory Park Lane, #2601<br />
              Dallas, TX 75219<br />
              +1-443-637-2749<br /><br />

              {{ support_email }}
            </p>
          </div>
        </template>
      </Collapse>
      <Collapse max-height="450px" class="xmb-1" title="Copyright Policy">
        <template v-slot:default>
          <div class="panel-body">
            <h3>Trademark</h3>
            <p>
              Slack, Slack API and Slack logo are trademark of Slack
              Technologies, Inc.
            </p>
            <p>Stripe, Stripe API and Stripe logo are trademark of Stripe.</p>
            <p>
              {{ appName }} is a trademark of {{ companyName }} LLC and are not
              created by, affiliated with, or supported by Slack Technologies,
              Inc.
            </p>
          </div>
        </template>
      </Collapse>
    </v-card>
  </app-modal>
</template>

<script>
import Collapse from "@/views/HomeArea/components/Collapse.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      open: false,
      lastUpdated: "May 25, 2018",
    };
  },
  created() {
    this.$event.$on("open-legalities", (open) => {
      this.open = open;
    });
  },
  computed: {
    ...mapGetters("appconfigs", ["support_email"]),
    endUserAggrementLink() {
      return `${window.location.origin}/privacy-policy`;
    },
  },
  components: { Collapse },
};
</script>

<style lang="scss" scoped></style>
