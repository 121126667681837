<template>
  <v-container fluid class="xbg-[#e5e5e5]">
    <router-view :key="$route.fullPath"></router-view>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
