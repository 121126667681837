<template>
  <div
    class="xh-min-screen m-0 p-0 xflex xflex-row xjustify-between xitems-stretch xw-full"
  >
    <div
      class="xw-6/12 xp-3 xflex xflex-col xmin-h-screen xbg-black xtext-white"
    >
      <label>Response:</label>
      <pre
        class="xbreak-words xoverflow-x-auto xwhitespace-pre-wrap xtext-white"
      >
      {{ response }}
      </pre>
    </div>
    <div
      class="xw-6/12 xp-3 xflex xsticky xflex-col min-h-screen xbg-blue-800 xbg-opacity-70 xitems-center xjustify-center"
    >
      <v-btn
        small
        class="text-none"
        color="primary"
        @click="$router.push({ name: 'test-hook' })"
      >
        Go to Slack Hook Test
      </v-btn>

      <div class="xflex xw-full xflex-col mb-3 px-5 xtext-white">
        <label>Select User</label>
        <v-select
          hide-details="auto"
          outlined
          dense
          :items="users"
          v-model="user_id"
        ></v-select>
      </div>
      <div class="xflex xw-full xflex-col mb-3 px-5 xtext-white">
        <label>Select command</label>
        <v-select
          hide-details="auto"
          outlined
          dense
          :items="commands"
          v-model="command"
        ></v-select>
      </div>
      <div class="xflex xw-full xflex-col mb-3 px-5 xtext-white">
        <label>Enter text</label>
        <v-text-field
          hide-details="auto"
          outlined
          dense
          v-model="text"
        ></v-text-field>
      </div>
      <v-btn :loading="submitting" color="primary" @click="sendTest">
        Submit
      </v-btn>
      <div class="flex pa-2">
        <label>Payload:</label>
        <pre
          class="xbreak-words xoverflow-x-auto xwhitespace-pre-wrap xtext-white"
        >
        {{ payload }} 
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commands: [
        "/in",
        "/brb",
        "/back",
        "/out",
        "/check",
        "/switch",
        "/project",
        "/mesasix",
        "/cut",
        "/check",
      ],
      users: [
        { text: "kirbycrypto12", value: "U03GG31LFDM" },
        { text: "kyraminerva", value: "U03J2MTCTJ4" },
      ],
      command: "/in",
      text: "",
      response: null,
      submitting: false,
      user_id: null,
      username: null,
    };
  },
  watch: {
    user_id: {
      handler: function (val) {
        this.username = val
          ? this.users.find((i) => i.value === val).text || null
          : null;
      },
      immediate: true,
    },
  },
  computed: {
    payload() {
      return {
        text: this.text,
        command: this.command,
        token: "Ftv8Wjq68Ht8QHjA88AJXIRG",
        team_id: "T03GTNSAD61",
        team_domain: "cryptogeeksworld",
        channel_id: "C03GG36SBPZ",
        channel_name: "projects-marketing",
        user_id: this.user_id,
        user_name: this.username,
        api_app_id: "A03GWM3RCUS",
        is_enterprise_install: false,
        response_url:
          "https://hooks.slack.com/commands/T03GTNSAD61/3619342357985/NwFGorJMccl7MfS3IyVgVsIK",
        trigger_id:
          "3609074224308.3571774353205.b8b1d4daa9858044b0d353d6eab3ec25",
      };
    },
  },
  created() {
    this.$auth.refreshCsrfToken();
  },
  methods: {
    sendTest() {
      this.submitting = true;
      this.$axios
        .post("api/webhook/slash-command", this.payload)
        .then(({ data }) => {
          this.response = data;
        })
        .catch((err) => (this.response = err))
        .finally(() => (this.submitting = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
