import { render, staticRenderFns } from "./CopyrightPolicy.vue?vue&type=template&id=47383200&scoped=true&"
import script from "./CopyrightPolicy.vue?vue&type=script&lang=js&"
export * from "./CopyrightPolicy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47383200",
  null
  
)

export default component.exports