<template>
  <v-dialog
    class="!xrounded-[10px]"
    v-bind="$attrs"
    persistent
    transition="dialog-bottom-transition"
    v-model="open"
    scrollable
    :fullscreen="smDown && fullscreenOnMobile"
    :max-width="maxWidth"
  >
    <slot name="whole-content">
      <v-card
        color="#ffffff"
        :class="wrapperClass"
        class="xoverflow-hidden"
        flat
      >
        <v-card-title class="xpt-[2em]" v-if="hasHeader">
          <slot name="header">
            <v-row no-gutters justify="space-between" align="center">
              <slot name="title">
                <strong class="xtext-gray-700 md:xtracking-wide">{{
                  title
                }}</strong>
              </slot>
              <v-icon color="#000" :disabled="submitting" @click="cancelled">
                mdi-close
              </v-icon>
            </v-row>
          </slot>
        </v-card-title>
        <v-card-text><slot name="default"></slot></v-card-text>
        <v-card-actions v-if="hasFooter" class="xpb-[15px] xpr-[20px]">
          <slot name="footer">
            <div
              class="xflex xw-full xpr-[0.8em] xpb-[0.8em] xflex-row xjustify-end xgap-2"
            >
              <v-btn
                @click="cancelled"
                :disabled="submitting"
                outlined
                color="primary"
                depressed
                class="xmin-w-[150px] text-none"
              >
                <span class="xfont-bold xleading-[1em]">
                  {{ cancelLabel }}
                </span>
              </v-btn>
              <v-btn
                @click="confirmed"
                color="primary"
                depressed
                :disabled="submitting || !valid"
                :loading="submitting"
                class="xmin-w-[150px] text-none"
              >
                <span class="xtext-[#fff] xfont-bold xleading-[1em]">
                  {{ confirmLabel }}
                </span>
              </v-btn>
            </div>
          </slot>
        </v-card-actions>
      </v-card>
    </slot>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    fullscreenOnMobile: { type: Boolean, default: true },
    value: { type: Boolean, default: false },
    cancelLabel: { type: String, default: "Cancel" },
    confirmLabel: { type: String, default: "Submit" },
    maxWidth: { type: [String, Number], default: 1024 },
    title: { type: String, default: "" },
    hasHeader: { type: Boolean, default: true },
    hasFooter: { type: Boolean, default: true },
    valid: { type: Boolean, default: false },
    wrapperClass: { type: String, default: "" },
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.submitting = false;
        }
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      open: false,
      submitting: false,
    };
  },
  methods: {
    cancelled() {
      this.open = false;
      this.$emit("cancelled");
    },
    reset() {
      this.submitting = false;
    },
    confirmed() {
      this.submitting = true;
      this.$emit("submitting", true);
      this.$emit("confirmed", this.reset);
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  border-radius: 20px !important;
}
</style>
