<template>
  <v-tooltip top color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="{ ...$attrs, ...attrs }"
        :loading="syncing"
        :disabled="syncing"
        outlined
        v-on="on"
        class="px-5 text-none"
        @click="syncUsersToSlack"
        color="primary"
      >
        <v-avatar size="20" class="mr-2">
          <v-img :src="slackIcon" contain></v-img>
        </v-avatar>
        Sync to Slack
      </v-btn>
    </template>
    <span>{{ tip }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  inheritAttrs: false,
  name: "SyncToSlack",
  props: {
    teamId: { type: String, required: true },
    tip: {
      type: String,
      default: 'Having trouble finding your members? Click "Sync to Slack."',
    },
  },
  computed: {
    ...mapGetters("workspace", ["syncing"]),
  },
  data() {
    return {
      slackIcon: require("@/assets/slack/slack-logo.svg"),
    };
  },
  methods: {
    ...mapActions("workspace", [
      "syncWorkspaceUsersToSlack",
      "syncWorkspaceToSlack",
    ]),
    ...mapMutations("workspace", ["set_allusers"]),
    syncUsersToSlack() {
      this.syncWorkspaceUsersToSlack({
        id: this.teamId,
        cb: (updatedUsers) => {
          this.set_allusers(updatedUsers);
          this.appToast("Successfully synced with Slack.", "success");
          this.$auth.fetchUser();
        },
      });

      this.syncWorkspaceToSlack({
        id: this.teamId,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
