<template>
  <v-container fluid class="xm-0 xp-0 xmin-h-screen xbg-gradient-tr-primary">
    <router-view :key="$route.fullPath"></router-view>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
