<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full p">
    <v-tabs
      :optional="optional"
      :replace="replace"
      hide-slider
      center-active
      background-color="transparent"
      show-arrows
    >
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        @click="goTo(tab)"
        active-class="xtext-white tabcustom-active"
        :class="{ 'xtext-white tabcustom-active ': isActive(tab) }"
        class="tabcustom"
      >
        <div
          class="xflex xfont-semibold xflex-row xgap-x-[1em] xjustify-start xitems-center"
        >
          <span class="text-none">{{ tab.name }}</span>
          <v-chip
            small
            color="#E5E5E5"
            v-if="tab.hasOwnProperty('counts')"
            class="xfont-[500] text-[16px] xleading-[19.35px] xtext-[#2e2e2e]"
          >
            {{ tab.counts || 0 }}
          </v-chip>
        </div>
      </v-tab>
    </v-tabs>
    <!-- xborder-[1px] xborder-[#e5e5e5] -->
    <v-card flat class="xmin-h-[70vh]">
      <slot name="default"></slot>
    </v-card>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    tabs: { type: [Array, Object], default: () => [] },
    optional: { type: Boolean, default: false },
    replace: { type: Boolean, default: false },
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    isActive(tab) {
      if (!tab) return false;
      else if (tab.hasOwnProperty("alias"))
        return tab.alias.includes(this.routeName);
      else if (tab.to && this.routeName === tab.to.name) {
        return true;
      } else return false;
    },
    goTo(tab) {
      this.$router.push(tab.to).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.tabcustom {
  background: #fff;
  color: #9a9a9a;
  overflow: hidden;
  border: none !important;
  font-size: 16px;
  line-height: 19.36px;
  &.tabcustom-active {
    color: #6a2c91 !important;
    border-bottom: 3px solid #6a2c91 !important;
  }
}
.xsample {
  border-top-left-radius: 0px !important;
}
</style>
