<template>
  <v-card flat min-height="80vh" color="transparent">
    <v-card-title
      class="xflex xflex-row xjustify-between xgap-[0.5em] xitems-center xw-full xpx-0"
    >
      <div
        class="xflex xflex-row xitems-center xgap-[0.5em]"
        :class="[
          mdUp || tablet
            ? '!xflex-row xflex-nowrap xjustify-between'
            : '!xflex-col xw-full',
        ]"
      >
        <KirbyMonthPicker
          @input="navigate"
          :value="selectedYearMonth"
          no-future
          :class="[mdUp ? '' : 'xw-full']"
        ></KirbyMonthPicker>

        <v-select
          class="xw-full md:xmax-w-[200px]"
          v-model="status"
          outlined
          dense
          hide-details="auto"
          item-text="text"
          item-value="value"
          :items="[
            { text: 'All', value: '' },
            { text: 'Approved', value: 'approved' },
            { text: 'Pending', value: 'pending' },
            { text: 'Denied', value: 'denied' },
          ]"
        >
        </v-select>
      </div>
      <btn-tooltip
        v-if="user.is_paying"
        tip="Add Paid Time Off"
        color="primary"
        :block="smDown"
        :disabled="false"
        :loading="false"
        @click="addPto"
        class="text-none"
      >
        <v-icon left> mdi-plus </v-icon> Add PTO
      </btn-tooltip>
    </v-card-title>
    <app-table
      :loading="fetching"
      :headings="header"
      :items="items"
      v-if="items.length"
    >
      <template v-slot:date="{ item }">
        <span>{{ item.date | format("ll") }}</span>
      </template>

      <template v-slot:range="{ item }">
        <span>{{
          item.duration_seconds | secToHuman(" hours", " minutes")
        }}</span>
      </template>

      <template v-slot:created_at="{ item }">
        <span>{{ item.created_at | format("ll") }}</span>
      </template>

      <template v-slot:status="{ item }">
        <chip-in
          small
          v-if="item.status == 'approved'"
          text="Approved"
        ></chip-in>
        <chip-out
          small
          v-else-if="item.status == 'pending'"
          text="Pending"
        ></chip-out>
        <chip-brb small v-else text="Denied"></chip-brb>
      </template>

      <template v-slot:handler="{ item }">
        <router-link
          v-if="item.denied_user"
          :to="{
            name: 'app-user-record-monthly',
            params: {
              id: item.denied_user.id,
              year_month: yearMonth,
            },
          }"
        >
          <avatar size="36" :user="item.denied_user" with-name></avatar>
        </router-link>
        <router-link
          v-else-if="item.approved_user"
          :to="{
            name: 'app-user-record-monthly',
            params: {
              id: item.approved_user.id,
              year_month: yearMonth,
            },
          }"
        >
          <avatar size="36" :user="item.approved_user" with-name></avatar>
        </router-link>
        <span v-else>None</span>
      </template>

      <template v-slot:actions="{ item }">
        <icon-list icon="mdi-dots-horizontal">
          <v-list dense>
            <v-list-item
              @click.stop="approvePTO(item)"
              v-if="$can.and('manage_user_ptos') && item.status !== 'approved'"
            >
              <v-list-item-title class="xtext-[#2E2E2E]">
                <v-icon color="#2E2E2E" left>mdi-clock-check-outline</v-icon>
                Approve PTO
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.stop="denyPTO(item)"
              v-if="$can.and('manage_user_ptos') && item.status !== 'denied'"
            >
              <v-list-item-title class="xtext-[#2E2E2E]">
                <v-icon color="#2E2E2E" left>mdi-clock-remove-outline</v-icon>
                Deny PTO
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.stop="deletePTO(item)"
              v-if="item.status !== 'approved'"
            >
              <v-list-item-title class="xtext-[#2E2E2E]">
                <v-icon color="#2E2E2E" left>mdi-clock-alert-outline</v-icon>
                Delete PTO
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </icon-list>
      </template>
    </app-table>
    <app-empty
      text="Loading PTO..."
      color="transparent"
      class="xmin-h-[300px] xh-full"
      v-else-if="fetching"
    ></app-empty>

    <app-empty
      text="No PTO found."
      color="transparent"
      class="xmin-h-[300px] xh-full"
      v-else
    ></app-empty>

    <v-card-actions
      v-if="next_page_url"
      :class="[mdUp ? 'xh-[60px]' : '']"
      class="xw-full xflex xflex-col xjustify-center xitems-center"
    >
      <btn-tooltip
        tip="Load more"
        color="primary"
        outlined
        @click="fetchMore"
        :loading="feching_more"
        :disabled="feching_more"
      >
        Load more
      </btn-tooltip>
    </v-card-actions>
    <AddPTOModal
      disable-select-user
      v-model="add_pto_modal"
      :user="user"
    ></AddPTOModal>
  </v-card>
</template>

<script>
import moment from "moment";
import AddPTOModal from "@/views/GlobalComponents/Modals/AddPTOModal.vue";
import { mapGetters } from "vuex";
import KirbyMonthPicker from "@/views/GlobalComponents/Forms/KirbyMonthPicker.vue";

export default {
  components: {
    AddPTOModal,
    KirbyMonthPicker,
  },
  data() {
    return {
      header: [
        { text: "PTO Date", value: "date", sortable: true },
        { text: "Total Time", value: "range", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Approved/Denied By", value: "handler", sortable: true },
        { text: "Created", value: "created_at", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "", value: "actions", sortable: false },
      ],
      add_pto_modal: false,
      items: [],
      next_page_url: null,
      fetching: false,
      feching_more: false,
      status: "",
      selectedYearMonth: moment().format("YYYY-MM"),
    };
  },
  computed: {
    ...mapGetters(["user"]),
    yearMonth() {
      return moment().format("YYYY-MM");
    },
  },
  watch: {
    status() {
      this.fetch();
    },
    "$route.params.year_month": {
      handler: function (val) {
        this.selectedYearMonth = val;
        val && this.fetch();
      },
      immediate: true,
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-my-ptos",
          params: { year_month: val },
        })
        .catch(() => {});
    },
    fetch() {
      this.fetching = true;

      const apiUrl = `api/records/my/ptos/monthly/${this.selectedYearMonth}${
        this.status ? `?status=${this.status}` : ""
      }`;

      this.$axios
        .get(apiUrl)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.fetching = false));
    },
    fetchMore() {
      this.fetching_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.fetching_more = false));
    },
    addPto() {
      this.add_pto_modal = true;
    },
    approvePTO(item) {
      this.appConfirmation(
        `Approve this Paid Time Off?<br /> This will generate time logs as stated in the PTO request.`,
        () => {
          this.$axios
            .put(`api/ptos`, { action: "approved", pto_id: item.id })
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.fetch();
            });
        }
      );
    },
    denyPTO(item) {
      this.appConfirmation(
        `Deny this Paid Time Off? <br /> This will also delete the time logs generated for this PTO request.`,
        () => {
          this.$axios
            .put(`api/ptos`, { action: "denied", pto_id: item.id })
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.fetch();
            });
        }
      );
    },
    deletePTO(item) {
      this.appConfirmation(
        `Delete this Paid Time Off? <br /> This will also delete the time logs generated for this PTO request.`,
        () => {
          this.$axios
            .put(`api/ptos`, { action: "deleted", pto_id: item.id })
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.fetch();
            });
        }
      );
    },
  },
  created() {
    this.fetch();
    this.$event.$on("refetch-ptos", () => this.fetch());
  },
};
</script>

<style lang="scss" scoped></style>
