<template>
  <div
    class="xflex xborder-[1px] !xborder-opacity-100 !xborder-[#d1d1d1] xp-[1px] xrounded-[0.25em] xflex-row xjustify-between xitems-center"
  >
    <div class="xflex xw-[50px] xflex-col xjustify-center xitems-center">
      <icon-tooltip
        color="#212121"
        tip="Previous Month"
        icon="mdi-chevron-left mdi-36px"
        :disabled="noPast && isBeforePrev"
        clickable
        @click="prev"
      >
      </icon-tooltip>
    </div>
    <div class="xflex xflex-col xflex-1 xjustify-center xitems-center">
      <v-menu
        ref="yearmonthmenu"
        v-model="yearmonthmenu"
        :close-on-content-click="false"
        :return-value.sync="yearmonth"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="display"
            readonly
            class="date-display xtext-center"
            dense
            prepend-icon="mdi-calendar-blank-outline"
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          class="kirby-month-picker"
          :min="min"
          :max="max"
          dense
          v-model="yearmonth"
          no-title
          type="month"
          show-adjacent-months
          color="primary"
          @click:month="handleChange"
        >
        </v-date-picker>
      </v-menu>
    </div>
    <div class="xflex xw-[50px] xflex-col xjustify-center xitems-center">
      <icon-tooltip
        color="#212121"
        tip="Next Month"
        icon="mdi-chevron-right mdi-36px"
        :disabled="noFuture && isFutureNext"
        clickable
        @click="next"
      >
      </icon-tooltip>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: moment().format("YYYY-MM"),
    },
    displayFormat: {
      type: String,
      default: "MMMM YYYY",
    },
    noFuture: { type: Boolean, default: false },
    noPast: { type: Boolean, default: false },
  },
  data() {
    return {
      format: "YYYY-MM",
      yearmonth: null,
      yearmonthmenu: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.yearmonth = val ? val : moment().format(this.format);
      },
      immediate: true,
    },
    yearmonth: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  computed: {
    display() {
      return moment(this.yearmonth, this.format).format(this.displayFormat);
    },
    nextMonth() {
      return this.yearmonth
        ? moment(this.yearmonth, this.format)
            .add(1, "month")
            .format(this.format)
        : null;
    },
    prevMonth() {
      return this.yearmonth
        ? moment(this.yearmonth, this.format)
            .subtract(1, "month")
            .format(this.format)
        : null;
    },
    isBeforePrev() {
      return this.prevMonth
        ? moment(this.prevMonth, this.format).isBefore(this.min)
        : false;
    },
    isFutureNext() {
      return this.nextMonth
        ? moment(this.nextMonth, this.format).isAfter(this.max)
        : false;
    },
    min() {
      return this.noPast ? moment().format(this.format) : null;
    },
    max() {
      return this.noFuture ? moment().format(this.format) : null;
    },
  },
  methods: {
    async handleChange(val) {
      const value = val ? moment(val, this.format).format(this.format) : null;
      this.$refs.yearmonthmenu.save(value);
      await this.emit("change", value);
    },
    async prev() {
      await this.emit("change", this.prevMonth);
      this.yearmonth = this.prevMonth;
    },
    async next() {
      await this.emit("change", this.nextMonth);
      this.yearmonth = this.nextMonth;
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
  },
};
</script>

<style lang="scss">
.date-display {
  min-width: 160px !important;

  .v-input__slot {
    &::before {
      border: none !important;
    }
  }
  .display-text {
    font-weight: 600;
    color: #6a6569;
  }
  input {
    box-shadow: none !important;
    border: none !important;
    text-align: center !important;
    padding: 5px !important;
  }
}
</style>
