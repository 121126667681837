var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({attrs:{"flat":"","color":"#E5E5E5"}},'v-card',_vm.$attrs,false),[_c('v-card-title',{staticClass:"xflex flex-row xjustify-between xitems-center xbg-[#E5E5E5]"},[_c('span',{staticClass:"xfont-[700] xtext-[22px] xtext-[#000]"},[_vm._v(" Projects "),_c('v-chip',{staticClass:"xfont-[900]",attrs:{"small":"","color":"#dcdcdc"}},[_vm._v(" "+_vm._s(_vm.counts)+" ")])],1),_c('router-link',{attrs:{"to":_vm.projectPage}},[_c('span',{staticClass:"xtext-[14px] xfont-[600] hover:xtext-[#000000]"},[_vm._v(" View All ")])])],1),_c('v-card-text',{staticClass:"xbg-[#fff] xborder-[1px] !xmin-h-[600px] xborder-gray-100 !xp-[1em] xrounded-[10px]"},[_c('app-table',{attrs:{"loading":_vm.loading,"empty-class":"!xh-[600px]","headings":_vm.headers,"items":_vm.projects},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":"","min-width":"200"}},[_c('v-list-item',{on:{"click":function($event){return _vm.navigateProject(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_vm._v("View")])],1)],1)],1)]}},{key:"total_time_spent",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.summary ? item.summary.total_human : "0m"))])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("ucwords")(item.name)))])]}},{key:"users",fn:function(ref){
var item = ref.item;
return [_c('avatars',{attrs:{"size":"40","users":item.summary.users,"count":8}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }