import Vue from 'vue'
import store from '@/stores/'
import request from '@/plugins/requests'
import router from '@/routers/' 

export const auth = {
    isAuthenticated() {
        return store.getters.user && store.getters.token ? true : false;
    },
    user() {
        return store.getters.user;
    },
    isRegionManager(id) {
        const regions = store.getters['roles/managedRegions'];
        if (!regions || regions.length < 1) return false;
        return regions.find(i => id === i.id) ? true : false
    },
    isSiteManager(id) {
        const sites = store.getters['roles/managedSites'];
        if (!sites || sites.length < 1) return false;
        return sites.find(i => id === i.id) ? true : false
    },
    async refreshCsrfToken() {
        await request.get('sanctum/csrf-cookie');
    },
    setLoginUser(user, token, cb) {
        if (user && token) {
            store.commit('set_user', user, { root: true })
            store.commit('set_token', token, { root: true })
            if (typeof cb === 'function') cb(user, token)
        }
    },
    async logout(cb) {
        await request.get('sanctum/csrf-cookie');
        await request.post('api/auth/logout', {})
            .then(({ data }) => {
                store.commit('set_user', null, { root: true })
                store.commit('set_token', null, { root: true })
                if (typeof cb === 'function') cb(data)
            })
    },
    async login(email, password, cb, err_cb) {
        await request.get('sanctum/csrf-cookie');
        await request.post('api/auth/login', { email, password })
            .then(({ data }) => {
                store.commit('set_user', data.user, { root: true })
                store.commit('set_token', data.token, { root: true })
                if (typeof cb === 'function') cb(data)
            })
            .catch((error) => {
                if (typeof err_cb === 'function') err_cb(error)
            })
    },
    async slackLogin(code, cb) {
        await request.post('api/auth/slack/exchange-code', { code })
            .then(({ data }) => {
                store.commit('set_user', data.user, { root: true })
                store.commit('set_token', data.token, { root: true })
                if (typeof cb === 'function') cb(data)
            })
            .catch(() => {
                router.push({ name: 'home', query: { login: true } })
            })
    },
    async fetchUser(cb) {
        await request.get('api/auth/user', {})
            .then(({ data }) => {
                store.commit('set_user', data.data, { root: true })
                if (typeof cb === 'function') cb(data)
            })
            .catch((err) => {
                store.commit('set_user', null, { root: true })
                store.commit('set_token', null, { root: true })
                router.push({ name: 'home', query: { login: true } }) 
            })
    }
}

const Authentication = {
    install: (Vue) => {
        Vue.prototype.$auth = auth
    }
}

Vue.use(Authentication)

export default Authentication