<template>
  <app-modal
    max-width="600"
    :confirm-label="btnLabel"
    :title="title"
    v-bind="$attrs"
    v-model="open"
    :valid="isValid"
    @confirmed="handleSubmit"
  >
    <v-form v-model="isValid" ref="form">
      <app-input
        v-model="name"
        :rules="[requiredRule()]"
        class="mb-2"
        label="Role Name"
      >
      </app-input>
      <app-textarea
        v-model="description"
        :rows="5"
        class="mb-2"
        label="Role Description"
      />
      <app-input
        type="number"
        min="1"
        step="10"
        max="100"
        :rules="[requiredRule(), minMaxRule(1, 100)]"
        v-model="level"
        class="mb-2"
        label="Role Level"
      />
      <v-checkbox
        hide-details="auto"
        class="my-2"
        v-model="assignable"
        :label="`Can be assign manually?`"
        :true-value="1"
        :false-value="0"
      ></v-checkbox>
      <v-checkbox
        hide-details="auto"
        class="my-2"
        v-model="deletable"
        :label="`Can be deleted by others (except Super Admin?)`"
        :true-value="1"
        :false-value="0"
      ></v-checkbox>
    </v-form>
  </app-modal>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: true },
    item: { type: Object, default: undefined },
  },
  data() {
    return {
      isValid: false,
      open: false,
      name: null,
      description: null,
      level: 1,
      assignable: 0,
      deletable: 0,
    };
  },
  computed: {
    isEdit() {
      return !!this.item;
    },
    title() {
      return this.isEdit ? "Edit Role" : "Add New Role";
    },
    btnLabel() {
      return this.isEdit ? "Save Changes" : "Save New Role";
    },
    payload() {
      return {
        id: this.item ? this.item.id : null,
        name: this.name,
        description: this.description,
        level: this.level,
        assignable: this.assignable,
        deletable: this.deletable,
      };
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    item: {
      handler: function (val) {
        val && this.mapItem(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit(this.isEdit ? "update" : "save", this.payload);
    },
    mapItem(obj) {
      this.name = obj.name || null;
      this.description = obj.description || null;
      this.level = obj.level || null;
      this.assignable = obj.assignable || null;
      this.deletable = obj.deletable || null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
