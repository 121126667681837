<template>
  <v-container
    class="xmin-h-screen xcontainer xmx-auto xflex xflex-col xitems-center xjustify-start !xbg-opacity-0"
    :class="{ '!xbg-none': smDown }"
    :style="
      tabletDown
        ? {}
        : {
            background: `url(${topLeft}) 0px top no-repeat, url(${rightBottom}) right bottom no-repeat, url(${leftBottom}) 0px bottom no-repeat`,
            backgroundColor: '#F3F3F3',
          }
    "
  >
    <v-slide-group center-active :show-arrows="tabletDown" class="xpy-[2em]">
      <v-slide-item class="xmx-[0.5em]">
        <v-btn
          depressed
          :link="$route.name !== 'terms'"
          :color="$route.name === 'terms' ? 'primary' : 'transparent'"
          @click="$router.push({ name: 'terms' })"
          class="text-none xfont-[600] xrounded-full xpy-[5px]"
        >
          Terms of Service
        </v-btn>
      </v-slide-item>

      <v-slide-item class="xmx-[0.5em]">
        <v-btn
          depressed
          :link="$route.name !== 'privacy-policy'"
          :color="$route.name === 'privacy-policy' ? 'primary' : 'transparent'"
          @click="$router.push({ name: 'privacy-policy' })"
          class="text-none xfont-[600] xrounded-full xpy-[5px]"
        >
          Privacy Policy
        </v-btn>
      </v-slide-item>
      <v-slide-item class="xmx-[0.5em]">
        <v-btn
          depressed
          :link="$route.name !== 'copyright-policy'"
          :color="
            $route.name === 'copyright-policy' ? 'primary' : 'transparent'
          "
          @click="$router.push({ name: 'copyright-policy' })"
          class="text-none xfont-[600] xrounded-full xpy-[5px]"
        >
          Copyright Policy
        </v-btn>
      </v-slide-item>
    </v-slide-group> 
    <v-tabs-items v-model="tab" class="!xbg-transparent">
      <v-tab-item value="terms" class="!xbg-transparent">
        <TermsOfService></TermsOfService>
      </v-tab-item>
      <v-tab-item value="privacy_policy" class="!xbg-transparent">
        <PrivacyPolicy></PrivacyPolicy>
      </v-tab-item>
      <v-tab-item value="copyright_policy" class="!xbg-transparent">
        <CopyrightPolicy></CopyrightPolicy>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CopyrightPolicy from "./components/tabs/CopyrightPolicy.vue";
import PrivacyPolicy from "./components/tabs/PrivacyPolicy.vue";
import TermsOfService from "./components/tabs/TermsOfService.vue";
export default {
  data() {
    return {
      topLeft: require("@/assets/covers/feature-t.svg"),
      leftBottom: require("@/assets/covers/feature-lb.svg"),
      rightBottom: require("@/assets/covers/feature-rb.svg"),
      slackLogo: require("@/assets/slack/slack-logo.svg"),
    };
  },
  watch: {
    "$route.name": {
      handler: function (val) {
        // if (val && val === "privacy-policy") this.tab = "privacy_policy";
        // else if (val && val === "terms") this.tab = "terms";
        // else this.tab = "copyright_policy";
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("appconfigs", ["slack_client_id"]),
    tab() {
      if (this.$route.name === "privacy-policy") return "privacy_policy";
      else if (this.$route.name === "terms") return "terms";
      else return "copyright_policy";
    },
  },
  methods: {},
  components: { TermsOfService, PrivacyPolicy, CopyrightPolicy },
};
</script>

<style lang="scss" scoped></style>
