<template>
  <div v-bind="divAttrs">
    <v-autocomplete
      dense
      v-bind="$attrs"
      v-model="selected"
      outlined
      append-icon="mdi-chevron-down"
      hide-details="auto"
      item-text="name"
      item-value="id"
      :items="filteredDC"
      :prepend-inner-icon="hasCreate ? `mdi-plus-circle-outline` : null"
      @click:prepend-inner="openModal = true"
    >
      <template v-slot:item="{ item }">
        <span>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="xfont-semibold font-weight-bold">
                {{ item.name | ucwords }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.region.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
      </template>
      <template v-slot:no-data>
        <v-list-item v-if="hasCreate">
          <span class="subheading">
            Click <v-icon small>mdi-plus-circle-outline</v-icon> to create new
            data center
          </span>
        </v-list-item>
        <v-list-item v-else>
          <span class="subheading"> No data center available. </span>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip color="transparent" label small class="mr-auto w-full my-1">
          <span class="xfont-[500] xtext-[15px]">{{ item.name }}</span>
        </v-chip>
      </template>
    </v-autocomplete>

    <DataCenterModal
      v-model="openModal"
      :item="null"
      @saved="fetch"
      @updated="fetch"
    ></DataCenterModal>
    <!-- <pre>{{ dataCenters }}</pre> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataCenterModal from "@/views/AppDashboard/Settings/components/DataCenterModal.vue";
export default {
  inheritAttrs: false,
  components: { DataCenterModal },
  props: {
    divAttrs: { type: Object, default: undefined },
    value: { type: Number, default: null },
    region: { type: Number, default: null },
    hasCreate: { type: Boolean, default: false },
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val;
      },
      immediate: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      openModal: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["dataCenters"]),
    filteredDC() {
      if (!this.region || this.region < 1) return this.dataCenters;
      else return this.dataCenters.filter((i) => i.region_id === this.region);
    },
  },
  mounted() {
    if (!this.dataCenters.length) this.fetch();
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceDataCenters"]),
    fetch() {
      this.fetchWorkspaceDataCenters({ id: this.user.team_id });
    },
  },
};
</script>

<style lang="scss" scoped></style>
