<template>
  <v-container fluid class="xbg-[#fff]">
    <!-- <NewInstallationGuideModal v-if="canSetup"></NewInstallationGuideModal> -->
    <!-- <AskAdminOwnersModal v-else></AskAdminOwnersModal> -->
    <router-view :key="$route.fullPath"></router-view>
  </v-container>
</template>

<script>
// import NewInstallationGuideModal from "@/views/GlobalComponents/Modals/NewInstallationGuideModal.vue";
// import AskAdminOwnersModal from "@/views/GlobalComponents/Modals/AskAdminOwnersModal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user", "newInstall"]),
    // canSetup() {
    //   return this.$can.or(
    //     "update_team_preferences|manage_member_subscription|update_subscription"
    //   );
    // },
  },
  created() {
    this.fetchWorkspaceInfo(this.user.team_id);
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceInfo"]),
  },
  components: {
    // NewInstallationGuideModal,
    // AskAdminOwnersModal,
  },
};
</script>

<style lang="scss" scoped></style>
