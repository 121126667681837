<template>
  <v-card flat min-height="100vh" class="xp-[1em]" color="transparent">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Roles</h1>
    </v-card-title>
    <v-card flat class="xpy-[1em]">
      <v-card-text>
        <div class="xflex mb-2 xflex-row xjustify-end xitems-center">
          <btn-tooltip
            color="primary"
            @click="openRoleModal(true, null)"
            tip="Add new role"
            outlined
          >
            <v-icon>mdi-plus</v-icon> Add New Role
          </btn-tooltip>
        </div>
        <app-table :headings="headers" :items="items" :loading="loading">
          <template v-slot:actions="{ item }">
            <div class="xflex xflex-row xjustify-center xitems-center xgap-2">
              <icon-tooltip
                @click="openRoleModal(true, item)"
                tip="Edit Role"
                clickable
                color="primary"
                icon="mdi-pencil"
              >
              </icon-tooltip>
              <icon-tooltip
                @click="handleDelete(item)"
                tip="Delete Role"
                color="error"
                clickable
                icon="mdi-delete"
              >
              </icon-tooltip>
            </div>
          </template>
          <template v-slot:workspace="{ item }">
            <span class="xtext-[12px]">
              {{ item.workspace ? item.workspace.name : "System [APP]" }}
            </span>
          </template>
          <template v-slot:deletable="{ item }">
            <v-icon color="primary" v-if="item.deletable">
              mdi-account-check
            </v-icon>
            <v-icon color="error" v-else>mdi-account-cancel</v-icon>
          </template>
          <template v-slot:assignable="{ item }">
            <v-icon color="primary" v-if="item.assignable">
              mdi-account-check
            </v-icon>
            <v-icon color="error" v-else>mdi-account-cancel</v-icon>
          </template>
        </app-table>
      </v-card-text>
      <v-card-actions
        class="xflex xflex-col xjustify-center xitems-center xpy-[0.5em]"
      >
        <v-btn
          color="primary"
          depressed
          :loading="loading_more"
          class="text-none px-5"
          :disabled="loading || loading_more || !next_page_url"
          @click="fetchMoreRoles"
        >
          Load more
        </v-btn>
      </v-card-actions>
    </v-card>
    <AddEditRole
      v-model="roleModal"
      :item="selected"
      @save="handleSave"
      @update="handleUpdate"
    />
  </v-card>
</template>

<script>
import AddEditRole from "./subs/AddEditRole.vue";
export default {
  components: { AddEditRole },
  data() {
    return {
      roleModal: false,
      selected: null,
      headers: [
        { text: "Name", value: "name", width: 180 },
        { text: "Workspace", value: "workspace", sortable: false },
        { text: "Level", value: "level", width: 100 },
        { text: "Description", value: "description", sortable: true },
        { text: "Deletable", value: "deletable", sortable: true, width: 120 },
        { text: "Assignable", value: "assignable", sortable: true, width: 120 },
        { value: "actions", sortable: false, align: "center" },
      ],
      tabs: [{ name: "Roles", to: { name: "admin-roles" } }],
      items: [],
      next_page_url: null,
      loading: false,
      loading_more: false,
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      this.loading = true;
      this.$axios
        .get("api/roles/admins")
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMoreRoles() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
    openRoleModal(open, item) {
      this.selected = item;
      this.roleModal = open;
    },
    handleDelete(item) {
      this.appConfirmation(
        "Delete this role?",
        () => {
          this.$axios.delete(`api/roles/${item.id}`).then(({ data }) => {
            let index = this.items.findIndex((i) => i.id === item.id);
            if (~index) {
              this.items.splice(index, 1);
              this.appToast("Role successfully deleted.", "success");
            }
          });
        },
        null,
        "Confirm Delete"
      );
    },
    handleSave(payload) {
      this.$axios
        .post(`api/roles`, payload)
        .then(({ data }) => {
          this.items.push(data);
          this.appToast("Role successfully created.", "success");
        })
        .finally(() => this.openRoleModal(false, null));
    },
    handleUpdate(payload) {
      this.$axios
        .put(`api/roles/${payload.id}`, payload)
        .then(({ data }) => {
          let index = this.items.findIndex((i) => i.id === data.id);
          if (~index) {
            this.items.splice(index, 1, data);
            this.appToast("Role successfully updated.", "success");
          }
        })
        .finally(() => this.openRoleModal(false, null));
    },
  },
};
</script>

<style lang="scss" scoped></style>
