<template>
  <v-card
    color="transparent"
    flat
    class="xflex xflex-col xmin-h-screen xw-full"
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <div
      class="xflex md:xflex-row xflex-col xitems-stretch xgap-x-[1em] xjustify-start"
    >
      <SelfMetric
        class="xh-full"
        :class="{
          'xw-2/3 xflex-1 !xmin-h-[calc(100vh-200px)]': laptopUp,
          '!xw-full': !laptopUp,
        }"
      ></SelfMetric>
      <!-- <v-divider
        vertical
        class="xmin-h-[calc(100vh-200px)] xbg-[#F1F1F1]"
      ></v-divider> -->
      <div
        :class="{ 'xw-1/3 xmax-w-[700px]': laptopUp, '!xw-full': !laptopUp }"
        class="xmin-h-[calc(100vh-200px)] xp-[1em] xh-full"
      >
        <UsersWidget class="xh-full xw-full"></UsersWidget>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ProjectWidget from "./components/ProjectWidget.vue";
import SelfMetric from "./components/SelfMetric.vue";
import UsersWidget from "./components/UsersWidget.vue";
export default {
  data() {
    return {
      lower_bottom: require("@/assets/images/data-work.svg"),
      cover: require("@/assets/covers/covers (3).webp"),
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["workspace", "users_status"]),
    currentUserStatus() {
      const user = this.users_status.find((i) => i.id === this.user.id);
      return user ? user.clocking_status : null;
    },
  },
  created() {
    this.fetchWorkspaceUsersStatus(this.user.team_id);
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceUsersStatus"]),
  },
  components: {
    ProjectWidget,
    UsersWidget,
    SelfMetric,
  },
};
</script>

<style lang="css" scoped></style>
