import request from '@/plugins/requests'
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        userProjects: [],
        projects: [],
        project: null,
        fetching: false
    },
    getters: {
        fetching: (state) => state.fetching,
        userProjects: (state) => state.userProjects,
        projects: (state) => state.projects,
        project: (state) => state.project,
        projectGet: (state) => (id) => {
            return state.projects.find(i => i.id === id);
        },
    },
    mutations: {
        set_fetching: (state, payload) => state.fetching = payload,
        setUserProjects: (state, payload) => (state.userProjects = payload),
        setProjects: (state, payload) => (state.projects = payload),
        setProject: (state, payload) => (state.project = payload),
        storeProject: (state, payload) => {
            let index = state.projects.findIndex(i => i.id === payload.id);
            if (~index) {
                state.projects.splice(index, 1, payload)
            } else {
                state.projects.push(payload)
            }
        },
        deleteProject: (state, payload) => {
            let index = state.projects.findIndex(i => i.id === payload.id);
            if (~index) {
                state.projects.splice(index, 1)
            }
        }
    },
    actions: {
        fetchUserProjects: ({ commit }, userId) => {
            commit('set_fetching', true);
            request
                .get(`api/user/${userId}/involved-projects`)
                .then(({ data }) => {
                    commit('setUserProjects', data);
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchProject: ({ commit }, id) => {
            commit('set_fetching', true);
            request
                .get(`api/projects/${id}`)
                .then(({ data }) => {
                    commit('setProject', data);
                    commit('storeProject', data);
                })
                .finally(() => commit('set_fetching', false))
        }
    }
}