<template>
  <app-modal
    :has-footer="false"
    :max-width="maxWidth"
    title="Backlog Info"
    v-model="open"
  >
    <v-card v-if="item" color="transparent" flat class="xmb-2">
      <v-card-title>Props</v-card-title>
      <pre>{{ item.props }}</pre>
    </v-card>
    <v-divider />
    <v-card v-if="item" color="transparent" flat class="xmb-2">
      <v-card-title>Inputs</v-card-title>
      <pre>{{ item.inputs }}</pre>
    </v-card>
  </app-modal>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    maxWidth: { type: [String, Number], default: 900 },
    item: { type: Object, default: undefined },
  },
  data() {
    return {
      open: false,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="css">
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
