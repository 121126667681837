<template>
  <v-chip outlined :color="approved ? '#19A917' : '#FC3D39'" v-bind="$attrs">
    <div
      class="xpx-[8px] xpy-[2px] xgap-x-[5px] !xmin-w-[33px] xmin-h-[20px] xflex xjustify-center xitems-center"
    >
      <span :class="approved ? 'xtext-text-work' : 'xtext-text-out'">
        {{ text }}
      </span>
      <span :class="approved ? 'xtext-text-work' : 'xtext-text-out'">
        ({{ status }})
      </span>
    </div>
  </v-chip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    approved: { type: Boolean, default: false },
    text: { type: String, default: "Added" },
  },
  computed: {
    status() {
      return this.approved ? "Approved" : "Pending";
    },
  },
};
</script>

<style></style>
