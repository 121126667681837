<template>
  <Modal
    v-model="open"
    :valid="true"
    :fullscreen-on-mobile="false"
    class="xrounded-lg"
    :max-width="maxWidth"
    :confirm-label="confirmLabel"
    @cancelled="cancel_clicked"
    @confirmed="confirm_clicked"
  >
    <template v-slot:title>
      <span class="xfont-bold xtext-red-800">{{ title }}</span>
    </template>
    <v-sheet
      color="transparent"
      class="xmin-h-[100px] xflex xflex-col xjustify-center xitems-center"
    >
      <div
        class="xfont-semibold xtext-gray-900 xtext-[16px] xbg-transparent"
        v-html="text"
      ></div>
    </v-sheet>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      maxWidth: 500,
      open: false,
      confirmLabel: "Confirm",
      text: "Are you sure you want to continue?",
      title: "Confirmation Required!",
      yesCallback: undefined,
      noCallback: undefined,
    };
  },
  created() {
    this.$event.$on(
      "open_confirmation_modal",
      (message, yescb, nocb, confirmLabel) => {
        this.text = message || this.text;
        this.yesCallback = yescb;
        this.noCallback = nocb;
        this.confirmLabel = confirmLabel || this.confirmLabel;
        this.open = true;
      }
    );
  },

  methods: {
    close_dialog() {
      this.open = false;
      this.yesCallback = undefined;
      this.noCallback = undefined;
    },
    cancel_clicked() {
      if (typeof this.noCallback === "function") {
        this.noCallback();
      }
      this.$nextTick(() => this.close_dialog());
    },
    confirm_clicked() {
      if (typeof this.yesCallback === "function") {
        this.yesCallback();
      }
      this.$nextTick(() => this.close_dialog());
    },
  },
};
</script>

<style scoped></style>
