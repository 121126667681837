import Vue from 'vue'
import axios from 'axios'
import store from '@/stores/'
import functions from '@/utils/global_methods';
import { environment } from '@/environment'

axios.defaults.withCredentials = false;

const checkNested = (obj, level, ...rest) => {
    if (obj === undefined) return false
    if (rest.length == 0 && obj.hasOwnProperty(level)) return true
    return checkNested(obj[level], ...rest)
}

const getNested = (obj, ...args) => {
    return args.reduce((obj, level) => obj && obj[level], obj)
}

const request = axios.create({
    baseURL: `${environment.apiBase}/`,
    timeout: 400000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
    },
})

request.interceptors.request.use(
    (request) => {
        request.headers.Authorization = 'Bearer ' + store.getters.token
        return request
    },
    (error) => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (checkNested(error, 'response', 'data')) {
            const data = getNested(error, 'response', 'data')
            if (checkNested(data, 'message')) {
                let message = getNested(data, 'message');
                if (checkNested(data, 'errors')) {
                    let errors = Object.values(data.errors)
                    message = message + `<br>` + errors.flat().join('<br>')
                }
                functions.appToast(message, 'error')
            }
        }
        return Promise.reject(error)
    })

Vue.prototype.$axios = request

export default request