export default {
    resetValidation() {
        if (this.$refs.form) this.$refs.form.resetValidation()
    },
    passwordConfirmationRule(password, v) {
        return (v) => (!!v && password === v) || `Confirmation password must match with password`
    },
    minLengthRule(minLength, v) {
        return (v) => (!!v && v.length >= minLength) || `Minimum length is ${minLength}`
    },
    maxLengthRule(maxLength, v) {
        return (v) => !v || v.length <= maxLength || `Max length is ${maxLength}`
    },
    minRule(min, v) {
        if (!v) return true
        if (!min) return 'Minimum value is not set'
        return (v) => (!v || (min && v && Number(v) >= min)) || `Min value is ${min}`
    },
    maxRule(max, v) {
        if (!v) return true
        if (!max) return 'Maximum value is not set'
        return (v) => (!v || (max && v && Number(v) <= max)) || `Max value is ${max}`
    },
    minAmountRule(min, v) {
        if (!min || typeof min === 'undefined') {
            return true
        }
        return (v) => (min && v && Number(v) >= min) || `Min value is ${min}`
    },
    maxAmountRule(max, v) {
        if (!max || typeof max === 'undefined') {
            return true
        }
        return (v) => (max && v && Number(v) <= max) || `Max value is ${max}`
    },
    minMaxRule(min, max, v) {
        if (!max || !min) return true 
        return (v) => (v && Number(v) <= Number(max) && Number(v) >= Number(min)) || `Value should be from ${min} to ${max}`
    },
    numericRule(v) {
        return (!v || (!isNaN(Number(v)) && isFinite(v))) || 'Value must be numeric'
    },
    positiveIncludeZeroRule(v) {
        if (!isNaN(v)) return 'This field is required'
        return (!isNaN(v) && Number(v) >= 0) || `Min value is 0.00`
    },
    positiveMoreThanZeroRule(v) {
        if (!isNaN(v)) return 'This field is required';
        if (!isNaN(v) && isFinite(v)) return 'Value must be numeric';
        if (!isNaN(v) && Number(v) <= 999999999) return `Max value is 999999999.99`;
        return (!isNaN(v) && Number(v) > 0) || `Min value is 1.00`
    },
    requiredRule(v) {
        return (v) => !!v || 'This field is required'
    },
    optionalRule(v) {
        return (v) => (!!v || v) || 'Optional field'
    },
    numberOnlyRules(v) {
        const reg = new RegExp('^\\d+$')
        return (v) => (!v || reg.test(v)) || `Only number is allowed`
    },
    numberDotOnlyRules(v) {
        const reg = /^-?\d*\.?\d*$/
        return (v) => (!v || reg.test(v)) || `Only number and dot is allowed`
    },
    emailRule(v) {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        return (v) => (!v || regex.test(v)) || 'Invalid Email address'
    },
    multipleEmailRule(v) {
        return (v) =>
            (!v || /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/.test(v)) || 'Value must be comma separated emails'
    },
    urlRule(v) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        ) // fragment locator
        return (v) => (!!v && pattern.test(v.split('#')[0])) || 'Invalid URL'
    }, 
}