<template>
  <btn-list :label="label" btn-color="primary" v-bind="$attrs">
    <v-list>
      <v-list-item
        v-for="fltr in _filters"
        :key="fltr.value"
        @click="setFilter(fltr.value)"
      >
        <v-list-item-title> {{ fltr.text }} </v-list-item-title>
      </v-list-item>
    </v-list>
  </btn-list>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: { type: String, default: "monthly" },
    noMonthly: { type: Boolean, default: false },
    noWeekly: { type: Boolean, default: false },
    noDaily: { type: Boolean, default: false },
    noCustom: { type: Boolean, default: false },
  },
  computed: {
    _filters() {
      let items = [];
      if (!this.noMonthly) items.push({ text: "Month", value: "monthly" });
      if (!this.noWeekly) items.push({ text: "Week", value: "weekly" });
      if (!this.noDaily) items.push({ text: "Day", value: "daily" });
      if (!this.noCustom) items.push({ text: "Custom", value: "custom" });
      return items;
    },
    label() {
      return this.type === "monthly"
        ? "Month"
        : this.type === "weekly"
        ? "Week"
        : this.type === "daily"
        ? "Day"
        : "Custom";
    },
  },
  methods: {
    setFilter(type) {
      this.$emit(type, true);
    },
  },
};
</script>

<style lang="scss" scoped></style>
