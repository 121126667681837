<template>
  <div
    class="xflex xborder-[1px] xp-[1px] xrounded-[0.25em] !xborder-opacity-100 xflex-row xjustify-between xitems-center !xborder-[#D1D1D1]"
  >
    <div class="xflex xw-[50px] xflex-col xjustify-center xitems-center"> 
      <icon-tooltip
        color="#212121"
        tip="Previous Day"
        icon="mdi-chevron-left mdi-36px"
        :disabled="noPast && isBeforePrev"
        clickable
        @click="prev"
      >
      </icon-tooltip>
    </div>
    <div class="xflex xflex-col xflex-1 xjustify-center xitems-center">
      <v-menu
        ref="datemenu"
        v-model="datemenu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="display"
            readonly
            class="date-display xtext-center"
            dense
            prepend-icon="mdi-calendar-blank-outline"
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          class="kirby-date-picker"
          :min="min"
          :max="max"
          v-model="date"
          no-title
          show-adjacent-months
          color="primary"
          @click:date="handleChange"
        >
        </v-date-picker>
      </v-menu>
    </div>
    <div class="xflex xw-[50px] xflex-col xjustify-center xitems-center"> 
      <icon-tooltip
        color="#212121"
        tip="Next Day"
        icon="mdi-chevron-right mdi-36px"
        :disabled="noFuture && isFutureNext"
        clickable
        @click="next"
      >
      </icon-tooltip>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: moment().format("YYYY-MM-DD"),
    },
    displayFormat: {
      type: String,
      default: "LL",
    },
    noFuture: { type: Boolean, default: false },
    noPast: { type: Boolean, default: false },
  },
  data() {
    return {
      format: "YYYY-MM-DD",
      date: null,
      datemenu: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.date = val ? val : moment().format(this.format);
      },
      immediate: true,
    },
    date: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  computed: {
    display() {
      return moment(this.date, this.format).format(this.displayFormat);
    },
    nextDay() {
      return this.date
        ? moment(this.date, this.format).add(1, "day").format(this.format)
        : null;
    },
    prevDay() {
      return this.date
        ? moment(this.date, this.format).subtract(1, "day").format(this.format)
        : null;
    },
    isBeforePrev() {
      return this.prevDay
        ? moment(this.prevDay, this.format).isBefore(this.min)
        : false;
    },
    isFutureNext() {
      return this.nextDay
        ? moment(this.nextDay, this.format).isAfter(this.max)
        : false;
    },
    min() {
      return this.noPast ? moment().format(this.format) : null;
    },
    max() {
      return this.noFuture ? moment().format(this.format) : null;
    },
  },
  methods: {
    async handleChange(val) {
      const value = val ? moment(val, this.format).format(this.format) : null;
      this.$refs.datemenu.save(value);
      await this.emit("change", value);
    },
    async prev() {
      await this.emit("change", this.prevDay);
      this.date = this.prevDay;
    },
    async next() {
      await this.emit("change", this.nextDay);
      this.date = this.nextDay;
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
  },
};
</script>

<style lang="scss">
.date-display {
  min-width: 160px !important;

  .v-input__slot {
    &::before {
      border: none !important;
    }
  }
  .display-text {
    font-weight: 600;
    color: #6a6569;
  }
  input {
    box-shadow: none !important;
    border: none !important;
    text-align: center !important;
    padding: 5px !important;
  }
}
</style>
