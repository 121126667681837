<template>
  <v-card flat color="transparent" min-height="100vh">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Admin Dashboard</h1>
    </v-card-title>
    <v-card-text
      class="xgrid md:xgrid-cols-2 xgrid-cols-1 xgap-[2em] xjustify-start xitems-stretch"
    >
      <v-card height="400" class="!xrounded-lg xp-2 xw-full xh-full">
        <v-card-title>Team Statistics</v-card-title>
        <v-card-text>
          <BarChart :items="team_statistics" title="Workspace Statistics" />
        </v-card-text>
      </v-card>
      <v-card height="400" class="!xrounded-lg xp-2 xw-full xh-full">
        <v-card-title class="xflex xflex-row xjustify-between xitems-center">
          <span>New Registered Team</span>
          <v-btn
            outlined
            small
            color="primary"
            @click="$router.push({ name: 'admin-subscribers-subscribed' })"
          >
            View All
          </v-btn>
        </v-card-title>
        <v-card-text>
          <app-table
            :headings="team_headers"
            :items="latestTeam"
            :loading="fetching"
          >
            <template v-slot:created_at="{ item }">
              <span>{{ item.created_at | format("lll") }}</span>
            </template>
          </app-table>
        </v-card-text>
      </v-card>
      <v-card height="400" class="!xrounded-lg xp-2 xw-full xh-full">
        <v-card-title>User Statistics</v-card-title>
        <v-card-text>
          <BarChart :items="user_statistics" title="Users Statistics" />
        </v-card-text>
      </v-card>
      <v-card
        height="400"
        class="!xrounded-lg xp-2 xw-full xh-full xoverflow-hidden"
      >
        <v-card-title class="xflex xflex-row xjustify-between xitems-center">
          <span>New Feedbacks</span>
          <v-btn
            outlined
            small
            color="primary"
            @click="$router.push({ name: 'admin-feedbacks' })"
          >
            View All
          </v-btn>
        </v-card-title>
        <v-card-text>
          <app-table
            empty-text="No new feedbacks"
            :headings="feedback_headers"
            :items="latestFeedback"
            :loading="fetching"
          >
            <template v-slot:user="{ item }">
              <avatar v-if="item.user" :user="item.user" with-name></avatar>
            </template>
            <template v-slot:team="{ item }">
              <span v-if="item.team">{{ item.team.name }}</span>
              <span v-else> - </span>
            </template>
            <template v-slot:created_at="{ item }">
              <span>{{ item.created_at | format("lll") }}</span>
            </template>
          </app-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { THEME_KEY } from "vue-echarts";
import BarChart from "@/views/GlobalComponents/Charts/BarChart.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      loading: false,
      latestTeam: [],
      latestFeedback: [],
      team_headers: [
        { text: "Team", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Registered", value: "created_at", sortable: false },
        { text: "Members", value: "users_count", sortable: false },
      ],
      feedback_headers: [
        { text: "User", value: "user", sortable: false },
        { text: "Team", value: "team", sortable: false },
        { text: "Message", value: "message", sortable: false },
        { text: "Date", value: "created_at", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("admins", [
      "fetching",
      "hasFetch",
      "team_statistics",
      "user_statistics",
    ]),
  },
  created() {
    this.fetchStatistics();
    this.fetchLatestTeam();
    this.fetchLatestFeedback();
  },
  methods: {
    ...mapActions("admins", ["fetchStatistics"]),
    fetchLatestTeam() {
      this.loading = true;
      this.$axios
        .get(`admin/dashboard/latest-team`)
        .then(({ data }) => {
          this.latestTeam = data;
        })
        .finally(() => (this.loading = false));
    },
    fetchLatestFeedback() {
      this.loading = true;
      this.$axios
        .get(`admin/dashboard/latest-feedback`)
        .then(({ data }) => {
          this.latestFeedback = data;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { BarChart },
};
</script>
