<template>
  <app-modal
    :valid="validForm"
    :title="isEdit ? 'Update Project' : 'Create New Project'"
    v-model="open"
    :max-width="800"
    @confirmed="isEdit ? handleUpdate() : handleSave()"
  >
    <v-card flat>
      <v-card-text class="xflex xflex-col xgap-y-[0.5em]">
        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Project Name *
        </label>
        <app-input
          type="text"
          :rules="[requiredRule()]"
          v-model="name"
          class="mb-2"
        ></app-input>

        <label
          class="xfont-bold xleading-[1em] xflex xflex-row xjustify-between xitems-center !xmy-[10px]"
        >
          <span>Project Short Code *</span>
          <icon-tooltip
            size="14"
            tip="Can be use on clocking e.g /in my-shortcode "
            icon="mdi-progress-question"
          ></icon-tooltip>
        </label>

        <app-input
          type="text"
          :rules="[requiredRule()]"
          v-model="short_code"
          class="mb-2"
        >
        </app-input>

        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Project Description
        </label>
        <app-textarea :rows="2" v-model="remark" class="xmb-2"></app-textarea>

        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Assign Admin
        </label>
        <UserSelect v-model="admin_id" class="xmb-2" clearable></UserSelect>

        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Assign Members
        </label>
        <UsersSelect v-model="members_id" clearable class="xmb-2"></UsersSelect>

        <label class="xfont-bold xleading-[1em] !xmy-[10px]">
          Assign Office Site
        </label>
        <SiteSelect v-model="site_id" clearable class="xmb-2"></SiteSelect>

        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Assign Data Center
        </label>
        <DataCenterSelect
          v-model="data_center_id"
          :region="selectedRegion"
          class="xmb-2"
          clearable
        ></DataCenterSelect>
      </v-card-text>
      <!-- <pre>{{ project }}</pre> -->
    </v-card>
  </app-modal>
</template>

<script>
import SiteSelect from "@/views/GlobalComponents/Forms/SiteSelect.vue";
import DataCenterSelect from "@/views/GlobalComponents/Forms/DataCenterSelect.vue";
import UserSelect from "@/views/GlobalComponents/Forms/UserSelect.vue";
import UsersSelect from "@/views/GlobalComponents/Forms/UsersSelect.vue";
import slugify from "slugify";
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
    project: { type: Object, default: undefined },
  },
  data() {
    return {
      open: false,
      name: null,
      short_code: null,
      remark: null,
      site_id: null,
      data_center_id: null,
      admin_id: null,
      members_id: [],
    };
  },
  computed: {
    ...mapGetters("workspace", ["sites"]),
    selectedRegion() {
      if (!this.site_id || !this.sites.length) return null;
      let site = this.sites.find((i) => i.id === this.site_id);
      return site ? site.region_id : null;
    },
    isEdit() {
      return !!this.project;
    },
    payload() {
      return {
        name: this.name,
        short_code: this.short_code,
        remark: this.remark,
        site_id: this.site_id,
        data_center_id: this.data_center_id,
        admin_id: this.admin_id,
        members_id: this.members_id,
      };
    },
    validForm() {
      return !!this.name && !!this.short_code;
    },
  },
  watch: {
    value: {
      handler: function (val) {
        if (!this.isEdit && val) this.clearInputs();
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
    name: {
      handler: function (val) {
        if (val) {
          this.short_code = slugify(val.toLowerCase());
        }
      },
      immediate: true,
    },
    project: {
      handler: function (val) {
        this.name = val ? val.name : null;
        this.short_code = val ? val.short_code : null;
        this.remark = val ? val.remark : null;
        this.site_id = val ? val.site_id : null;
        this.data_center_id = val ? val.data_center_id : null;
        this.admin_id = val && val.admin ? val.admin.id : null;
        this.members_id =
          val && val.project_users && val.project_users.length
            ? val.project_users.map((i) => i.id)
            : [];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    clearInputs() {
      this.name = null;
      this.remark = null;
      this.short_code = null;
      this.site_id = null;
      this.data_center_id = null;
      this.admin_id = null;
      this.members_id = [];
    },
    handleSave() {
      this.$axios
        .post(`api/projects`, this.payload)
        .then(({ data }) => {
          this.$emit("new-item", data);
          this.appToast("New project created.", "success");
        })
        .finally(() => (this.open = false));
    },
    handleUpdate() {
      this.$axios
        .put(`api/projects/${this.project.id}`, this.payload)
        .then(({ data }) => {
          this.$emit("update-item", data);
          this.appToast("Project updated.", "success");
        })
        .finally(() => (this.open = false));
    },
  },
  components: { SiteSelect, DataCenterSelect, UserSelect, UsersSelect },
};
</script>

<style lang="scss" scoped></style>
