<template>
  <v-card flat min-height="100vh" class="xp-[1em]" color="transparent">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Teams</h1>
    </v-card-title>
    <v-card flat class="xpt-[1em]">
      <app-tabs optional :tabs="tabs">
        <router-view :key="$route.fullPath"></router-view>
      </app-tabs>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    statistics: null,
    loading: false,
  }),
  computed: {
    ...mapGetters("admins", ["fetching", "hasFetch", "team_statistics"]),
    tabs() {
      return [
        {
          name: "Subscribed",
          to: {
            name: "admin-subscribers-subscribed",
            params: { filter: "subscribed" },
          },
          counts: this.getByFilter("Subscribed Team"),
        },
        {
          name: "Cancelled",
          to: {
            name: "admin-subscribers-cancelled",
            params: { filter: "cancelled" },
          },
          counts: this.getByFilter("Cancelled Team"),
        },
        {
          name: "Exempted",
          to: {
            name: "admin-subscribers-exempted",
            params: { filter: "exempted" },
          },
          counts: this.getByFilter("Exempted Team"),
        },
        {
          name: "Ongoing Free Trial",
          to: {
            name: "admin-subscribers-ongoing-free-trial",
            params: { filter: "ongoing-free-trial" },
          },
          counts: this.getByFilter("Ongoing Trial Team"),
        },
        {
          name: "Expired Free Trial",
          to: {
            name: "admin-subscribers-expired-free-trial",
            params: { filter: "expired-free-trial" },
          },
          counts: this.getByFilter("Expired Trial Team"),
        },
        {
          name: "Disabled Team",
          to: {
            name: "admin-subscribers-disabled",
            params: { filter: "disabled" },
          },
          counts: this.getByFilter("Disabled Team"),
        },
      ];
    },
  },
  created() {
    if (!this.hasFetch) this.fetchStatistics();
  },
  methods: {
    ...mapActions("admins", ["fetchStatistics"]),
    getByFilter(name) {
      let item = this.team_statistics.find((i) => i.name === name);
      return item ? item.value : 0;
    },
    fetchWorkspaces() {
      this.loading = true;
      this.$axios
        .get(`admin/dashboard/statistics`)
        .then(({ data }) => {
          this.statistics = data.team;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
