<template>
  <v-card min-height="100vh" width="100%" flat>
    <v-card-text
      class="xw-full xmin-h-[90vh] xflex xflex-col xjustify-center xitems-center"
    >
      <div class="xw-6/12 xmx-auto xmin-h-500">
        <h1 class="xpy-2 xfont-bold xtext-[20px]">Forgot Password.</h1>
        <p class="xpy-2">
          An email confirmation will be sent to the provided email address.
        </p>
        <v-form ref="form" v-model="valid">
          <label class="xfont-bold">Enter email:</label>
          <app-input
            :rules="[requiredRule(), emailRule()]"
            v-model="email"
          ></app-input>
        </v-form>

        <v-btn
          color="primary"
          :loading="submitting"
          :disabled="!email || submitting || !valid"
          class="text-none xmy-2"
          @click="submit"
        >
          Submit
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      email: null,
      submitting: false,
    };
  },
  mounted() {
    if (this.$auth.isAuthenticated())
      this.$router.push({ name: "home" }).catch(() => {});
  },
  methods: {
    submit() {
      this.submitting = true;
      this.$axios
        .post(`api/auth/forgot-password`, { email: this.email })
        .then(({ data }) => {
          this.appToast(data.message, "info");
        })
        .finally(() => (this.submitting = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
