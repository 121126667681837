<template>
  <v-chart v-bind="$attrs" autoresize class="chart" :option="options" />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: { type: String, default: "Statistics" },
    seriesName: { type: String, default: "Count" },
  },
  computed: {
    titles() {
      return this.items.map((i) => i.name);
    },
    datum() {
      return this.items.map((i) => i.value);
    },
    options() {
      return {
        backgroundColor: "#ffffff",
        title: {
          text: "",
        },
        tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.titles,
            axisLabel: { rotate: 45 },
            axisTick: { alignWithLabel: true},
            nameTextStyle: {
              overflow: "truncate",
            },
          },
        ],
        yAxis: [{ type: "value" }],
        series: [
          {
            name: this.seriesName,
            type: "bar",
            barWidth: "50%",
            data: this.datum,
          },
        ],
        color: {
          type: "linear",
          x: 0,
          y: 1,
          r: 0,
          colorStops: [
            { offset: 0, color: "#7367f0" },
            { offset: 1, color: "#00cfe8" },
          ],
          global: false,
        },
      };
    },
  },
};
</script>

<style lang="css" scoped>
.chart {
  min-height: 300px;
  height: auto;
  width: 100%;
}
</style>
