<template>
  <v-text-field
    v-bind="$attrs"
    hide-details="auto"
    :outlined="outlined"
    dense
    :class="[tile ? 'tile' : '', `text-${textAlign}`]"
    color="primary"
    v-on="$listeners"
    @input="(v) => $emit('input', v)"
  >
    <template v-for="slot in parentSlots" #[slot]="props">
      <slot :name="slot" :props="props" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    tile: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    textAlign: { type: String, default: "left" },
  },
  setup(props, { slots }) {
    const parentSlots = Object.keys(slots);
    return { parentSlots };
  },
};
</script>

<style lang="scss">
.text-center {
  input {
    text-align: center;
  }
}
.text-left {
  input {
    text-align: left;
  }
}
.text-right {
  input {
    text-align: right;
  }
}
</style>
