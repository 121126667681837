import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './routers'
import store from './stores'
import VueMeta from 'vue-meta'

import '@/plugins/authentication';
import '@/plugins/event-bus';
import '@/plugins/requests';
import '@/plugins/components';
import '@/plugins/permissions';

import global_computed from './utils/global_computed'
import global_methods from '@/utils/global_methods'
import global_filters from '@/utils/global_filters'
import global_rules from '@/utils/global_rules'
import { environment } from './environment'

Vue.config.productionTip = false

Vue.mixin({
  data: () => ({ ...environment }),
  computed: {
    logoSquare: () => require('./assets/logo-square.png'),
    logoLongLight: () => require('./assets/logo-long-light.png'),
    logoLongDark: () => require('./assets/logo-long-dark.png'),
    logoLongDarkV2: () => require('./assets/logo-long-dark-v2.svg'),
    ...global_computed
  }, 
  filters: { ...global_filters },
  methods: { ...global_methods, ...global_rules }
})

Vue.use(VueMeta);

new Vue({
  vuetify: vuetify,
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount('#app')
