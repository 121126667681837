<template>
  <v-snackbar v-model="snackbar" dark multi-line :color="colorType">
    <template v-slot:default>
      <div
        class="xflex xfont-sans xfont-semibold xtext-left subtitle-2 body-1 xtext-white xflex-col xitems-center xjustify-start"
        v-html="message"
      ></div>
    </template>
    <template v-slot:action="{ attrs }">
      <v-btn icon class="mx-1" v-bind="attrs" @click="snackbar = false">
        <v-icon color="white">mdi-close-circle-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      message: "",
      type: "success",
    };
  },
  computed: {
    colorType() {
      if (this.type === "success") return "#32CD32";
      else if (this.type === "error") return "#FF0000";
      else if (this.type === "info") return "#87ceeb";
      else if (this.type === "warning") return "#FFAE42";
      return "#808080";
    },
  },
  mounted() {
    this.$event.$on("open_snackbar", (message, type) => {
      this.message = message || this.message;
      this.type = type || "success";
      this.snackbar = true;
    });
  },
};
</script>

<style lang="css" scoped></style>
