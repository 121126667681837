<template>
  <v-app-bar app flat dark color="#282828" :height="70">
    <div class="xflex xgap-2 xflex-row xw-full xjustify-between xitems-center">
      <div class="xflex xflex-row xjustify-start xgap-x-[1em] xitems-center">
        <v-icon v-if="tabletDown" color="white" @click="toggle_sidebar()">
          mdi-menu
        </v-icon>
        <v-avatar
          height="70"
          width="140"
          v-if="!sidebar"
          tile
          @click="$router.push({ name: 'admin-dashboard' }).catch(() => {})"
        >
          <v-img contain :src="logoLongLight"></v-img>
        </v-avatar>
      </div>
      <v-breadcrumbs v-if="laptopUp" :items="breadcrumbs" dark>
        <template v-slot:divider>
          <v-icon color="white">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            active-class="!xtext-[#fff]"
            class="!xtext-[#fff] xfont-bold"
            :to="item.route || null"
            :disabled="item.disabled"
          >
            {{ item.text | ucwords }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer v-if="laptopUp"></v-spacer>
      <div class="xflex xjustify-end xitems-center xgap-x-[1em]">
        <StatusBar v-if="!mobile"></StatusBar>
        <UserDropdown
          :lighten="false"
          v-if="$auth.isAuthenticated()"
          source="admin-dashboard"
        />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import StatusBar from "./StatusBar.vue";
import UserDropdown from "./UserDropdown.vue";
export default {
  components: { UserDropdown, StatusBar },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  watch: {
    "$route.meta": {
      handler: function (val) {
        this.breadcrumbs = val ? val.breads : [];
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$event.$on("breadcrumbs", (payload) => {
      this.breadcrumbs = payload;
    });
  },
  computed: {
    ...mapGetters("appconfigs", ["sidebar"]),
  },
  methods: {
    ...mapMutations("appconfigs", ["toggle_sidebar"]),
  },
};
</script>

<style scoped></style>
