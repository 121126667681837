<template>
  <v-tooltip
    color="primary"
    :top="isTop"
    :bottom="isBottom"
    :right="isRight"
    :left="isLeft"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="clickable"
        @click.stop="$emit('click')"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
      >
        {{ icon }}
      </v-icon>
      <v-icon v-else v-bind="{ ...$attrs, ...attrs }" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    <span v-html="tip"></span>
  </v-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    clickable: { type: Boolean, default: false },
    icon: { type: String, default: "mdi-plus" },
    tip: { type: String, default: "" }, 
    pos: { type: String, default: "top" },
  },
  computed: {
    isTop() {
      return this.pos.toLowerCase() === "top";
    },
    isBottom() {
      return this.pos.toLowerCase() === "bottom";
    },
    isLeft() {
      return this.pos.toLowerCase() === "left";
    },
    isRight() {
      return this.pos.toLowerCase() === "right";
    },
  },
};
</script>

<style lang="scss" scoped></style>
