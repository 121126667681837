<template>
  <div id="support" class="xbg-[#ffffff] xw-full xblock xh-full">
    <div
      :class="[smDown ? '!xbg-none' : '!xbg-[#ffffff]']"
      class="xflex xflex-col xjustify-center xitems-center xw-full xcontainer xmx-auto xmin-h-[70vh]"
      :style="{
        background: `url(${topLeft}) 100px top no-repeat, url(${rightBottom}) right bottom no-repeat, url(${leftBottom}) 120px bottom no-repeat`,
        backgroundColor: '#FFFFFF',
      }"
    >
      <div
        class="xflex xflex-col xw-full xpy-[2em] xpx-[2em] xjustify-center xitems-center"
      >
        <h2
          class="xtext-[#a240e3] xfont-[900] xleading-[160%] xtext-[13px] xtracking-[0.43em]"
        >
          CONTACT US
        </h2>
        <h1 class="xfont-[700] xtext-[42px] xleading-[51px]">Support</h1>
      </div>
      <v-form ref="form" class="px-6 xw-full">
        <div
          class="md:xmax-w-[780px] md:xmin-w-[800px] xmax-w-[95%] xmx-auto xflex xflex-col xw-full xgap-[1em]"
        >
          <v-text-field
            v-model="email"
            outlined
            clearable
            class="blackborder-fieldset"
            hide-details="auto"
            placeholder="Your email"
            :rules="[requiredRule(), emailRule()]"
          ></v-text-field>
          <v-text-field
            v-model="workspace"
            outlined
            clearable
            class="blackborder-fieldset"
            hide-details="auto"
            placeholder="Workspace Domain/Name e.g acme or N/A if none"
            :rules="[requiredRule()]"
          ></v-text-field>
          <v-select
            v-model="type"
            outlined
            class="blackborder-fieldset"
            hide-details="auto"
            :items="items"
            placeholder="Support type"
            :rules="[requiredRule()]"
          ></v-select>
          <div class="xflex xflex-col xjustify-start xitems-start xgap-[0.5em]">
            <div v-if="type === 'submit_bug'">
              Please provide us the following to replicate the bug:
              <ul list-type="dotted">
                <li>
                  1. Which page URL the bug occurs or which Slash command cause
                  the bug.
                </li>
                <li>
                  2. The input value which caused the bug and other details. A
                  video or image link is appreciated.
                </li>
              </ul>
            </div>
            <div v-else-if="type === 'feature_request'">
              Please provide us the details regarding your feature request. We
              will review your feature requests and will provide feedback if
              those can be implemented or not.
            </div>
            <div v-else-if="type === 'account_related'">
              This support type is for account-related issues like account
              complete deletion, account reactivated request and question.
            </div>
            <div v-else-if="type === 'subscription_related'">
              This support type is for subscription-related issues like
              subscription exemption, subscription payment issues, etc.
            </div>
          </div>
          <v-textarea
            v-model="message"
            outlined
            clearable
            class="blackborder-fieldset"
            hide-details="auto"
            placeholder="Details"
            :rules="[requiredRule(), minLengthRule(50)]"
          ></v-textarea>
          <v-btn
            color="primary"
            rounded
            :block="smDown"
            :max-width="500"
            :min-width="200"
            :disabled="!valid"
            large
            height="60"
            elevation="false"
            class="xmx-auto text-none xtracking-normal xpy-[22px] xpx-[24px] !xtext-[18px] xrounded-[8px] xfont-bold"
            @click="submit"
          >
            Submit
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      topLeft: require("@/assets/covers/feature-t.svg"),
      leftBottom: require("@/assets/covers/feature-lb.svg"),
      rightBottom: require("@/assets/covers/feature-rb.svg"),
      items: [
        { value: "submit_bug", text: "Submit a Bug" },
        { value: "feature_request", text: "Feature Request" },
        { value: "account_related", text: "Account Related" },
        { value: "subscription_related", text: "Subcription Related" },
        { value: "others", text: "Others" },
      ],
      workspace: null,
      email: null,
      type: null,
      message: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    payload() {
      return {
        workspace: this.workspace,
        email: this.email,
        type: this.type,
        message: this.message,
      };
    },
    valid() {
      return this.payload.workspace &&
        this.payload.email &&
        this.payload.type &&
        this.payload.message &&
        this.payload.message.length >= 50
        ? true
        : false;
    },
  },
  created() {
    if (this.user) {
      this.workspace = this.user.team.name;
      this.email = this.user.email;
    }
  },
  methods: {
    onResize() {
      this.windowH = window.innerHeight;
    },
    resetForm() {
      this.workspace = null;
      this.email = null;
      this.type = null;
      this.message = null;
      this.resetValidation();
    },
    submit() {
      this.appConfirmation(
        "Submit this form?",
        () => {
          this.$axios.post(`api/support`, this.payload).then(({ data }) => {
            this.appToast(data.message, "success");
            this.resetForm();
          });
        },
        null,
        "Yes! Submit"
      );
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style lang="css">
.v-application--is-ltr .v-text-field--outlined.blackborder-fieldset fieldset {
  border: 1px solid #828282 !important;
  background: #ffffff;
}
</style>
