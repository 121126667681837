<template>
  <v-menu offset-y left min-width="200">
    <template v-slot:activator="{ on, attrs }">
      <div
        :class="laptopUp ? `xgap-x-[1em]` : `xgap-x-[0.2em]`"
        class="xflex xh-[70px] xfont-bold hover:secondary--text xflex-row xjustify-end xitems-center"
        v-bind="attrs"
        v-on="on"
      >
        <div class="xavatar xonline">
          <div class="xw-[40px] xmask xmask-circle">
            <v-img contain :src="$auth.user().image_url" />
          </div>
        </div>
        <div
          v-if="hasName"
          class="xtext-[#000] xfont-[700] xtext-[18px] xleading-[22px]"
        >
          {{ $auth.user().real_name }}
        </div>
        <v-icon :color="lighten ? '#A8A8A8' : 'primary'">
          mdi-chevron-down
        </v-icon>
      </div>
    </template>
    <v-list dense>
      <v-list-item
        class="text-left"
        v-for="(item, index) in items"
        @click="handleClick(item)"
        :key="index"
      >
        <v-list-item-title class="font-weight-bold">
          {{ item.title }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon> {{ item.icon }} </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    source: { type: String, default: "home" },
    lighten: { type: Boolean, default: true },
    hasName: { type: Boolean, default: false },
  },
  data() {
    return {
      setting: {
        title: "My Settings",
        icon: "mdi-cog",
        route: "app-my-settings",
      },
      home: { title: "Home", icon: "mdi-home", route: "home" },
      adminDashboard: {
        title: "Admin Area",
        icon: "mdi-account-tie-hat-outline",
        route: "admin-dashboard",
      },
      appDashboard: {
        title: "Dashboard",
        icon: "mdi-account-tie-outline",
        route: "app-dashboard",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    isAdminPage() {
      return this.$route.meta.hasOwnProperty("super_admin") &&
        this.$route.meta.super_admin
        ? true
        : false;
    },
    isHomePage() {
      return this.$route.meta.hasOwnProperty("homes") && this.$route.meta.homes
        ? true
        : false;
    },
    items() {
      let list = [];
      if (!this.isHomePage) list.push(this.home);
      if (this.isAdminPage || this.isHomePage) list.push(this.appDashboard);
      if (this.user.is_super_admin && !this.isAdminPage)
        list.push(this.adminDashboard);
      list.push(this.setting);
      list.push({ title: "Logout", icon: "mdi-logout" });
      return list;
    },
  },
  methods: {
    handleClick(item) {
      if (item.title === "Logout") {
        this.appConfirmation(`Log out from ${this.appName}?`, () => {
          this.$auth.logout(() => {
            window.location = "/";
          });
        });
      } else {
        this.$router.push({ name: item.route }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
