<template>
  <v-card flat min-height="100vh" class="xp-[1em]" color="transparent">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Support</h1>
    </v-card-title>
    <v-card flat class="xpy-[1em] xmin-h-[90vh]">
      <v-card-text>
        <app-table :loading="loading" :headings="headers" :items="items">
          <template v-slot:user="{ item }">
            <avatar
              size="35"
              v-if="item.user"
              :user="item.user"
              with-name
            ></avatar>
            <span v-else>{{ item.user_id || "-" }}</span>
          </template>
          <template v-slot:user_id="{ item }">
            <span v-if="item.user">{{ item.user.real_name }}</span>
            <clipboard v-if="item.user_id" :text="item.user_id"> </clipboard>
            <span v-else>Not on database</span>
          </template>
          <template v-slot:email="{ item }">
            <clipboard :text="item.email" v-if="item.email"> </clipboard>
            <span v-else>-</span>
          </template>
          <template v-slot:team_id="{ item }">
            <span v-if="item.team">
              {{ item.team.name }} ({{ item.team.id }})
            </span>
            <span v-else>{{ item.team_id || item.workspace || "-" }}</span>
          </template>
          <template v-slot:created_at="{ item }">
            <span>
              {{ item.created_at | format("lll") }}
            </span>
          </template>
          <template v-slot:type="{ item }">
            <span>{{ item.type | ucwords }}</span>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      counts: 0,
      loading: false,
      loading_more: false,
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "From", value: "user_id", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Team", value: "team_id", sortable: true },
        { text: "Type", value: "type", sortable: true },
        { text: "Message", value: "details", sortable: false },
        { text: "Date & Time", value: "created_at", sortable: true },
      ],
      items: [],
    };
  },
  created() {
    this.fetchStats();
    this.fetch();
  },
  computed: {
    tabs() {
      return [
        {
          name: "Supports Ticket",
          to: { name: "admin-supports" },
          counts: this.counts,
        },
      ];
    },
  },
  methods: {
    fetchStats() {
      this.$axios.get(`admin/support/statistics`).then(({ data }) => {
        this.counts = data.supports_count;
      });
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get(`admin/support`)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
