<template>
  <v-card flat color="transparent" class="xp-[1em]">
    <v-card flat v-if="workspace">
      <div
        class="xh-[150px] xmb-[1em] xw-full xoverflow-hidden xflex-row xbg-cover xbg-top xjustify-between xitems-center xpx-2 xflex"
        :style="{
          backgroundImage: `url(${bgWave})`,
        }"
      >
        <div class="xflex xflex-row xjustify-center xgap-x-[1em] xitems-center">
          <v-avatar :size="100" class="xrounded-md ma-2">
            <v-img contain :src="workspace.image_url" />
          </v-avatar>
          <div
            class="xflex xflex-col xjustify-center xitems-start xgap-y-[0.1em]"
          >
            <h1 class="xtext-[#2e2e2e] xtext-[25px] xfont-extrabold xmy-1">
              {{ workspace.name }}
            </h1>
            <a :href="`${workspace.domain}.slack.com`" target="_blank" class="hover:xunderline">
              <h1 class="xtext-[#2e2e2e] xtext-[15px] xfont-[500] xmy-1">
                {{ workspace.domain }}.slack.com
              </h1>
            </a>
          </div>
        </div>
      </div>
      <app-tabs optional :tabs="tabs">
        <v-card-text>
          <router-view :key="$route.fullPath"></router-view>
        </v-card-text>
      </app-tabs>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      bgWave: require("@/assets/covers/waves-1.svg"),
    };
  },
  computed: {
    ...mapGetters("workspace", ["workspace"]),
    tabs() {
      return [
        {
          name: "Info",
          to: {
            name: "admin-workspace-info",
            params: { id: this.$route.params.id },
          },
        },
        {
          name: "Settings",
          to: {
            name: "admin-workspace-configs",
            params: { id: this.$route.params.id },
          },
        },
        {
          name: "Members",
          to: {
            name: "admin-workspace-members",
            params: { id: this.$route.params.id },
          },
        },
        {
          name: "Bot Responses",
          to: {
            name: "admin-workspace-bot-responses",
            params: { id: this.$route.params.id },
          },
        },
      ];
    },
  },
  watch: {
    "$route.params.id": {
      handler: function (val) {
        if (
          (val && this.workspace && this.workspace.id != val) ||
          (val && !this.workspace)
        )
          this.fetchWorkspaceInfo(val);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceInfo"]),
  },
};
</script>

<style lang="scss" scoped></style>
