<template>
  <v-menu bottom left offset-y content-class="arrow-menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-none !xpx-[20px]"
        depressed
        :color="btnColor"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      >
        <slot name="label">
          <span :style="`color:${textColor};`">{{ label }}</span>
        </slot>
        <v-spacer />
        <v-icon right :color="textColor">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <slot name="default"></slot>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: true,
  props: {
    icon: { type: String, default: "mdi-dots-vertical" },
    label: { type: String, default: "List" },
    textColor: { type: String, default: "light" },
    btnColor: { type: String, default: "light" },
  },
};
</script>

<style lang="scss" scoped></style>
