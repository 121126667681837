<template>
  <div v-bind="divAttrs">
    <v-autocomplete
      dense
      v-bind="$attrs"
      v-model="selected"
      outlined
      hide-details="auto"
      item-text="name"
      :item-value="!returnName ? `id` : `name`"
      :items="projects"
      append-icon="mdi-chevron-down"
      :prepend-inner-icon="hasCreate ? `mdi-plus-circle-outline` : null"
      @click:prepend-inner="openModal = true"
    >
      <template v-slot:no-data>
        <v-list-item v-if="hasCreate">
          <span class="subheading">
            Click <v-icon small>mdi-plus-circle-outline</v-icon> to create new
            project/task
          </span>
        </v-list-item>
        <v-list-item v-else>
          <span class="subheading">
            No project/task available. Unable to create new project/task.
          </span>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <span class="xmax-w-[300px]">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subheading">
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.short_code }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
      </template>
    </v-autocomplete>

    <ProjectModal
      v-model="openModal"
      @new-item="newProjectAdded"
    ></ProjectModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ProjectModal from "../Modals/ProjectModal.vue";
export default {
  inheritAttrs: false,
  components: { ProjectModal },
  props: {
    divAttrs: { type: Object, default: undefined },
    value: { type: [Number, String], default: null },
    hasCreate: { type: Boolean, default: false },
    returnName: { type: Boolean, default: false },
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val;
      },
      immediate: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      openModal: false,
    };
  },
  computed: {
    ...mapGetters("workspace", ["projects", "workspace"]),
  },
  mounted() {
    if (!this.projects.length) {
      this.fetchWorkspaceProjects(this.workspace.id);
    }
  },
  methods: {
    ...mapMutations("workspace", ["add_project"]),
    ...mapActions("workspace", ["fetchWorkspaceProjects"]),
    newProjectAdded(project) {
      if (project) {
        this.add_project(project);
      }
      this.selected = project ? project.id : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
