<template>
  <v-app>
    <AdminSidebar
      v-if="$auth.isAuthenticated() && $route.path.includes('/admin-dashboard')"
    ></AdminSidebar>
    <AppSidebar
      v-else-if="$auth.isAuthenticated() && $route.path.includes('/dashboard')"
    ></AppSidebar>

    <AdminHeader
      v-if="$auth.isAuthenticated() && $route.path.includes('/admin-dashboard')"
    ></AdminHeader>
    <AppHeader
      v-else-if="$auth.isAuthenticated() && $route.path.includes('/dashboard')"
    ></AppHeader>
    <HomeHeader v-else />

    <v-main>
      <!-- {{ tablet }} -->
      <OverlayLoader />
      <router-view :key="$route.fullPath" />
      <ConfirmationModal
        :key="`${$auth.isAuthenticated() ? $auth.user().id : 0}-confirmation`"
      ></ConfirmationModal>
      <NotificationModal
        :key="`${$auth.isAuthenticated() ? $auth.user().id : 0}-notification`"
      ></NotificationModal>
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import ConfirmationModal from "@/views/GlobalComponents/Modals/ConfirmationModal.vue";
import Snackbar from "@/views/GlobalComponents/Snackbar.vue";
import OverlayLoader from "@/views/GlobalComponents/Loaders/OverlayLoader.vue";
import AdminHeader from "@/views/GlobalComponents/Headers/AdminHeader.vue";
import AppHeader from "@/views/GlobalComponents/Headers/AppHeader.vue";
import AdminSidebar from "@/views/GlobalComponents/Sidebars/AdminSidebar.vue";
import AppSidebar from "@/views/GlobalComponents/Sidebars/AppSidebar.vue";
import HomeHeader from "@/views/GlobalComponents/Headers/HomeHeader.vue";
import NotificationModal from "@/views/GlobalComponents/Modals/NotificationModal.vue";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    ConfirmationModal,
    Snackbar,
    OverlayLoader,
    AppHeader,
    AdminHeader,
    AdminSidebar,
    AppSidebar,
    HomeHeader,
    NotificationModal,
  },
  metaInfo() {
    return {
      title: this.appName,
      meta: [
        {
          name: "slack-app-id",
          content: this.slackApp.app_id,
        },
      ],
    };
  },
  created() {
    this.fetchConfigs();
    if (this.$auth.isAuthenticated()) {
      this.$auth.fetchUser();
      this.$can.fetchPermissions();
    }
  },
  methods: {
    ...mapActions("appconfigs", ["fetchConfigs"]),
  },
};
</script>
