<template>
  <v-menu bottom left offset-y content-class="arrow-menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="{ ...attrs, ...btnAttrs }" v-on="on">
        <v-icon color="primary" v-bind="$attrs">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <slot name="default"></slot>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    icon: { type: String, default: "mdi-dots-vertical" },
    btnAttrs: { type: Object },
  },
};
</script>

<style lang="css">

</style>
