import Index from '@/views/TestArea/Index.vue';
import SlashCommand from '@/views/TestArea/SlashCommand.vue';
import InteractiveHook from '@/views/TestArea/InteractiveHook.vue';
import ComponentTest from '@/views/TestArea/ComponentTest.vue'


export const testRoutes = {
    path: '/test',
    component: Index,
    children: [
        {
            path: '/',
            name: 'test',
            component: ComponentTest
        },
        {
            path: 'slash',
            name: 'test-slash',
            component: SlashCommand
        },
        {
            path: 'hook',
            name: 'test-hook',
            component: InteractiveHook
        },
    ],
    meta: {
        auth: false
    }
} 