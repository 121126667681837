<template>
  <v-card flat outlined v-bind="$attrs">
    <v-card-text class="xp-[0.5em]">
      <div class="xflex xflex-row xw-full xjustify-between xitems-center">
        <div class="xflex xflex-1 xflex-col xjustify-start xitems-start">
          <div
            class="xw-full xfont-[600] xcursor-pointer xflex xp-[1em] xflex-col"
            @click="toggle"
          >
            <slot name="title">
              <div
                class="xfont-[600] md:xtext-[20px] xtext-[17px] xleading-[32px] xtext-[#000]"
                v-html="title"
              ></div>
            </slot>
          </div>
          <v-expand-transition>
            <v-card
              flat
              v-show="expand"
              :style="`max-height: ${maxHeight}`"
              class="xflex xw-full xoverflow-x-hidden xpy-[0.5em] !xoverflow-y-auto xpx-[1em] xleading-[29px] xflex-col xtext-[#000000] xfont-[400] xtext-[18px]"
            >
              <slot name="default">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Reiciendis iusto cum itaque doloribus. Dolores officiis at
                numquam fugiat, libero omnis aliquam itaque accusamus expedita
                odit similique nostrum ab iste voluptatibus.
              </slot>
            </v-card>
          </v-expand-transition>
        </div>
        <div class="xflex xflex-col xjustify-center xp-[1em] xitems-center">
          <v-icon color="#000" size="29" @click="toggle" v-if="!expand">
            mdi-plus
          </v-icon>
          <v-icon color="#000" size="29" @click="toggle" v-else>
            mdi-minus
          </v-icon>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: { type: String, default: "Title" },
    expanded: { type: Boolean, default: false },
    maxHeight: { type: String, default: "auto" },
  },
  data() {
    return {
      expand: false,
    };
  },
  watch: {
    expanded: {
      handler: function (val) {
        this.expand = val;
      },
      immediate: true,
    },
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    },
  },
};
</script>

<style lang="scss" scoped></style>
