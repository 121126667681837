import Vue from 'vue'
import Vuetify from 'vuetify'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import '@/scss/variables.scss'
import '@/styles/tailwind-compiled.css';
import 'srcdoc-polyfill/srcdoc-polyfill.min.js'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        current: 'en'
    },
    breakpoint: {
        mobileBreakpoint: 'sm',
        scrollBarWidth: 12,
        thresholds: {
            xs: 640,
            sm: 768,
            md: 1024,
            lg: 1280,
            xl: 1536
        }
    },
    theme: {
        dark: false,
        default: 'light',
        themes: {
            light: {
                primary: "#6a2c91",
                secondary: "#4da2c5",
                accent: "#6ee7b7",
                neutral: "#9ca3af",
                "base-100": "#e5e7eb",
                info: "#67e8f9",
                success: "#22c55e",
                warning: "#fb923c",
                error: "#ef4444",
                urgent: '#d80000',
                pending: '#cbcb00',
                behind: '#ff7043',
                open: '#674646',
                completed: '#35b400',
                dark: '#282828',
                light: '#eeeeee',
                works: '#44D941',
                breaks: '#FCAA3F'
            }
        },
        options: {
            customProperties: true,
            cspNonce: 'dQw4wdsadsa9WgXc2'
        }
    },
    icons: {
        iconfont: 'mdi',
        values: {
            notification: 'notification_important',
            success: 'check',
            error: 'error'
        }
    }
})