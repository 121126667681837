import { environment } from "../environment"
/**
 * xsOnly: boolean
  smOnly: boolean
  smAndDown: boolean
  smAndUp: boolean
  mdOnly: boolean
  mdAndDown: boolean
  mdAndUp: boolean
  lgOnly: boolean
  lgAndDown: boolean
  lgAndUp: boolean
  xlOnly: boolean
 */
export default {
    bpWidth() {
        return this.$vuetify.breakpoint.width
    },
    smDown() {
        return this.$vuetify.breakpoint.smAndDown
    },
    mdDown() {
        return this.$vuetify.breakpoint.mdAndDown
    },
    mdUp() {
        return this.$vuetify.breakpoint.mdAndUp
    },
    lgUp() {
        return this.$vuetify.breakpoint.lgAndUp
    },
    lgDown() {
        return this.$vuetify.breakpoint.lgAndDown
    },
    mobile() {
        return this.bpWidth <= 768;
    },
    mobileUp() {
        return this.bpWidth > 768;
    },
    tablet() {
        return this.bpWidth >= 768 && this.bpWidth <= 1080;
    },
    tabletUp() {
        return this.bpWidth >= 768;
    },
    tabletDown() {
        return this.bpWidth <= 1080;
    },
    laptopUp() {
        return this.bpWidth > 1080;
    },
    laptopDown() {
        return this.bpWidth < 1080;
    }
}
