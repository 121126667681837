import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'
import { environment } from '../environment'

export default {
    appConfirmation: (message, yescb, nocb, confirmLabel) => {
        Vue.prototype.$event.$emit(
            'open_confirmation_modal',
            message,
            typeof yescb !== 'function' || !yescb ? undefined : yescb,
            typeof nocb !== 'function' || !nocb ? undefined : nocb,
            typeof confirmLabel == 'undefined' || !confirmLabel ? null : confirmLabel
        )
    },
    appNotification: (message, type, title) => {
        Vue.prototype.$event.$emit(
            'open_notification_modal',
            message,
            typeof type == 'undefined' || !type ? 'primary' : type,
            typeof title == 'undefined' || !type ? null : title,
        )
    },
    appToast: (message, type) => {
        Vue.prototype.$event.$emit(
            'open_snackbar',
            message,
            type
        )
    },
    appLoader: (open, message, absolute) => {
        Vue.prototype.$event.$emit(
            'global_loader',
            open,
            message,
            typeof absolute == 'undefined' ? false : absolute
        )
    },
    addHashToLocation(params) {
        history.pushState(
            {},
            null,
            this.$route.path + '#' + encodeURIComponent(params)
        )
    },
    isModuleEnabled(module) {
        return module ? environment.modules.includes(module.toLowerCase()) : false
    },
    checkNestedHas: (obj, level, ...rest) => {
        if (obj === undefined) return false
        if (rest.length == 0 && obj.hasOwnProperty(level)) return true
        return checkNested(obj[level], ...rest)
    },
    getNestedValue: (obj, ...args) => {
        return args.reduce((obj, level) => obj && obj[level], obj)
    },
    percentOf(value, total = 86400) {
        return (value * 100) / total;
    },
    getTimeHeader(start = '2AM', dense = false) {
        let labels = ['2am', '4am', '6am', '8am', '10am', '12pm', '2pm', '4pm', '6pm', '8pm', '10pm', '12am'];
        let foundIndex = labels.findIndex(i => i.toLowerCase() === start.toLowerCase())
        const first = _.cloneDeep(labels).filter((i, index) => index >= foundIndex)
        const second = _.cloneDeep(labels).filter((i, index) => index < foundIndex)

        let revolved = first.concat(second);
        if (dense) {
            return _.map(revolved, (value, index) => {
                return index % 2 !== 0 ? "" : value;
            });
        } else return revolved
    },
    getMinMax(old_date, new_date, type) {
        if (new_date !== null && !isNaN(new_date)) {
            return this.compareDates(old_date, new_date, type);
        } else {
            return old_date;
        }
    },
    compareDates(old_date, new_date, type) {
        switch (type) {
            case 'min':
                return this.getHigher(old_date, new_date);
                break;

            case 'max':
                return this.getLower(old_date, new_date);
                break;
        }
        return;
    },
    getHigher(old_date, new_date) {
        if (old_date > new_date) {
            return old_date;
        }
        return new_date;
    },
    getLower(old_date, new_date) {
        if (old_date > new_date) {
            return new_date;
        }
        return old_date;
    },
    durationFormat(val1, val2, format) {
        if (!val1 || !val2) return 'Unknown';
        let formatting = format || 'H [hours] m [minutes]'
        let diff = moment(val2, 'YYYY-MM-DD HH:mm:ss').diff(moment(val1, 'YYYY-MM-DD HH:mm:ss'));
        let duration = moment.duration(diff);
        return duration.format(formatting).replace('-', '')
    }

}