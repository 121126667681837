import request from '@/plugins/requests'
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        fetching: false,
        hasFetch: false,
        team_statistics: [],
        user_statistics: [], 
    },
    getters: {
        fetching: state => state.fetching,
        hasFetch: state => state.hasFetch,
        team_statistics: state => state.team_statistics,
        user_statistics: state => state.user_statistics,
    },
    mutations: {
        set_fetching: (state, payload) => (state.fetching = payload),
        set_hasFetch: (state, payload) => (state.hasFetch = payload),
        set_team_statistics: (state, payload) => (state.team_statistics = payload),
        set_user_statistics: (state, payload) => (state.user_statistics = payload),
    },
    actions: {
        fetchStatistics: ({ commit }) => {
            commit('set_fetching', true)
            request.get(`admin/dashboard/statistics`)
                .then(({ data }) => {
                    commit('set_team_statistics', data.team);
                    commit('set_user_statistics', data.user);
                    commit('set_hasFetch', true)
                })
                .finally(() => commit('set_fetching', false));
        },
    }
}