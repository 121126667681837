import request from '@/plugins/requests'
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        fetching: false,
        in_bots: [],
        brb_bots: [],
        back_bots: [],
        out_bots: [],
    },
    getters: {
        fetching: state => state.fetching,
        in_bots: (state) => state.in_bots,
        brb_bots: (state) => state.brb_bots,
        back_bots: (state) => state.back_bots,
        out_bots: (state) => state.out_bots,
    },
    mutations: {
        set_fetching: (state, payload) => (state.fetching = payload),
        set_in_bots: (state, payload) => (state.in_bots = payload),
        set_brb_bots: (state, payload) => (state.brb_bots = payload),
        set_back_bots: (state, payload) => (state.back_bots = payload),
        set_out_bots: (state, payload) => (state.out_bots = payload),
        set_responses: (state, payload) => {
            state.in_bots = _.cloneDeep(payload).filter(i => i.type === 'in')
            state.brb_bots = _.cloneDeep(payload).filter(i => i.type === 'brb')
            state.back_bots = _.cloneDeep(payload).filter(i => i.type === 'back')
            state.out_bots = _.cloneDeep(payload).filter(i => i.type === 'out')
        },
        add_bot: (state, payload) => {
            if (payload.type) {
                state[`${payload.type}_bots`].push(payload);
            }
        },
        update_bot: (state, payload) => {
            if (payload.type) {
                let index = state[`${payload.type}_bots`].findIndex(i => i.id === payload.id);
                if (~index) {
                    state[`${payload.type}_bots`].splice(index, 1, payload)
                }
            }
        },
        delete_bot: (state, payload) => {
            if (payload.type) {
                let index = state[`${payload.type}_bots`].findIndex(i => i.id === payload.id);
                if (~index) {
                    state[`${payload.type}_bots`].splice(index, 1)
                }
            }
        },
    },
    actions: {
        fetchBotResponses: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/bot-responses/${id}`)
                .then(({ data }) => {
                    commit('set_responses', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchBotResponsesByType: ({ commit }, { id, type }) => {
            request.get(`api/bot-responses/${id}?type=${type}`)
                .then(({ data }) => {
                    commit(`set_${type}_bots`, data)
                })
        },
        saveNewBot: ({ commit }, { id, payload, cb }) => {
            request.post(`api/bot-responses/${id}`, payload)
                .then(({ data }) => {
                    commit(`add_bot`, data)
                    if (typeof cb === 'function') cb(data)
                })
        },
        updateBot: ({ commit }, { id, payload, cb }) => {
            request.put(`api/bot-responses/${id}/${payload.id}`, payload)
                .then(({ data }) => {
                    commit('update_bot', data)
                    if (typeof cb === 'function') cb(data)
                })
        },
        deleteBot: ({ commit }, { id, payload, cb }) => {
            request.delete(`api/bot-responses/${id}/${payload.id}`)
                .then(({ data }) => {
                    commit('delete_bot', payload)
                    if (typeof cb === 'function') cb(data)
                })
        }
    }
}