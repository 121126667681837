var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","color":"transparent","min-height":"100vh"}},[_c('v-card-title',[_c('h1',{staticClass:"xfont-[600] xtext-[24px] xleading-[29px]"},[_vm._v("Admin Dashboard")])]),_c('v-card-text',{staticClass:"xgrid md:xgrid-cols-2 xgrid-cols-1 xgap-[2em] xjustify-start xitems-stretch"},[_c('v-card',{staticClass:"!xrounded-lg xp-2 xw-full xh-full",attrs:{"height":"400"}},[_c('v-card-title',[_vm._v("Team Statistics")]),_c('v-card-text',[_c('BarChart',{attrs:{"items":_vm.team_statistics,"title":"Workspace Statistics"}})],1)],1),_c('v-card',{staticClass:"!xrounded-lg xp-2 xw-full xh-full",attrs:{"height":"400"}},[_c('v-card-title',{staticClass:"xflex xflex-row xjustify-between xitems-center"},[_c('span',[_vm._v("New Registered Team")]),_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'admin-subscribers-subscribed' })}}},[_vm._v(" View All ")])],1),_c('v-card-text',[_c('app-table',{attrs:{"headings":_vm.team_headers,"items":_vm.latestTeam,"loading":_vm.fetching},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.created_at,"lll")))])]}}])})],1)],1),_c('v-card',{staticClass:"!xrounded-lg xp-2 xw-full xh-full",attrs:{"height":"400"}},[_c('v-card-title',[_vm._v("User Statistics")]),_c('v-card-text',[_c('BarChart',{attrs:{"items":_vm.user_statistics,"title":"Users Statistics"}})],1)],1),_c('v-card',{staticClass:"!xrounded-lg xp-2 xw-full xh-full xoverflow-hidden",attrs:{"height":"400"}},[_c('v-card-title',{staticClass:"xflex xflex-row xjustify-between xitems-center"},[_c('span',[_vm._v("New Feedbacks")]),_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'admin-feedbacks' })}}},[_vm._v(" View All ")])],1),_c('v-card-text',[_c('app-table',{attrs:{"empty-text":"No new feedbacks","headings":_vm.feedback_headers,"items":_vm.latestFeedback,"loading":_vm.fetching},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('avatar',{attrs:{"user":item.user,"with-name":""}}):_vm._e()]}},{key:"team",fn:function(ref){
var item = ref.item;
return [(item.team)?_c('span',[_vm._v(_vm._s(item.team.name))]):_c('span',[_vm._v(" - ")])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.created_at,"lll")))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }