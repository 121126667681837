import Vue from 'vue'
import DatetimePicker from 'vuetify-datetime-picker'
import PortalVue from 'portal-vue'
import VueClipboard from 'vue-clipboard2'
import Zendesk from '@dansmaculotte/vue-zendesk'
 
Vue.use(VueClipboard)
Vue.use(PortalVue, {
  portalName: 'portal',
  portalTargetName: 'portal-target',
})
Vue.use(DatetimePicker)

import AppTable from '@/views/GlobalComponents/Tables/AppTable.vue';
import AppModal from '@/views/GlobalComponents/Modals/Modal.vue';
import AppInputGroup from '@/views/GlobalComponents/Wrappers/InputGroup.vue';
import AppDateTimePicker from '@/views/GlobalComponents/Forms/DateTimePicker.vue';
import KirbyDateTimePicker from '@/views/GlobalComponents/Forms/KirbyDateTimePicker.vue';
import AppTab from '@/views/GlobalComponents/Wrappers/Tabs.vue';
import AppTextField from '@/views/GlobalComponents/Forms/Input.vue';
import AppTextArea from '@/views/GlobalComponents/Forms/TextArea.vue';
import SearchBox from '@/views/GlobalComponents/Forms/SearchBox.vue';
import Avatars from '@/views/GlobalComponents/Avatars.vue'
import Avatar from '@/views/GlobalComponents/Avatar.vue'
import Empty from '@/views/GlobalComponents/Loaders/Empty.vue'
import SyncToSlack from '@/views/GlobalComponents/Buttons/SyncToSlack.vue'
import AreaLoader from '@/views/GlobalComponents/Loaders/AreaLoader.vue'

import ButtonTooltip from '@/views/GlobalComponents/Buttons/ButtonTooltip.vue';
import IconTooltip from '@/views/GlobalComponents/Buttons/IconTooltip.vue';
import ButtonList from '@/views/GlobalComponents/Buttons/ButtonList.vue';
import ButtonRangeFilter from '@/views/GlobalComponents/Buttons/ButtonRangeFilter.vue';
import IconList from '@/views/GlobalComponents/Buttons/IconList.vue';
import ExportBtn from '@/views/GlobalComponents/Buttons/ExportBtn.vue';
import Clipboard from '@/views/GlobalComponents/Wrappers/Clipboard.vue'
import ChipIn from '@/views/GlobalComponents/Chips/ChipIn.vue';
import ChipBrb from '@/views/GlobalComponents/Chips/ChipBrb.vue';
import ChipOut from '@/views/GlobalComponents/Chips/ChipOut.vue';
import ChipAT from '@/views/GlobalComponents/Chips/ChipAT.vue';
import ChipPTO from '@/views/GlobalComponents/Chips/ChipPTO.vue';

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, PieChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components'

use([CanvasRenderer, PieChart, BarChart, GridComponent, TitleComponent, LegendComponent, TooltipComponent]);

Vue.component('v-chart', ECharts)

Vue.component('chip-in', ChipIn);
Vue.component('chip-brb', ChipBrb);
Vue.component('chip-out', ChipOut);
Vue.component('chip-at', ChipAT);
Vue.component('chip-pto', ChipPTO);
Vue.component('clipboard', Clipboard);
Vue.component('btn-tooltip', ButtonTooltip);
Vue.component('icon-tooltip', IconTooltip);
Vue.component('icon-list', IconList);
Vue.component('btn-list', ButtonList);
Vue.component('btn-range-filter', ButtonRangeFilter);
Vue.component('btn-export', ExportBtn);
Vue.component('avatars', Avatars);
Vue.component('avatar', Avatar);
Vue.component('SyncToSlack', SyncToSlack);

Vue.component('app-search', SearchBox);
Vue.component('app-modal', AppModal);
Vue.component('app-table', AppTable);
Vue.component('app-input-group', AppInputGroup);
Vue.component('app-input', AppTextField);
Vue.component('app-textarea', AppTextArea);
Vue.component('app-empty', Empty);
Vue.component('app-area-loader', AreaLoader);
Vue.component('app-datetime-picker', AppDateTimePicker);
Vue.component('app-tabs', AppTab);
Vue.component('kirby-datetime-picker', KirbyDateTimePicker);


Vue.use(Zendesk, {
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#78a300'
      }
    }
  }
})