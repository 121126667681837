<template>
  <v-card min-height="100vh" width="100%" flat>
    <v-card-text
      class="xw-full xmin-h-[90vh] xflex xflex-col xjustify-center xitems-center"
    >
      <div class="xw-6/12 xmx-auto xmin-h-500">
        <h1 class="xpy-2 xfont-bold xtext-[20px]">Set Password.</h1>
        <p class="xpy-2">Set your new password.</p>
        <v-form ref="form" v-model="valid">
          <label class="xfont-bold">Email Address:</label>
          <app-input
            :rules="[requiredRule(), emailRule()]"
            :value="email"
            disabled
          ></app-input>
          <label class="xfont-bold">Enter new password:</label>
          <app-input
            :rules="[requiredRule(), minLengthRule(8)]"
            v-model="new_password"
          ></app-input>
          <label class="xfont-bold">Confirm new password:</label>
          <app-input
            :rules="[requiredRule(), passwordConfirmationRule(new_password)]"
            v-model="confirm_password"
          ></app-input>
        </v-form>
        <v-alert
          v-if="!token"
          type="error"
          icon="mdi-alert"
          outlined
          class="my-2"
        >
          No password reset token found!
        </v-alert>
        <v-btn
          color="primary"
          :loading="submitting"
          :disabled="!email || submitting || !valid || !token"
          class="text-none xmy-2"
          @click="submit"
        >
          Submit
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      token: null,
      email: null,
      new_password: null,
      confirm_password: null,
      submitting: false,
    };
  },
  watch: {
    "$route.params.token": {
      handler: function (val) {
        this.token = val;
      },
      immediate: true,
    },
    "$route.params.email": {
      handler: function (val) {
        this.email = val;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$auth.isAuthenticated())
      this.$router.push({ name: "home" }).catch(() => {});
  },
  methods: {
    submit() {
      this.submitting = true;
      this.$axios
        .post(`api/auth/set-password`, {
          email: this.email,
          token: this.token,
          password: this.new_password,
        })
        .then(({ data }) => {
          this.appToast(data.message, "success");
          this.$router.push({ name: "login" });
        })
        .finally(() => (this.submitting = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
