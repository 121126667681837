var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"xmt-4",attrs:{"flat":"","min-height":"80vh","color":"transparent"}},[(_vm.pendingEdits.length)?_c('app-table',{attrs:{"loading":_vm.fetchingPending,"headings":_vm.header,"items":_vm.pendingEdits},scopedSlots:_vm._u([{key:"command",fn:function(ref){
var item = ref.item;
return [(['in', 'switch', 'back'].includes(item.command))?_c('chip-in',{attrs:{"text":item.command}}):(item.command === 'brb')?_c('chip-brb',{attrs:{"text":item.command}}):_c('chip-out',{attrs:{"text":item.command}})]}},{key:"old",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("format")(item.old_created_at,"LLL"))+" ")])]}},{key:"new",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("format")(item.new_created_at,"LLL"))+" ")])]}},{key:"diff",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDifference(item))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("ucwords")(item.status ? item.status : "pending"))+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.cancelEdit(item, index)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Cancel Edit ")],1)],1),(item.status != 'denied')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.sendEdit(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-file-move-outline")]),_vm._v(" Send Time Edit for Approval ")],1)],1):_vm._e(),(_vm.$can.and('manage_user_time'))?_c('v-divider'):_vm._e(),(_vm.$can.and('manage_user_time'))?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.approvedEdit(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-file-check-outline")]),_vm._v(" Self Approved ")],1)],1),_c('v-list-item-action',[_c('v-list-item-action-text',{staticClass:"xtext-[#2E2E2E] xtext-right xtext-[9px]"},[_vm._v(" Admin/Manager Permission ")])],1)],1):_vm._e()],1)],1)]}}],null,false,2004889647)}):(_vm.fetchingPending)?_c('app-empty',{staticClass:"xmin-h-[300px] xh-full",attrs:{"text":"Loading Pending Edits...","color":"transparent"}}):_c('app-empty',{staticClass:"xmin-h-[300px] xh-full",attrs:{"text":"No Pending Edits","color":"transparent"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }