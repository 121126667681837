<template>
  <v-select
    dense
    outlined
    hide-details="auto"
    v-model="model"
    :items="items"
    append-icon="mdi-sort-clock-ascending"
    item-text="text"
    item-value="value"
    v-bind="$attrs"
  >
  </v-select>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: String, default: "01:00" },
  },
  data() {
    return {
      model: "01:00:00",
      items: [
        { text: "1 hour", value: "01:00:00" },
        { text: "1 hour and 30 minutes", value: "01:30:00" },
        { text: "2 hours", value: "02:00:00" },
        { text: "2 hours and 30 minutes", value: "02:30:00" },
        { text: "3 hours", value: "03:00:00" },
        { text: "3 hours and 30 minutes", value: "03:30:00" },
        { text: "4 hours", value: "04:00:00" },
        { text: "4 hours and 30 minutes", value: "04:30:00" },
        { text: "5 hours", value: "05:00:00" },
        { text: "5 hours and 30 minutes", value: "05:30:00" },
        { text: "6 hours", value: "06:00:00" },
        { text: "6 hours and 30 minutes", value: "06:30:00" },
        { text: "7 hours", value: "07:00:00" },
        { text: "7 hours and 30 minutes", value: "07:30:00" },
        { text: "8 hours", value: "08:00:00" },
        { text: "8 hours and 30 minutes", value: "08:30:00" },
        { text: "9 hours", value: "09:00:00" },
        { text: "9 hour sand 30 minutes", value: "09:30:00" },
        { text: "10 hours", value: "10:00:00" },
        { text: "10 hours and 30 minutes", value: "10:30:00" },
        { text: "11 hours", value: "11:00:00" },
        { text: "11 hours and 30 minutes", value: "11:30:00" },
        { text: "12 hours", value: "12:00:00" },
        { text: "12 hours and 30 minutes", value: "12:30:00" },
        { text: "13 hours", value: "13:00:00" },
        { text: "13 hours and 30 minutes", value: "13:30:00" },
        { text: "13 hours", value: "14:00:00" },
        { text: "14 hours and 30 minutes", value: "14:30:00" },
        { text: "15 hours", value: "15:00:00" },
        { text: "15 hours and 30 minutes", value: "15:30:00" },
        { text: "15 hours", value: "16:00:00" },
        { text: "16 hours and 30 minutes", value: "16:30:00" },
        { text: "17 hours", value: "17:00:00" },
        { text: "17 hours and 30 minutes", value: "17:30:00" },
        { text: "18 hours", value: "18:00:00" },
        { text: "18 hours and 30 minutes", value: "18:30:00" },
        { text: "19 hours", value: "19:00:00" },
        { text: "19 hours and 30 minutes", value: "19:30:00" },
        { text: "20 hours", value: "20:00:00" },
        { text: "20 hours and 30 minutes", value: "20:30:00" },
        { text: "21 hours", value: "21:00:00" },
        { text: "21 hours and 30 minutes", value: "21:30:00" },
        { text: "22 hours", value: "22:00:00" },
        { text: "22 hours and 30 minutes", value: "22:30:00" },
        { text: "23 hours", value: "23:00:00" },
        { text: "23 hours and 30 minutes", value: "23:30:00" },
        // { text: "24 hours", value: "24:00:00" },
      ],
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.model = val;
      },
      immediate: true,
    },
    model: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
