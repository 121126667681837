var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isEditable)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"color":_vm.block.type === 'on-work' ? "primary" : "warning","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"xh-[30px]",class:[
            _vm.block.type,
            _vm.hover ? 'hover' : '',
            _vm.hasEditBlock && _vm.isEditable ? 'xcursor-pointer' : '' ],style:({ width: ((_vm.block.percent) + "%") }),on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},'div',attrs,false),Object.assign({}, tooltip, menu)))]}}],null,true)},[_c('div',{staticClass:"xflex xflex-col xjustify-start xitems-start xgap-y-1"},[_c('div',{staticClass:"xflex xflex-row xjustify-start xtext-left xitems-start xgap-x-2"},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-clock")]),_c('span',[_vm._v(_vm._s(_vm._f("secToHuman")(_vm.block.seconds," Hours", " Minutes"))+" ")])],1),(_vm.block.type === 'on-work')?_c('div',{staticClass:"xflex xflex-row xjustify-start xtext-left xitems-start xgap-x-2"},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-account-hard-hat")]),_c('span',[_vm._v(_vm._s(_vm.block.project_name || "General Task")+" ")])],1):_vm._e(),(_vm.isEdited)?_c('div',{staticClass:"xflex xflex-row xjustify-start xtext-left xitems-start xgap-x-2"},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-account-edit-outline")]),(_vm.isApproved)?_c('span',[_vm._v("Edit Approved")]):_c('span',[_vm._v("Edit Is Pending")])],1):_vm._e()])])]}}],null,false,2689987885)},[(_vm.hasEditBlock && _vm.isEditable)?_c('div',{staticClass:"xbg-[#fff] xp-[0.5em]"},[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary","small":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('edit-block', _vm.block)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1)],1):_vm._e()]):_c('div',{staticClass:"xh-[30px] on-out",style:({ width: ((_vm.block.percent) + "%") })})}
var staticRenderFns = []

export { render, staticRenderFns }