<template>
  <v-card color="transparent" class="!xbg-transparent">
    <v-card-text class="xflex xflex-col xgap-y-[1em]">
      <div class="xmax-w-[1000px]">
        <p class="separator">Terms of Service (“Terms”)</p>

        <p class="separator xfont-[700]">Last updated: {{ lastUpdated }}</p>

        <p class="separator">
          Please read these Terms of Service carefully before using any
          {{ companyName }} services, including {{ appName }} or any other
          service, software, or product (the "Service") provided by
          {{ companyName }}
          ("us", "we", or "our").
        </p>

        <p class="separator">
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all users and others who access or use the Service.
        </p>

        <p class="separator">
          <strong>
            By accessing or using the Service you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Service. These Terms of Service are provided in addition
            to the Master Subscription Agreement between you and
            {{ companyName }}. In the event of a conflict between these Terms
            and the Master Subscription Agreement, the Master Subscription
            Agreement shall control.
          </strong>
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Use of Service</h4>

        <p class="separator">
          You are at least 18 years old or you are under the supervision of a
          parent or guardian. {{ companyName }} grants you a limited and
          revocable license to use the Service. The Service may not be
          reproduced, duplicated, copied, sold, resold, visited, or otherwise
          exploited for any commercial purpose without our express, written
          consent. {{ companyName }} reserves the right to refuse service,
          terminate accounts, remove or edit content, or cancel orders or
          portions thereof, in our sole discretion, if
          {{ companyName }} determines that any client conduct violates
          applicable law or is harmful to the interests of {{ companyName }}.
          You are prohibited from uploading to, posting on or transmitting
          through {{ companyName }} any information or material that (a)
          violates or infringes the proprietary rights of any person; (b) is
          libelous, threatening, defamatory, obscene, indecent, pornographic or
          could give rise to any civil or criminal liability under any law; or
          (c) includes any viruses, worms, Trojan Horses or other harmful code
          or properties. If you use the Service, you are responsible for
          maintaining the confidentiality of your account information and
          password and for restricting access to your computer, and you agree to
          accept responsibility for all activities that occur using your account
          information.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Intellectual property</h4>

        <p class="separator">
          All content on {{ companyName }}, including text, graphics, button
          icons, images and software belongs exclusively to {{ companyName }}.
          The compilation, arrangement, and assembly of all such text, graphics,
          button icons, images and software belong exclusively to
          {{ companyName }}. {{ companyName }}, its logos and trade names are
          registered trademarks of {{ companyName }} and its affiliates. The use
          of any {{ companyName }} trademark is prohibited without the prior
          written consent of {{ companyName }}. All other trademarks not owned
          by {{ companyName }} or its affiliates that appear on the Service are
          the property of their respective owners.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Termination</h4>

        <p class="separator">
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms. All provisions of the
          Terms which by their nature should survive termination shall survive
          termination, including, without limitation, ownership provisions,
          warranty disclaimers, confidentiality provisions, and indemnity and
          limitations of liability.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Subscriptions</h4>

        <p class="separator">
          Some parts of the Service are billed on a subscription basis
          ("Subscription(s)"). You will be billed in accordance with the terms
          of any such Subscription.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Content</h4>

        <p class="separator">
          Our Service allows you to link, store, share, collate, organize, and
          otherwise make available certain information, text, graphics,
          financial information, or other material ("Content"). You are
          responsible for the content linked, stored, shared, or otherwise made
          available on the Service.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Links to Other Web Sites</h4>

        <p class="separator">
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by {{ companyName }}.
        </p>

        <p class="separator">
          {{ companyName }} has no control over, and assumes no responsibility
          for, the content, privacy policies, or practices of any third party
          web sites or services. You further acknowledge and agree that
          {{ companyName }}
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          use of or reliance on any such content, goods or services available on
          or through any such websites or services.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Disclaimer and Limitation of Liability</h4>

        <p class="separator">
          Services provided by {{ companyName }} and the transactions conducted
          upon and through it are provided to you on an "as is" basis.
          {{ companyName }} makes no representations or warranties of any kind,
          express or implied, as to the operation of the Services or the
          information or content therein. To the extent allowed by law,
          {{ companyName }} disclaims all warranties, express or implied,
          including but not limited to, implied warranties of merchantability
          and fitness for a particular purpose, non-infringement, title, quiet
          enjoyment, data accuracy and systems integration. To the extent
          allowed by law,
          {{ companyName }}
          will not be liable for any damages of any kind arising from your use
          of the Site including, but not limited to, indirect, incidental,
          punitive, exemplary, special or consequential damages.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Changes</h4>

        <p class="separator">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days’ notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>

        <h4 class="xfont-[700] xmb-[5px]">Contact Us</h4>

        <p class="separator">
          If you have any questions about these Terms, please contact us via
          {{ support_email }}
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      lastUpdated: "May 25, 2018",
    };
  },
  computed: {
    ...mapGetters("appconfigs", ["support_email"]),
  },
};
</script>

<style lang="scss" scoped></style>
