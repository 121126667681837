<template>
  <div
    class="xflex xmy-0 xmx-auto xflex-col xbg-[#f3f3f3] xmin-h-screen xjustify-start xitems-start"
  >
    <!-- Hero Block -->
    <HeroBlock v-intersect="onIntersect('home')"></HeroBlock>
    <!-- Feature Block -->
    <div id="features"></div>
    <FeatureBlock v-intersect="onIntersect('features')"></FeatureBlock>
    <!-- Command Block -->
    <CommandBlock v-intersect="onIntersect('commands')"></CommandBlock>
    <!-- Pricing Block -->
    <PricingBlock v-intersect="onIntersect('pricing')"></PricingBlock>
    <!-- Faq Block -->
    <FaqBlock v-intersect="onIntersect('faq')"></FaqBlock>
    <!-- Support Block -->
    <SupportBlock v-intersect="onIntersect('support')"></SupportBlock>
  </div>
</template>

<script>
import goTo from "vuetify/lib/services/goto";
import HeroBlock from "./components/HeroBlock.vue";
import FeatureBlock from "./components/FeatureBlock.vue";
import CommandBlock from "./components/CommandBlock.vue";
import PricingBlock from "./components/PricingBlock.vue";
import SupportBlock from "./components/SupportBlock.vue";
import FaqBlock from "./components/FaqBlock.vue";
export default {
  name: "Home",
  components: {
    FeatureBlock,
    HeroBlock,
    CommandBlock,
    PricingBlock,
    SupportBlock,
    FaqBlock,
  },
  data() {
    return {
      open: false,
      code: null,
      block: "#home",
      exchangeInProgress: false,
      blocks: [
        "#home",
        "#features",
        "#pricing",
        "#supports",
        "#commands",
        "#faq",
      ],
    };
  },

  watch: {
    "$route.hash": {
      handler: function (val) {
        this.block = val;
      },
      deep: true,
      immediate: true,
    },
    "$route.query.code": {
      handler: function (val) {
        this.code = val;
        val && !this.$auth.isAuthenticated() && this.exchangeCodeToToken(val);
      },
      immediate: true,
    },
    "$route.query.login": {
      handler: function (val) {
        val &&
          !this.$auth.isAuthenticated() &&
          this.$event.$emit("show-login", true);
      },
      immediate: true,
    },
  },
  mounted() {
    if (["features", "pricing", "support"].includes(this.$route.name))
      goTo(`#${this.$route.name}`);
  },
  methods: {
    // exchangeCodeToToken(code) {
    //    // Guard variable to prevent multiple API calls
    //   if (this.exchangeInProgress) {
    //     return;
    //   }

    //   this.exchangeInProgress = true;

    //   this.$auth.slackLogin(code, (data) => {
    //     this.appLoader(true, "Authenticating....Please wait...");
    //     this.$can.fetchPermissions(() => {
    //       if (this.redirect_uri) this.$router.push({ path: this.redirect_uri });
    //       else this.$router.push({ name: "app-dashboard" });
    //       this.appLoader(false);
    //       this.exchangeInProgress = false;
    //     });
    //   });
    // },
    exchangeCodeToToken(code) {
      // Guard variable to prevent multiple API calls
      if (this.exchangeInProgress) {
        return;
      }
      this.exchangeInProgress = true; // Set flag to true when starting the API call

      try {
        this.$auth.slackLogin(code, (data) => {
          this.appLoader(true, "Authenticating....Please wait...");
          this.$can.fetchPermissions(() => {
            if (this.redirect_uri) {
              this.$router.push({ path: this.redirect_uri });
            } else {
              this.$router.push({ name: "app-dashboard" });
            }
            this.appLoader(false);
            this.exchangeInProgress = false; // Reset flag after API call completes
          });
        });
      } catch (error) {
        console.error('Error during slackLogin:', error);
        this.exchangeInProgress = false; // Reset flag on error as well
      }
    },
    onIntersect(block) {
      // console.log(block);
    },
  },
};
</script>

<style lang="scss">
$carousel-controls-size: 100px;
</style>
