<template>
  <v-app-bar
    app
    dark
    color="#fff"
    :height="70"
    flat
    style="border-bottom: 1px solid #e5e5e5 !important"
  >
    <div
      :class="[
        tablet
          ? 'xjustify-between'
          : mdUp
          ? 'xjustify-end'
          : 'xjustify-between',
      ]"
      class="xflex xgap-2 xflex-row xw-full xitems-center"
    >
      <div class="xflex xflex-row xjustify-start xgap-x-[1em] xitems-center">
        <v-icon v-if="tabletDown" color="#000" @click="toggle_sidebar()">
          mdi-menu
        </v-icon>
        <v-avatar
          height="70"
          width="140"
          v-if="!sidebar"
          tile
          @click="$router.push({ name: 'app-dashboard' }).catch(() => {})"
        >
          <v-img contain :src="logoLongDark"></v-img>
        </v-avatar> 
      </div>
      <div class="xflex xjustify-end xitems-center xgap-x-[1em]">
        <StatusBar v-if="!mobile"></StatusBar>
        <UserDropdown
          lighten
          v-if="$auth.isAuthenticated()"
          source="app-dashboard"
        />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import StatusBar from "./StatusBar.vue";
import UserDropdown from "./UserDropdown.vue";

export default {
  methods: {
    ...mapMutations("appconfigs", ["toggle_sidebar"]),
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  watch: {
    "$route.meta": {
      handler: function (val) {
        this.breadcrumbs = val ? val.breads : [];
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("appconfigs", ["sidebar"]),
  },
  created() {
    this.$event.$on("breadcrumbs", (payload) => {
      this.breadcrumbs = payload;
    });
  },
  components: { UserDropdown, StatusBar },
};
</script>

<style scoped></style>
