<template>
  <v-autocomplete
    hide-details="auto"
    dense
    v-model="selected"
    outlined
    item-text="name"
    :multiple="multiple"
    item-value="id"
    v-bind="$attrs"
    append-icon="mdi-chevron-down"
    :items="items"
    :prepend-inner-icon="hasCreate ? `mdi-plus-circle-outline` : null"
    :return-object="returnObject"
    @click:prepend-inner="$emit('open-create')"
  >
    <template v-slot:item="{ item }">
      <span>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="xfont-semibold font-weight-bold">
              {{ item.name | ucwords }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.region.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
    </template>
    <template v-slot:no-data>
      <v-list-item v-if="hasCreate">
        <span class="subheading">
          Click <v-icon small>mdi-plus-circle-outline</v-icon> to create new
          data center
        </span>
      </v-list-item>
      <v-list-item v-else>
        <span class="subheading"> No site available. </span>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <v-chip color="transparent" label small class="mr-auto w-full my-1">
        <span class="xfont-[500] xtext-[15px]">{{ item.name }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
  inheritAttrs: false,
  props: {
    value: { type: [Object, Number], default: undefined },
    returnObject: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    hasCreate: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val;
      },
      immediate: true,
      deep: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["sites"]),
    items() {
      return _.cloneDeep(this.sites);
    },
  },
  mounted() {
    this.fetchWorkspaceSites({ id: this.user.team_id });
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceSites"]),
  },
};
</script>

<style lang="scss" scoped></style>
