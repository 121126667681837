<template>
  <v-card
    min-height="100vh"
    class="xw-full"
    :class="[tabletDown ? '' : 'xp-[1em]']"
    color="transparent"
    flat
  >
    <router-view :key="$route.fullPath"></router-view>

    <!-- <pre>{{ workspace }}</pre> -->
    <!-- <pre>{{ subscription }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["subscription", "workspace"]),
    subscriptionCount() {
      return this.subscription ? this.subscription.payings_count : 0;
    },
    subscriptionName() {
      // Basic Monthly | Free Trial | Exempted
      if (!this.subscription) return "Free Trial";
      else if (this.subscription.is_exempted) return "Exempted";
      else return "Basic Monthly";
    },
    subcriptionStatus() {
      // Active | Inactive | Free Trial | Cancelled | Free Trial Expired
      if (!this.subscription) return "On Free Trial";
      if (
        this.subscription.status === "BASIC MONTHLY" ||
        this.subscription.is_exempted
      )
        return "Active";
      else if (this.subscription.cancelled_and_on_grace_period)
        return (
          "Canceled, Active Until " +
          moment(this.grace_period_ends_at).format("LL")
        );
      else if (this.subscription.cancelled_and_ended)
        return "Canceled, Inactive";
      else if (this.subscription.is_free_trial_lapsed)
        return "Free Trial Ended";
      else return "On Free Trial";
    },
  },
  data() {
    return {
      tab: "users",
      processing: false,
    };
  },
  created() {
    this.fetchWorkspaceSubscription(this.user.team_id);
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceSubscription"]),
  },
};
</script>

<style lang="scss" scoped></style>
