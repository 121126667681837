<template>
  <v-app-bar app flat dark color="#fff" :height="90">
    <div
      :class="{
        'xcontainer xjustify-around': mdUp,
        'xjustify-between': smDown,
        '!xmax-w-full xjustify-between': tablet,
      }"
      class="xflex xgap-2 xflex-row xw-full xitems-center xmx-auto"
    >
      <v-avatar
        tile
        :width="200"
        class="xcursor-pointer"
        @click="$router.push({ name: 'home', hash: '#home' }).catch(() => {})"
      >
        <v-img contain :src="logoLongDark" alt="Logo"></v-img>
      </v-avatar>
      <v-spacer v-if="!mdUp"></v-spacer>
      <div
        v-if="mdUp"
        class="xflex xflex-row xgap-x-10 xjustify-start md:xml-10 xflex-grow xitems-center"
      >
        <router-link
          :class="[
            isActive('#features') ? '!xtext-[#692D91]' : '!xtext-[#000]',
          ]"
          class="xfont-bold hover:!xtext-[#692D91] xtext-[18px] xcursor-pointer"
          :to="{ name: 'home', hash: '#features' }"
        >
          Features
        </router-link>
        <router-link
          :class="[isActive('#support') ? '!xtext-[#692D91]' : '!xtext-[#000]']"
          class="xfont-bold hover:!xtext-[#692D91] xtext-[18px] xcursor-pointer"
          :to="{ name: 'home', hash: '#support' }"
        >
          Support
        </router-link>
        <router-link
          :class="[isActive('#pricing') ? '!xtext-[#692D91]' : '!xtext-[#000]']"
          class="xfont-bold hover:!xtext-[#692D91] xtext-[18px] xcursor-pointer"
          :to="{ name: 'home', hash: '#pricing' }"
        >
          Pricing
        </router-link>
        <router-link
          :class="[isActive('#faq') ? '!xtext-[#692D91]' : '!xtext-[#000]']"
          class="xfont-bold hover:!xtext-[#692D91] xtext-[18px] xcursor-pointer"
          :to="{ name: 'home', hash: '#faq' }"
        >
          FAQ
        </router-link>
      </div>
      <btn-list
        tile
        class="light"
        v-else
        :height="70"
        btn-color="#fff"
        text-color="var(--v-primary-base)"
        icon="mdi-chevron-down"
      >
        <template v-slot:label>
          <v-icon color="primary" size="30">mdi-menu</v-icon>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <router-link :to="{ name: 'home', hash: '#features' }">
                  Features
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link :to="{ name: 'home', hash: '#support' }">
                  Support
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link :to="{ name: 'home', hash: '#pricing' }">
                  Pricing
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-title>
                <router-link :to="{ name: 'home', hash: '#support' }">
                  FAQ
                </router-link>
              </v-list-item-title>
            </v-list-item> -->
          </v-list>
        </template>
      </btn-list>
      <!-- <StatusBar
        :has-calendar="false"
        v-if="mdUp && $auth.isAuthenticated()"
      ></StatusBar> -->
      <v-btn
        v-if="!isAuthenticated"
        class="text-none"
        text
        height="60"
        color="#000"
        depressed
        @click="openLogin = true"
      >
        <span class="xtext-[18px] xleading-[22px] xfont-bold !xtracking-normal">Log In</span>
      </v-btn>
      <UserDropdown
        :has-name="mdUp"
        :lighten="false"
        source="home"
        v-else
      ></UserDropdown>
      <v-btn
        v-if="!isAuthenticated && mdUp"
        height="60"
        :to="{ name: 'install-app' }"
        color="#692D91"
        elevation="false"
        class="text-none xtracking-normal xpy-[22px] xpx-[24px] !xtext-[18px] xrounded-[8px] xfont-bold"
      >
        Get Free Trial
      </v-btn>
    </div>
    <LoginModal v-model="openLogin" />
  </v-app-bar>
</template>

<script>
import LoginModal from "../Modals/LoginModal.vue";
import StatusBar from "./StatusBar.vue";
import UserDropdown from "./UserDropdown.vue";
export default {
  components: { LoginModal, UserDropdown, StatusBar },
  data() {
    return {
      openLogin: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated();
    },
  },
  created() {
    this.$event.$on("show-login", () => {
      this.openLogin = true;
    });
  },
  watch: {
    "$route.name": {
      handler: function (val) {
        if (val && val == "login" && !this.isAuthenticated)
          this.openLogin = true;
      },
      immediate: true,
    },
  },
  methods: {
    isActive(hash) {
      return (
        (this.$route.hash && this.$route.hash === hash) ||
        this.$route.name === hash.replace("#", "")
      );
    },
  },
};
</script>

<style scoped></style>
