<template>
  <v-card v-bind="$attrs" color="transparent" flat>
    <Collapse expanded class="xmb-1" title="SETUP REQUIREMENTS">
      <template v-slot:default>
        <p>
          {{ appName }} is an app for teams that requires a one time
          integration. To do the integration, you will need to have a
          <a
            href="https://slack.com/help/articles/360018112273-Types-of-roles-in-Slack"
            target="_blank"
          >
            non-guest roles
          </a>
          to your Slack team.
        </p>
        <div
          class="xgrid md:xgrid-cols-2 xgrid-cols-1 xgap-[1em] xjustify-start xitems-stretch"
        >
          <div
            class="xcol-span-1 xflex xflex-col xjustify-start xitems-stretch"
          >
            <p>Confirm your identity from your Slack team.</p>
            <p>
              Grant access to the app to get the list of public channels in your
              team. This is needed for configuring where the time tracker will
              be integrated into your team.
            </p>
            <p>
              Grant access to the app to get the basic info of your team. (e.g
              team name, team domain and team id)
            </p>
            <p>
              Grant access to the app to get a list of all members of your team
              and their information like time zone, email, avatar, etc. This
              will be used on generating team record for the time tracker.
            </p>
            <p>
              Grant access to the app to setup predefined slash commands of our
              app.
            </p>
          </div>
          <div
            class="xcol-span-1 xflex xflex-col xjustify-start xitems-stretch"
          >
            <v-img
              contain
              :src="registerOAuth"
              class="xmax-h-[350px] xmy-[10px]"
            />
          </div>
          <div
            class="md:xcol-span-2 xcol-span-1 xflex xflex-col xjustify-start xitems-stretch"
          >
            <p>
              Authorizing above will automatically add {{ appName }} to your
              team's Slack account.
            </p>
            <p>
              Only non-bot Slack user and non-deleted Slack user will be added.
            </p>
          </div>
        </div>
      </template>
    </Collapse>
    <Collapse
      expanded
      class="xmb-1"
      title="WHAT TO DO AFTER SUCCESSFULLY ADDED MESASIX TIME TRACKER TO SLACK?"
    >
      <template v-slot:default>
        <div class="xw-fit xoverflow-hidden xflex xflex-col xgap-y-[0.5em]">
          <p class="xtext-[14px] xtext-[#e46464]">
            The following steps can be done by your Slack workspace Owner and
            Admins.
          </p>
          <div class="xflex xjustify-start xitems-start xflex-col">
            <p>
              <strong>1. (Required)</strong> Add selected member(s) to allowed
              users for clocking and click "Save Changes".
            </p>
            <img class="xml-[2em] xmax-h-[400px]" contain :src="payingImg" />
          </div>
          <div class="xflex xjustify-start xitems-start xflex-col">
            <p>
              <strong>2. (Optional)</strong> Configure team preferences for
              {{ appName }} and click "Save Changes".
            </p>
            <img
              class="xml-[2em] xmax-h-[400px]"
              contain
              :src="preferenceImg"
            />
          </div>
          <p class="xmt-4">
            Check our complete guide on how to get started with
            {{ appName }}
            <router-link
              class="xinline"
              tag="a"
              :to="{ name: 'getting_started' }"
            >
              here
            </router-link>
          </p>
        </div>
      </template>
    </Collapse>
  </v-card>
</template>

<script>
import Collapse from "./Collapse.vue";
export default {
  inheritAttrs: false,

  components: { Collapse },
  data() {
    return {
      registerOAuth: require("@/assets/slack/slack-register-oauth.png"),
      preferenceImg: require("@/assets/images/preferences.png"),
      payingImg: require("@/assets/images/add-to-paying.png"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
