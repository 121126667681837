<template>
  <v-overlay v-bind="$attrs" absolute v-model="overlay">
    <div class="mx-auto xflex xflex-col xjustify-center xitems-center">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
      <span class="xtext-white xmy-2 xtext-[18px] xfont-bold">
        {{ text }}
      </span>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    text: { type: String, default: "Processing..." },
  },
  watch: {
    value: {
      handler: function (val) {
        this.overlay = val;
      },
      immediate: true,
    },
    overlay: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      overlay: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
