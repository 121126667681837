import request from '@/plugins/requests'
import _ from 'lodash'
import ct from 'countries-and-timezones'

export default {
    namespaced: true,
    state: {
        sidebar: true,
        temporary: false,
        light: true,

        configs: [],
        originals: [],
        app_trial_days: 7,
        default_global_project_task: "General Task",
        number_of_member_to_notify: 2,
        minute_to_respond: 10,
        developers: null,
        support_email: null,
 
        stripe_price_id: null,
        stripe_publishable_key: null
    },
    getters: {
        sidebar: state => state.sidebar,
        temporary: state => state.temporary,
        light: state => state.light,
        appConfigBy: state => (key) => {
            return state.hasOwnProperty(key) ? state[key] : null
        },
        timezones: state => {
            const tzs = Object.values(ct.getAllTimezones())
                .filter((i) => {
                    return !i.name.includes('Etc/') && i.name != 'WET'
                })
                .map((i) => {
                    i.fullname = `${i.name} (${i.utcOffsetStr})`
                    return i
                });
            tzs.push({ name: 'UTC', fullname: 'UTC (00:00)' });
            return tzs.sort((a, b) => (a.name > b.name ? 1 : -1))
        },

        configs: (state) => state.configs,
        originals: (state) => state.originals,
        app_trial_days: state => state.app_trial_days ? state.app_trial_days : null,
        default_global_project_task: state => state.default_global_project_task ? state.default_global_project_task : null,
        number_of_member_to_notify: state => state.number_of_member_to_notify ? state.number_of_member_to_notify : null,
        minute_to_respond: state => state.minute_to_respond ? state.minute_to_respond : null,
        developers: state => state.developers ? state.developers : null,
        support_email: state => state.support_email ? state.support_email : null,
 
        stripe_price_id: (state) => state.stripe_price_id,
        stripe_publishable_key: (state) => statestripe_publishable_key,
    },
    mutations: {
        toggle_sidebar: (state) => (state.sidebar = !state.sidebar),
        set_sidebar: (state, payload) => (state.sidebar = payload),
        set_temporary: (state, payload) => (state.temporary = payload),
        set_light: (state, payload) => (state.light = payload),

        set_configs: (state, payload) => {
            state.configs = payload;
            state.app_trial_days = payload.hasOwnProperty('app_trial_days') ? payload.app_trial_days.cast_value : null;
            state.default_global_project_task = payload.hasOwnProperty('default_global_project_task') ? payload.default_global_project_task.cast_value : null;
            state.number_of_member_to_notify = payload.hasOwnProperty('number_of_member_to_notify') ? payload.number_of_member_to_notify.cast_value : null;
            state.minute_to_respond = payload.hasOwnProperty('minute_to_respond') ? payload.minute_to_respond.cast_value : null;
            state.developers = payload.hasOwnProperty('developers') ? payload.developers.cast_value : null;
            state.support_email = payload.hasOwnProperty('support_email') ? payload.support_email.cast_value : null;
        },
        set_config: (state, { key, data }) => {
            if (state.hasOwnProperty(key))
                state[key] = data
        },
        set_originals: (state, payload) => {
            state.originals = payload
        }, 
        set_stripe_price_id: (state, payload) => (state.stripe_price_id = payload),
        set_stripe_publishable_key: (state, payload) => (state.stripe_publishable_key = payload),
    },
    actions: {
        fetchConfigs: ({ commit }) => {
            request.get("sanctum/csrf-cookie").then(() => {
                request.get(`api/configs`)
                    .then(({ data }) => {
                        commit('set_configs', data.configs || [])
                        commit('set_originals', data.configs || []) 
                        commit('set_stripe_price_id', data.stripe_price_id || null)
                        commit('set_stripe_publishable_key', data.stripe_publishable_key || null)
                    })
            });
        },
        fetchConfig: ({ commit }, key) => {
            request.get(`api/configs/${key}`)
                .then(({ data }) => {
                    commit('set_config', { key, data })
                })
        },
    }
}