var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"xp-[1em]",attrs:{"flat":"","min-height":"100vh","color":"transparent"}},[_c('v-card-title',[_c('h1',{staticClass:"xfont-[600] xtext-[24px] xleading-[29px]"},[_vm._v("Feedbacks")])]),_c('v-card',{staticClass:"xpy-[1em] xmin-h-[90vh]",attrs:{"flat":""}},[_c('v-card-text',[_c('app-table',{attrs:{"loading":_vm.loading,"headings":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('avatar',{attrs:{"size":"35","user":item.user,"with-name":""}}):_c('span',[_vm._v(_vm._s(item.user_id || "-"))])]}},{key:"user_id",fn:function(ref){
var item = ref.item;
return [_c('clipboard',{attrs:{"text":item.user_id}})]}},{key:"email",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('clipboard',{attrs:{"text":item.user.email}}):_c('span',[_vm._v("-")])]}},{key:"team_id",fn:function(ref){
var item = ref.item;
return [(item.team)?_c('span',[_vm._v(" "+_vm._s(item.team.name)+" ")]):_c('span',[_vm._v(_vm._s(item.team_id || "-"))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("format")(item.created_at,"LLL"))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }