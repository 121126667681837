<template>
  <v-card flat min-height="100vh" class="xp-[1em]" color="transparent">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Backlogs</h1>
    </v-card-title>
    <v-card flat class="xpy[1em] xmin-h-[90vh]">
      <v-card-text>
        <div class="xflex xflex-row xjustify-start xitems-center xmb-2">
          <btn-list
            color="primary"
            min-width="200"
            :label="type | ucwords"
            icon="mdi-menu-swap"
          >
            <v-list dense>
              <v-list-item v-for="li in types" :key="li" @click="type = li">
                <v-list-item-content>
                  <v-list-item-title>{{ li | ucwords }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </btn-list>
        </div>
        <app-table :loading="loading" :headings="headers" :items="items">
          <template v-slot:created_at="{ item }">
            <span> {{ item.created_at | format("LLL") }} UTC </span>
          </template>
          <template v-slot:from_file="{ item }">
            <span> {{ item.from_file | ucwords }} </span>
          </template>
          <template v-slot:actions="{ item }">
            <btn-tooltip
              outlined
              small
              color="primary"
              tip="View props"
              @click="openToModal(item, true)"
            >
              View Props
            </btn-tooltip>
          </template>
        </app-table>
      </v-card-text>
    </v-card>

    <BacklogInfoModal v-model="modal" :item="activeItem"></BacklogInfoModal>
  </v-card>
</template>

<script>
import BacklogInfoModal from "./components/BacklogInfoModal.vue";
export default {
  data() {
    return {
      counts: 0,
      loading: false,
      loading_more: false,
      types: [],
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "Type", value: "from_file", sortable: true },
        { text: "Remarks", value: "remark", sortable: true },
        { text: "Event ID", value: "event_id", sortable: true },
        { text: "Custom ID", value: "custom_id", sortable: true },
        { text: "Date", value: "created_at", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      modal: false,
      activeItem: null,
      type: "all",
    };
  },
  created() {
    this.fetchStats();
    this.fetchTypes();
  },
  watch: {
    type: {
      handler: function (val) {
        val && this.fetch(val);
      },
      immediate: true,
    },
  },
  computed: {
    tabs() {
      return [
        {
          name: "Backlogs",
          to: { name: "admin-baclklogs" },
          counts: this.counts,
        },
      ];
    },
  },
  methods: {
    openToModal(item, open) {
      this.activeItem = item;
      this.modal = open;
    },
    fetchStats() {
      this.$axios.get(`admin/backlogs/statistics`).then(({ data }) => {
        this.counts = data.backlogs_count;
      });
    },
    fetchTypes() {
      this.$axios.get(`admin/backlogs/types`).then(({ data }) => {
        this.types = data.types;
        this.types.unshift("all");
      });
    },
    fetch(type) {
      this.loading = true;
      this.$axios
        .get(`admin/backlogs?type=${type}`)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
  },
  components: { BacklogInfoModal },
};
</script>

<style lang="scss" scoped></style>
