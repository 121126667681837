import moment from 'moment'
import momenttz from 'moment-timezone'

import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

export default {
    age(value) {
        if (!value) return 'Unknown';
        let diff = moment().diff(moment(value, 'YYYY-MM-DD HH:mm:ss'));
        let duration = moment.duration(diff);
        return duration.format("D[D] H[H]")
    },
    percent(val) {
        return val.includes('%') ? val : `${val}%`
    },
    money(val, symbol = '$') {
        return val.includes(symbol) ? val : `${symbol} ${val.toFixed(2)}`
    },
    ucwords(value) {
        return (value + '')
            .replaceAll('_', ' ')
            .replace(/^(.)|\s+(.)/g, function ($1) {
                return $1.toUpperCase()
            })
    },
    uppercase(value) {
        return value ? value.toUpperCase() : ''
    },
    truncate(text, stop, clamp) {
        if (!text) return ''
        return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    },
    trunc_mid(str, firstCharCount = str.length, endCharCount = 0) {
        return str ? truncateMiddle(str, firstCharCount, endCharCount, '...') : ''
    },
    joinHumanReadable(value, connector = 'and') {
        if (!value || !value.length) {
            return ''
        }
        if (value.length === 1) {
            return value[0]
        }
        var lastStr = value.pop()
        return value.join(', ') + ' ' + connector + ' ' + lastStr
    },
    utcToLocal(utcTime, format) {
        const formatting = format ? format : 'YYYY-MM-DD HH:mm:ss'
        return moment.utc(utcTime).local().format(formatting)
    },
    from_now(date) {
        const tz_date = momenttz.tz(date, 'UTC')
        return moment(tz_date).fromNow()
    },
    format(value, format = 'MMM D Y HH:mm A') {
        return value ? moment(value).format(format) : 'Unknown';
    },
    secToHuman(value, hrs_format = ' hours', min_format = ' minutes') {
        const sec = parseInt(value); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60);

        return `${!isNaN(hours) ? hours : 0}${hrs_format} ${!isNaN(hours) ? minutes : 0}${min_format}`
    },
    toHHMMSS(value) {
        if (!value || typeof value === 'undefined') return '00:00:00'

        var sec_num = parseInt(value, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }
}