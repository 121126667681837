<template>
  <v-card flat color="transparent" class="xw-full xmin-h-[100vh]">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Thank You!</h1>
    </v-card-title>
    <div
      class="xm-0 xbg-bottom xmin-h-screen"
      :style="{ backgroundImage: `url(${footer_wave})` }"
    >
      <div class="xpt-[3em]">
        <div
          class="xpx-3 xmx-auto xflex xflex-wrap xflex-col md:xflex-row xitems-center"
        >
          <!--Left Col-->
          <div
            class="xflex xflex-col xw-full md:xw-8/12 xmx-auto xjustify-center xitems-start xtext-center md:xtext-left"
          >
            <p class="xuppercase xtracking-wide xw-full">
              Subscription succeed!
            </p>
            <h1 class="xmy-4 xtext-5xl xfont-bold xleading-tight">
              Thank you for your subscription!
            </h1>
            <p class="xleading-normal xtext-2xl xmb-8">
              For more question regarding your subscription,you can contact us
              at
              <span class="xfont-[600] xtext-primary">{{ support_email }}</span>
            </p>
            <v-btn
              x-large
              color="primary"
              class="text-none xrounded-full xmt-[3em]"
              @click="$router.push({ name: 'app-dashboard' })"
            >
              Back to Dashboard
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      footer_wave: require("@/assets/images/wave-bottom.svg"),
    };
  },
  computed: {
    ...mapGetters("appconfigs", ["support_email"]),
  },
};
</script>

<style lang="scss" scoped></style>
