

const developmentApp = {
    localhost: {
        developer_mode: true,
        appName: 'Mesasix Time Tracker',
        appBase: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}`,
        apiBase: `${process.env.VUE_APP_API}`,
        zendeskKey: '64HWv0gL2pJjfm8nWwL0LIvbcA7rjWAT',
        companyName: 'KirbyCorps',
        slackApp: {
            app_id: 'A3FJK4TQX',
            client_id: '7363994471.117631163847'
        }
    },
    staging: {
        developer_mode: true,
        appName: 'Mesasix Time Tracker',
        appBase: 'https://timetracker-stage.mesasix.com',
        apiBase: 'https://api.timetracker-stage.mesasix.com',
        zendeskKey: null,
        companyName: 'Mesasix',
        slackApp: {
            app_id: 'A3FJK4TQX',
            client_id: '7363994471.117631163847'
        }
    },
    live: {
        developer_mode: false,
        appName: 'Mesasix Time Tracker',
        appBase: 'https://timetracker.mesasix.com',
        apiBase: 'https://api.timetracker.mesasix.com',
        zendeskKey: null,
        companyName: 'Mesasix',
        slackApp: {
            app_id: 'A0TEXFJJY',
            client_id: '7363994471.27507528644'
        }
    }
}

let detected_environment = developmentApp.localhost

if (window.location.host.includes('timetracker.mesasixdev')) {
    detected_environment = developmentApp.staging
}
else if (window.location.host.includes('timetracker.mesasix')) {
    detected_environment = developmentApp.live
}

export const environment = Object.assign({}, detected_environment, {
    modules: [
        'data-centers', 'regions', 'sites', 'ptos', 'projects' //lowercase all
    ]
})
