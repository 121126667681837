import request from '@/plugins/requests'
import httpBuildQuery from 'http-build-query';

export default {
    namespaced: true,
    state: {
        roles: [],
        workspaceRoles: [],
        role: null, 
        appPermissions: [],
        managedSites: [],
        managedRegions: []
    },
    getters: {
        roles: (state) => state.roles,
        workspaceRoles: (state) => state.workspaceRoles,
        role: (state) => state.role, 
        appPermissions: state => state.appPermissions,
        managedSites: state => state.managedSites,
        managedRegions: state => state.managedRegions,
    },
    mutations: {
        set_roles: (state, payload) => (state.roles = payload),
        set_role: (state, payload) => (state.role = payload), 
        set_appPermissions: (state, payload) => (state.appPermissions = payload),
        set_workspace_roles: (state, payload) => (state.workspaceRoles = payload),
        set_managed_sites: (state, payload) => (state.managedSites = payload),
        set_managed_regions: (state, payload) => (state.managedRegions = payload),
    },
    actions: {
        fetchRoles: ({ commit }, excepts) => {
            request.get(`api/roles?${httpBuildQuery({ excepts: excepts })}`)
                .then(({ data }) => {
                    commit('set_roles', data)
                })
        },
        fetchRole: ({ commit }, id) => {
            request.get(`api/roles/${id}`)
                .then(({ data }) => {
                    commit('set_role', data)
                })
        },
        fetchAppPermissions: ({ commit }) => {
            request.get(`api/roles/all/permissions`)
                .then(({ data }) => {
                    commit('set_appPermissions', data)
                })
        },
        fetchWorkspaceRoles: ({ commit }, id) => {
            request.get(`api/workspaces/${id}/roles`)
                .then(({ data }) => {
                    commit('set_workspace_roles', data)
                })
        },
    }
}