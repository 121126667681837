<template>
  <v-card color="transparent" class="!xbg-transparent">
    <v-card-text class="xflex xflex-col xgap-y-[1em]">
      <div class="xmax-w-[1000px]">
        <h3 class="xfont-[700] xmb-[5px]">Trademark</h3>
        <p>
          Slack, Slack API and Slack logo are trademark of Slack Technologies,
          Inc.
        </p>
        <p>Stripe, Stripe API and Stripe logo are trademark of Stripe.</p>
        <p>
          {{ appName }} is a trademark of {{ companyName }} LLC and are not
          created by, affiliated with, or supported by Slack Technologies, Inc.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
