<template>
  <v-tooltip
    color="primary"
    :top="isTop"
    :bottom="isBottom"
    :right="isRight"
    :left="isLeft"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click.stop="$emit('click', $event)"
        depressed
        :block="block"
        :loading="loading"
        :disabled="disabled"
        class="text-none !xpx-[20px] btn-tooltip"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
      >
        <slot name="default">Button</slot>
        <span v-if="loading">Processing...</span>
      </v-btn>
    </template>
    <span v-html="tip"></span>
  </v-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    tip: { type: String, default: "" },
    pos: { type: String, default: "top" },
    loading: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    isTop() {
      return this.pos.toLowerCase() === "top";
    },
    isBottom() {
      return this.pos.toLowerCase() === "bottom";
    },
    isLeft() {
      return this.pos.toLowerCase() === "left";
    },
    isRight() {
      return this.pos.toLowerCase() === "right";
    },
  },
};
</script>

<style lang="scss" scoped></style>
