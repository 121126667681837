<template>
  <Modal
    v-model="open"
    :valid="true"
    class="xrounded-lg"
    :fullscreen-on-mobile="false"
    :max-width="maxWidth"
    :has-footer="false"
  >
    <template v-slot:title>
      <span :class="[`${type}--text`]" class="xfont-bold">{{ title }}</span>
    </template>
    <v-sheet
      color="transparent"
      class="xmin-h-[100px] xflex xflex-col xjustify-center xitems-center"
    >
      <div
        class="xfont-semibold primary--text xtext-[16px] xbg-transparent"
        v-html="text"
      ></div>
    </v-sheet>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      maxWidth: 500,
      open: false,
      text: "Are you sure you want to continue?",
      title: "Mesasix Time Tracker Notification",
      type: "primary",
    };
  },
  created() {
    this.$event.$on("open_notification_modal", (message, type, title) => {
      this.text = message || this.text;
      this.type = type || "primary";
      this.title = title || "Mesasix Time Tracker Notification";
      this.open = true;
    });
  },
};
</script>

<style lang="css" scoped></style>
