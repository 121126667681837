<template>
  <div
    id="commands"
    class="xbg-[#000] xw-full xblock md:xh-[100vh] xh-full xmin-h-[800px] xpy-8 md:xpy-0"
  >
    <div
      :class="{ '!xmax-w-[90%]': tablet || smDown }"
      class="xgrid xcontainer xmx-auto md:xgrid-cols-2 xgrid-cols-1 xw-full xh-full !xbg-[#000]"
      :style="
        mdUp
          ? {
              background: `url(${topcenter}) 50% top no-repeat, url(${rightbottom}) right 100px bottom 100px no-repeat, url(${leftbottom}) 20px bottom no-repeat`,
            }
          : {
              background: ` url(${rightbottom}) right 100px bottom 100px no-repeat, url(${leftbottom}) 20px bottom no-repeat`,
            }
      "
    >
      <div
        :class="tablet || smDown ? 'xpt-[2em]' : 'xp-[2em]'"
        class="xcol-span-1 md:xp-[80px] xflex xflex-col xjustify-start xitems-stretch xh-full"
      >
        <h2
          class="xtext-[#A240E3] xfont-[900] xmb-[1em] xtext-[13px] xuppercase xtracking-[0.43em] xleading-[20px]"
        >
          Commands
        </h2>
        <div
          class="xfont-[700] xmb-[24px] xtext-[42px] xleading-[51px] xtext-[#fff]"
        >
          Talk to Our Tracker
        </div>
        <p
          class="xtext-[18px] xfont-[400] xleading-[160%] xtext-[#CDCDCD] !xmb-[24px]"
        >
          It has never been easier to track your team members' work time than
          with the {{ appName }}.
        </p>
        <div
          class="xflex xflex-col xitems-center xgap-y-4 xmb-[1em] xfont-bold"
        >
          <div
            :class="[
              activeBtn === 'btn1'
                ? 'xbg-[#7A38A3] text-white'
                : 'xbg-[#1C1C1C] !xtext-[#999]',
              btnClass,
            ]"
            @click="setActive('btn1')"
          >
            <span>Slack Clocking and Project Tracking</span>
          </div>
          <div
            :class="[
              activeBtn === 'btn2'
                ? 'xbg-[#7A38A3] text-white'
                : 'xbg-[#1C1C1C] !xtext-[#999]',
              btnClass,
            ]"
            @click="setActive('btn2')"
          >
            <span>Check Your Total Work Time</span>
          </div>
          <div
            :class="[
              activeBtn === 'btn3'
                ? 'xbg-[#7A38A3] text-white'
                : 'xbg-[#1C1C1C] !xtext-[#999]',
              btnClass,
            ]"
            @click="setActive('btn3')"
          >
            <span>Other Commands</span>
          </div>
        </div>
      </div>
      <div
        :class="tabletDown ? '' : 'xmin-h-[800px]'"
        class="xcol-span-1 xflex xflex-col xitems-stretch xh-full"
      >
        <div
          v-if="activeBtn === 'btn1'"
          :class="[
            smDown ? 'xmx-auto' : '',
            tablet ? '!xw-full' : '',
            tablet || smDown ? '!xw-full' : 'xmin-h-[800px]',
          ]"
          class="xflex command-list md:xh-[100vh] xflex-col md:xw-[75%] xw-[80%] xp-[1em] xjustify-start hover:xoverflow-y-auto xoverflow-hidden xitems-stretch xh-full xbg-[#1C1C1C]"
        >
          <h2
            class="xtext-[24px] xleading-[29px] xfont-[700] xtext-[#fff] xmy-[1em]"
          >
            Slack Clocking and Project Tracking
          </h2>
          <div class="command-wrapper">
            <span class="command">/in</span>
            Clock in. Starts tracking your time.
          </div>
          <div class="command-wrapper">
            <span class="command">/in [project-short-code]</span>
            Clock in with task or project. Starts tracking the time of the
            project/task specified.
          </div>
          <div class="command-wrapper">
            <span class="command">/out</span>
            Clock out. Stops recording the time.
          </div>
          <div class="command-wrapper">
            <span class="command">/brb </span>
            Execute this command when you're going to take your break.
            {{ appName }} Bot pauses recording the time of your current project.
          </div>
          <div class="command-wrapper">
            <span class="command">/back</span>
            When you are back from break, say /back. {{ appName }} Bot continues
            recording your time. This will also resume your last active project.
          </div>
          <div class="command-wrapper">
            <span class="command">/back [project-short-code]</span>
            {{ appName }} Bot continues recording your time. This will also
            start recording the task/project specified.
          </div>
          <div class="command-wrapper">
            <span class="command">/switch [project-short-code]</span>
            Change your current project. {{ appName }} Bot will pause your last
            active project and will start recording the time for the new
            specified project. You can also switch to an already existing
            project in your shift, {{ appName }} Bot will pause the previous
            project and resume recording the time for the specified project.
          </div>
          <div class="command-wrapper">
            Note: Projects and Tasks are case sensitive.
          </div>
          <div class="command-wrapper">
            Below are the commands available for team owner/admin only
          </div>
          <div class="command-wrapper">
            <span class="command">/in @name project-short-code</span>
            Clock in a member
          </div>
          <div class="command-wrapper">
            <span class="command">/brb @name</span>
            BRB a member
          </div>
          <div class="command-wrapper">
            <span class="command">/back @name</span>
            project/task Mark a member as back.
          </div>
          <div class="command-wrapper">
            <span class="command">/out @name </span>
            Clock out a member
          </div>
          <div class="command-wrapper">
            <span class="command">/switch @name project-short-code </span>
            Switch member's project/task
          </div>
          <div class="command-wrapper">Project Tracking</div>
          <div class="command-wrapper">
            <span class="command">/project show list</span>
            Show current project/task details -- project, time and status.
          </div>
          <div class="command-wrapper">
            <span class="command">/project assign project-short-code </span>
            Assign your default project, this project will be used when omitting
            the project/task in /in command.
          </div>
          <div class="command-wrapper">Forcibly clock out a member</div>
          <div class="command-wrapper">
            <span class="command">/cut @name</span>
            Force Clock out a team member (for team owner/admin use only). The
            user will be notified that his/her time has been cut.
          </div>
        </div>
        <div
          v-if="activeBtn === 'btn2'"
          :class="[
            smDown ? 'xmx-auto' : '',
            tablet ? '!xw-full' : '',
            tablet || smDown ? '!xw-full' : 'xmin-h-[800px]',
          ]"
          class="xflex command-list md:xh-[100vh] xflex-col md:xw-[75%] xw-[80%] xp-[1em] hover:xoverflow-y-auto xoverflow-hidden xitems-stretch xh-full xbg-[#1C1C1C]"
        >
          <h2
            class="xtext-[24px] xleading-[29px] xfont-[700] xtext-[#fff] xmy-[1em]"
          >
            Check Your Total Work Time
          </h2>
          <div class="command-wrapper">
            <span class="command">/check my status</span>
            Check your current status.
          </div>
          <div class="command-wrapper">
            <span class="command">/check my shift</span>
            Check your total work time for the current shift.
          </div>
          <div class="command-wrapper">
            <span class="command">/check my week</span>
            Check your total work time for the current week.
          </div>
          <div class="command-wrapper">
            <span class="command">/check my month</span>
            Check your total work time for the current month.
          </div>

          <div class="command-wrapper">
            Below are the commands available for team owner/admin only
          </div>
          <div class="command-wrapper">
            <span class="command">/check @name status</span>
            Check specified team member's current status.
          </div>
          <div class="command-wrapper">
            <span class="command">/check @name shift</span>
            Check specified team member's total work time for the current shift.
          </div>
          <div class="command-wrapper">
            <span class="command">/check @name week</span>
            Check specified team member's total work time for the current week.
          </div>
          <div class="command-wrapper">
            <span class="command">/check @name month</span>
            Check specified team member's total work time for the current month.
          </div>
        </div>
        <div
          v-if="activeBtn === 'btn3'"
          :class="[
            smDown ? 'xmx-auto' : '',
            tablet ? '!xw-full' : '',
            tablet || smDown ? '!xw-full' : 'xmin-h-[800px]',
          ]"
          class="xflex command-list md:xh-[100vh] xflex-col md:xw-[75%] xw-[80%] xp-[1em] hover:xoverflow-y-auto xoverflow-hidden xitems-stretch xh-full xbg-[#1C1C1C]"
        >
          <h2
            class="xtext-[24px] xleading-[29px] xfont-[700] xtext-[#fff] xmy-[1em]"
          >
            Other Commands
          </h2>
          <div class="command-wrapper">
            Submit feedback, question or suggestions to the mesasix team:
          </div>
          <div class="command-wrapper">
            <span class="command">/mesasix feedback Great App</span>
            Sends "Great App" to us - Help us make {{ appName }} a better app.
          </div>
          <div class="command-wrapper">
            <span class="command">/mesasix help</span>
            Will show command list.
          </div>
          <div class="command-wrapper xw-full">
            <span class="command">/mesasix</span>
            Will show a menu button. Each button has its own uses.
            <v-img
              contain
              class="xmy-[0.5em] xrounded-md"
              :src="mesasixMenu"
            ></v-img>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowH: 0,
      activeBtn: "btn1",
      homeFront3: require("@/assets/covers/homebg3.png"),
      mesasixMenu: require("@/assets/images/mesasix-menu.png"),
      rightbottom: require("@/assets/commands/circle.svg"),
      topcenter: require("@/assets/commands/half-circle.svg"),
      leftbottom: require("@/assets/commands/half-oblong.svg"),
      btnClass: `xtext-[#fff] xtext-none xitems-center xcursor-pointer hover:!xbg-[#7A38A3] xpx-[20px] xpy-[10px] xmin-h-[60px] xrounded-[7px] xflex xjustify-start xoverflow-hidden xtext-white md:xtext-[22px] xtext-[18px] xwhitespace-normal xleading-[27px] xtext-wrap xtext-left xw-full`,
      commandList: ``,
    };
  },
  methods: {
    setActive(btn) {
      this.activeBtn = btn;
    },
    onResize() {
      this.windowH = window.innerHeight;
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style lang="scss" scoped>
#commands {
  .command-list {
    .command-wrapper {
      margin-bottom: 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      line-height: 160%;
      .command {
        font-family: "PT Mono";
        font-weight: 700;
        display: block;
        color: #d698ff;
      }
    }
  }
}
</style>
