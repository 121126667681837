<template>
  <v-select
    hide-details="auto"
    dense
    :class="[`command-color-${selected}`]"
    class="!xtext-center command-picker-input"
    v-model="selected"
    outlined
    shaped
    light
    item-text="text"
    item-value="value"
    append-icon="mdi-chevron-down"
    v-bind="$attrs"
    :items="selectable"
  >
  </v-select>
</template>

<script>
export default {
  props: {
    value: { type: String, default: "in" },
    previous: { type: String, default: "out" },
    editable: { type: Boolean, default: true },
  },
  data() {
    return {
      selected: "in",
      in: { text: "IN", value: "in", color: "#60D74D" },
      brb: { text: "BRB", value: "brb", color: "#FFBE3F" },
      back: { text: "BACK", value: "back", color: "#60D74D" },
      out: { text: "OUT", value: "out", color: "#FF6161" },
      switch: { text: "SWITCH", value: "switch", color: "#3FAEFF" },
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val;
      },
      immediate: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  computed: {
    color() {
      return this[this.selected] ? this[this.selected].color : "#000";
    },
    selectable() {
      if (!this.editable) return [this[this.value]];
      else if (this.previous === "in") return [this.brb, this.switch];
      else if (this.previous === "brb") return [this.back];
      else if (this.previous === "back") return [this.switch, this.brb];
      else if (this.previous === "out") return [this.in];
      else return [this.brb, this.switch]; //switch
    },
  },
};
</script>

<style lang="scss">
.command-picker-input {
  &.command-color-in {
    fieldset {
      background: #60d74d;
    }
  }
  &.command-color-brb {
    fieldset {
      background: #ffbe3f;
    }
  }
  &.command-color-back {
    fieldset {
      background: #60d74d;
    }
  }
  &.command-color-out {
    fieldset {
      background: #ff6161;
    }
  }
  &.command-color-switch {
    fieldset {
      background: #3faeff;
    }
  }
  fieldset {
    border-radius: 30px !important;
  }
  .v-select__selection {
    width: 100% !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
