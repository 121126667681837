<template>
  <div
    class="xflex xflex-row xjustify-between xgap-x-[1em] xitems-center xtext-[12px]"
  >
    <div class="xtext-[#A8A8A8]" v-if="laptopUp && hasCalendar">
      {{ calendarNow | format(formatBySize) }}
    </div>
    <div
      v-if="!fetching"
      :style="{ backgroundColor: bgColor, color: textColor }"
      class="xflex xflex-row xgap-[5px] xpy-[5px] xpx-[1em] xrounded-full xjustify-between xitems-center"
    >
      <div class="xflex xflex-row xflex-nowrap xpx-[0.5em]">
        <v-icon size="10" :color="textColor" left>mdi-circle</v-icon>
        {{ status | ucwords }}
      </div>
      <v-divider
        class="xmx-[5px] xh-[13px] xrelative xtop-[2px]"
        vertical
        :style="{ backgroundColor: textColor }"
        v-if="status !== 'out'"
      ></v-divider>
      <div
        class="xflex xflex-row xflex-nowrap xpx-[0.5em] xtext-[12px] xfont-[400]"
        :title="last_active_project"
        v-if="status !== 'out' && mdUp"
      >
        {{ last_active_project | truncate(mdUp ? 20 : 8) }}
      </div>
      <v-divider
        class="xbg-[#A8A8A8] xmx-[5px] xh-[13px] xrelative xtop-[2px]"
        vertical
        v-if="mdUp"
        :style="{ backgroundColor: textColor }"
      ></v-divider>
      <div class="xpx-[0.5em] xfont-[400]" v-if="on_work || on_break">
        {{ on_work ? work_seconds : break_seconds | toHHMMSS }}
      </div>
      <div class="xpx-[0.5em] xfont-[400]" v-else-if="last_log_datetime">
        {{ last_log_datetime | from_now }}
      </div>
    </div>
    <!-- <pre>{{ all }}</pre> -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: {
    hasCalendar: { type: Boolean, default: true },
  },
  data() {
    return {
      calendarNow: new Date(),
      interval: null,
    };
  },
  created() {
    this.fetchCurrentStatus();
  },
  mounted() {
    this.interval = setInterval(
      function () {
        this.calendarNow = new Date();
        if (this.on_work) this.increment_work_seconds();
        else if (this.on_break) this.increment_break_seconds();
      }.bind(this),
      1000
    );
  },
  destroyed: function () {
    clearInterval(this.interval);
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("currents", [
      "fetching",
      "status",
      "no_record",
      "on_break",
      "on_work",
      "on_out",
      "break_seconds",
      "work_seconds",
      "last_log_datetime",
      "last_active_project",
    ]),
    formatBySize() {
      return this.mdUp
        ? "dddd, MMMM D \| h:mm:ss A"
        : "ddd, MMM D \| h:mm:ss A";
    },
    bgColor() {
      if (["in", "switch", "back"].includes(this.status)) return "#19B00024";
      else if (this.status === "brb") return "#d59a0324";
      else return "#FF494924";
    },
    textColor() {
      if (["in", "switch", "back"].includes(this.status)) return "#19A917";
      else if (this.status === "brb") return "#FCAA3F";
      else return "#FF4949";
    },
  },
  methods: {
    ...mapActions("currents", ["fetchCurrentStatus"]),
    ...mapMutations("currents", [
      "increment_work_seconds",
      "increment_break_seconds",
    ]),
  },
};
</script>
