var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"xp-[1em]",attrs:{"flat":"","min-height":"100vh","color":"transparent"}},[_c('v-card-title',[_c('h1',{staticClass:"xfont-[600] xtext-[24px] xleading-[29px]"},[_vm._v("Roles")])]),_c('v-card',{staticClass:"xpy-[1em]",attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"xflex mb-2 xflex-row xjustify-end xitems-center"},[_c('btn-tooltip',{attrs:{"color":"primary","tip":"Add new role","outlined":""},on:{"click":function($event){return _vm.openRoleModal(true, null)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add New Role ")],1)],1),_c('app-table',{attrs:{"headings":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"xflex xflex-row xjustify-center xitems-center xgap-2"},[_c('icon-tooltip',{attrs:{"tip":"Edit Role","clickable":"","color":"primary","icon":"mdi-pencil"},on:{"click":function($event){return _vm.openRoleModal(true, item)}}}),_c('icon-tooltip',{attrs:{"tip":"Delete Role","color":"error","clickable":"","icon":"mdi-delete"},on:{"click":function($event){return _vm.handleDelete(item)}}})],1)]}},{key:"workspace",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"xtext-[12px]"},[_vm._v(" "+_vm._s(item.workspace ? item.workspace.name : "System [APP]")+" ")])]}},{key:"deletable",fn:function(ref){
var item = ref.item;
return [(item.deletable)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-account-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-account-cancel")])]}},{key:"assignable",fn:function(ref){
var item = ref.item;
return [(item.assignable)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-account-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-account-cancel")])]}}])})],1),_c('v-card-actions',{staticClass:"xflex xflex-col xjustify-center xitems-center xpy-[0.5em]"},[_c('v-btn',{staticClass:"text-none px-5",attrs:{"color":"primary","depressed":"","loading":_vm.loading_more,"disabled":_vm.loading || _vm.loading_more || !_vm.next_page_url},on:{"click":_vm.fetchMoreRoles}},[_vm._v(" Load more ")])],1)],1),_c('AddEditRole',{attrs:{"item":_vm.selected},on:{"save":_vm.handleSave,"update":_vm.handleUpdate},model:{value:(_vm.roleModal),callback:function ($$v) {_vm.roleModal=$$v},expression:"roleModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }