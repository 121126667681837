<template>
  <v-navigation-drawer
    app
    :light="light"
    height="100vh"
    v-model="sidebar"
    color="#ffffff"
    :temporary="temporary"
    left
    :mobile-breakpoint="1023"
  >
    <template v-slot:append>
      <div
        class="xbg-transparent xh-[50px] xgap-x-[5px] xflex xflex-row xjustify-start xpx-[1em] xitems-center xborder-[#e5e5e5] xborder-r-[1px]"
      >
        <v-avatar tile height="40">
          <v-img contain :src="user.team.image_url"></v-img>
        </v-avatar>
        <div class="xflex xflex-col xjustify-start xitems-start xtext-left">
          <span
            class="xfont-[600] xtext-[#373737] xtext-[13px] xleading-[13px]"
            >{{ user.team.name }}</span
          >
          <a
            target="_blank"
            rel="noreferrer"
            :href="`https://${user.team.domain}.slack.com`"
            ><span class="xfont-[400] xtext-[10px]"
              >{{ user.team.domain }}.slack.com</span
            ></a
          >
        </div>
      </div>
    </template>
    <template v-slot:prepend>
      <v-list-item
        class="xbg-[#fff] xh-[70px] !xborder-b-[#e5e5e5] xborder-solid xborder-b-[1px]"
      >
        <v-list-item-content>
          <v-avatar
            tile
            @click="$router.push({ name: 'app-dashboard' }).catch(() => {})"
          >
            <v-img contain :src="logoLongDark"></v-img>
          </v-avatar>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list
      class="xmy-0 xpy-0 xborder-right-[1px] xmt-5 lg:xmt-9"
      flat
      v-model="leftsidebar"
    >
      <template v-for="item in items">
        <v-hover
          v-slot:default="{ hover }"
          :key="`main-${item.title}`"
          v-if="canShow(item)"
        >
          <v-list-item
            class="xh-[50px] xm-0 xp-0 sidebar-item"
            :class="[
              hover ? 'sidebar-item-hover' : '',
              isActive(item) ? 'sidebar-item-active' : '',
            ]"
            :to="canClickItem(item) ? item.route : null"
            :disabled="!canClickItem(item)"
          >
            <img
              :src="isActive(item) ? borderLeftActive : borderLeftInactive"
            />
            <div
              class="xflex xflex-row xw-full xml-[30px] xh-full xjustify-start xitems-center xgap-x-[.5em]"
            >
              <v-icon :color="isActive(item) || hover ? '#7A38A3' : '#9A9A9A'">
                {{ item.icon }}
              </v-icon>
              <span
                class="xfont-[600] xtext-[15px] xleading-[18px]"
                :class="
                  isActive(item) || hover
                    ? 'xtext-[#7A38A3]'
                    : 'xtext-[#9A9A9A]'
                "
              >
                {{ item.title }}
                <span
                  v-if="!canClickItem(item)"
                  class="xblock xmt-[1px] xtext-[#F44336] xfont-[500] xtext-[10px]"
                >
                  *Available for paying members only
                </span>
              </span>
            </div>
          </v-list-item>
        </v-hover>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
    sidebar: {
      get() {
        return this.mdUp && !this.tablet
          ? true
          : this.$store.getters["appconfigs/sidebar"];
      },
      set(val) {
        this.$store.commit("appconfigs/set_sidebar", val);
      },
    },
    canClickItem() {
      return (item) => {
        const nonClickableItems = [
          "Team Record",
          "Projects",
          "Paid Time Off",
          "Member Report",
        ];
        return true; // this.user.is_paying || !nonClickableItems.includes(item.title);
      };
    },
    temporary: {
      get() {
        return this.mdUp ? false : this.$store.getters["appconfigs/temporary"];
      },
      set(val) {
        if (!this.mdUp) this.$store.commit("appconfigs/set_temporary", val);
      },
    },
    light: {
      get() {
        return this.$store.getters["appconfigs/light"];
      },
      set(val) {
        this.$store.commit("appconfigs/set_light", val);
      },
    },
    accessibleSetting() {
      let returnVal = "app-settings-preferences";
      if (this.$can.or("view_team_preferences|view_team_info"))
        return returnVal;
      else if (this.$can.or("view_bot_responses|manage_bot_responses"))
        return "app-settings-bot-responses";
      else if (this.$can.and("manage_roles_permissions"))
        return "app-settings-user-management";
      return returnVal;
    },
    items() {
      let list = [
        {
          title: "My Dashboard",
          route: { name: "app-dashboard" },
          icon: "mdi-view-grid-outline",
          alias: ["app-dashboard", "app-my-settings"],
          exact: null,
          some: null,
        },
        {
          title: "My Record",
          route: {
            name: "app-my-record",
            params: { year_month: moment().format("YYYY-MM") },
          },
          alias: ["app-my-record", "app-my-pending-edits", "app-my-ptos"],
          icon: "mdi-server",
          exact: null,
          some: null,
        },
        {
          title: "Team Record",
          route: {
            name: "app-team-record-daily",
            params: { date: moment().format("YYYY-MM-DD") },
          },
          alias: [
            "app-team-record-daily",
            "app-team-record-monthly",
            "app-team-record-weekly",
            "app-team-record-custom",
            "app-user-record-daily",
            "app-user-record-monthly",
            "app-user-record-weekly",
            "app-user-record-custom",
          ],
          icon: "mdi-layers-triple-outline",
          exact: "view_team_record",
          some: null,
        },
      ];
      if (this.isModuleEnabled("projects")) {
        list.push({
          title: "Projects",
          route: {
            name: "app-project-report-monthly",
            params: { year_month: moment().format("YYYY-MM") },
          },
          icon: "mdi-content-copy",
          exact: null,
          some: "view_project_report|view_projects",
          alias: [
            "app-project-report-daily",
            "app-project-report-monthly",
            "app-project-report-weekly",
            "app-project-daily",
            "app-project-monthly",
            "app-project-weekly",
            "app-user-pto-custom",
            "app-user-pto-monthly",
            "app-user-time-custom",
            "app-user-time-monthly",
          ],
        });
      }
      if (this.isModuleEnabled("ptos")) {
        list.push({
          title: "Paid Time Off",
          route: {
            name: "app-pto-all-monthly",
            params: { year_month: moment().format("YYYY-MM") },
          },
          icon: "mdi-umbrella-beach",
          exact: "manage_user_ptos",
          some: null,
          alias: [
            "app-pto-all-monthly",
            "app-pto-all-weekly",
            "app-pto-all-custom",
            "app-pto-pending-monthly",
            "app-pto-pending-weekly",
            "app-pto-pending-custom",
          ],
        });
      }
      list.push({
        title: "Member Report",
        route: { name: "app-member-report" },
        icon: "mdi-clipboard-outline",
        exact: "view_team_report",
        some: null,
        alias: ["app-member-report", "app-add-time", "app-add-pto"],
      });
      list.push({
        title: "Settings",
        icon: "mdi-cog",
        route: { name: this.accessibleSetting },
        alias: [
          "app-settings-general-regions",
          "app-settings-general-sites",
          "app-settings-general-data-centers",
          "app-settings-general-new-users",
          "app-settings-general-all-users",
          "app-settings-preferences",
          "app-settings-bot-responses",
          "app-settings-user-management",
        ],
        exact: null,
        some: "view_office|manage_office|view_region|manage_region|view_data_center|manage_data_center|view_team_info|view_bot_responses|manage_bot_responses|view_team_preferences|update_team_preferences|manage_roles_permissions|add_roles|delete_roles|update_roles|assign_roles|revoke_roles",
      });
      list.push({
        title: "Billing",
        icon: "mdi-mailbox-open-outline",
        route: { name: "app-subscription" },
        exact: null,
        some: "update_subscription|manage_member_subscription",
        alias: [
          "app-subscription",
          "app-subscription-history",
          "app-update-information",
        ],
      });
      return list;
    },
  },
  data() {
    return {
      leftsidebar: null,
      borderLeftInactive: require("@/assets/border-left-inactive.svg"),
      borderLeftActive: require("@/assets/border-left-active.svg"),
    };
  },
  methods: {
    isActive(item) {
      if (item.hasOwnProperty("alias"))
        return item.alias.includes(this.$route.name);
      return this.$route.name === item.route.name;
    },
    hasChild(item) {
      return item.hasOwnProperty("children");
    },
    canShow(item) {
      if (item.hasOwnProperty("exact") && item.exact) {
        return this.$can.and(item.exact);
      }
      if (item.hasOwnProperty("some") && item.some) {
        return this.$can.or(item.some);
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer__content {
  background: #fff !important;
  color: #393939;
  border-right: 1px solid #e5e5e5 !important;
}
.v-navigation-drawer__border {
  width: 0px !important;
}

.sidebar-item {
  &.sidebar-item-active {
    &::before {
      border-left: 4px solid #7a38a3 !important;
    }
  }
}
</style>
