import Vue from "vue";
import Router from "vue-router";
import store from "@/stores/";
import permissions from "@/plugins/permissions";
import { homeRoutes } from "./routes/HomeRoutes";
import { appRoutes } from "./routes/AppDashboardRoutes";
import { adminRoutes } from "./routes/AdminDashboardRoutes";
import { testRoutes } from "./routes/TestRoutes";
import { environment } from "../environment";
import goTo from "vuetify/lib/services/goto";

Vue.use(Router);

const checkNested = (obj, level, ...rest) => {
  if (obj === undefined) return false;
  if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
  return checkNested(obj[level], ...rest);
};

const getNested = (obj, ...args) => {
  return args.reduce((obj, level) => obj && obj[level], obj);
};

const router = new Router({
  routes: [
    homeRoutes,
    adminRoutes,
    appRoutes,
    testRoutes,
    {
      path: "/insufficient-permissions",
      name: "NotEnoughPermission",
      component: () => import("@/views/PermissionDenied.vue"),
    },
    {
      path: "/*",
      name: "NotFound",
      component: () => import("@/views/NotFound.vue"),
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    // console.log(scrollTo)
    return goTo(scrollTo);
  },
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.authenticated;
  const authUser = store.getters.user;
  const isPaying = authUser?.is_paying;

  if (
    checkNested(to, "meta", "requiredModule") &&
    !environment.modules.includes(getNested(to, "meta", "requiredModule"))
  ) {
    return next({ name: "NotFound" });
  } else if (
    checkNested(to, "meta", "auth") &&
    isAuthenticated &&
    getNested(to, "meta", "auth") === false
  ) {
    return next({ name: "app-dashboard" });
  } else if (
    checkNested(to, "meta", "auth") &&
    !isAuthenticated &&
    getNested(to, "meta", "auth") === true
  ) {
    return next({
      name: "home",
      query: { login: true, redirect_uri: to.fullPath || "/" },
    });
  } else if (
    checkNested(to, "meta", "exact") &&
    isAuthenticated &&
    !permissions.and(getNested(to, "meta", "exact"))
  ) {
    return next({ name: "NotEnoughPermission" });
  } else if (
    checkNested(to, "meta", "some") &&
    isAuthenticated &&
    !permissions.or(getNested(to, "meta", "some"))
  ) {
    return next({ name: "NotEnoughPermission" });
  } else if (
    checkNested(to, "meta", "super_admin") &&
    isAuthenticated &&
    getNested(to, "meta", "super_admin") === true &&
    !permissions.is_super_admin()
  )
    return next({ name: "NotEnoughPermission" });
  else if (
    checkNested(to, "meta", "requiresPayingUser") &&
    isAuthenticated &&
    getNested(to, "meta", "requiresPayingUser") === true &&
    !isPaying
  )
    return next({ name: "NotEnoughPermission" });
  else next();
});

export default router;
