<template>
  <v-sheet
    v-bind="$attrs"
    class="xp-[5px] xmin-h-[150px] xflex xflex-col xitems-center xjustify-center"
  >
    <div class="xgrid xgrid-cols-3 xgap-x-[5px] xjustify-center xitems-center">
      <div class="xflex xflex-col xh-full">
        <v-btn
          @click="downHour('hour')"
          block
          text
          :disabled="!canAddHour"
          elevation="0"
          class="xflex xflex-col xjustify-center xitems-center"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>

        <div class="xflex xh-[50px] xflex-col xjustify-center xitems-center">
          <label>{{ displayHour }}</label>
        </div>
        <v-btn
          @click="upHour('hour')"
          block
          text
          :disabled="!canSubHour"
          elevation="0"
          class="xflex xflex-col xjustify-center xitems-center"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>

      <div class="xflex xflex-col xh-full">
        <v-btn
          @click="downMinute('minute')"
          block
          text
          :disabled="!canAddMinute"
          elevation="0"
          class="xflex xflex-col xjustify-center xitems-center"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <div class="xflex xh-[50px] xflex-col xjustify-center xitems-center">
          <label>{{ displayMinute }}</label>
        </div>
        <v-btn
          @click="upMinute('minute')"
          block
          text
          :disabled="!canSubMinute"
          elevation="0"
          class="xflex xflex-col xjustify-center xitems-center"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>

      <div
        class="xflex xflex-col xgap-y-[5px] xh-full xjustify-center xitems-center"
      >
        <v-btn
          @click="setAM('ampm')"
          id="custom-vbtn"
          :disabled="!canSetAM"
          :color="displayAmPm == 'AM' ? `primary` : 'grey'"
          elevation="0"
          text
          :class="{ 'active-disabled': !canSetAM && displayAmPm == 'AM' }"
          class="xfont-semibold"
        >
          AM
        </v-btn>
        <v-btn
          @click="setPM('ampm')"
          id="custom-vbtn"
          :disabled="!canSetPM"
          :color="displayAmPm == 'PM' ? `primary` : 'grey'"
          elevation="0"
          text
          :class="{ 'active-disabled': !canSetPM && displayAmPm == 'PM' }"
          class="xfont-semibold"
        >
          PM
        </v-btn>
      </div>
    </div>
    <!-- <div class="xcol-span-3"> -->
    <!-- <pre>value: {{ value }}</pre> -->
    <!-- <pre>final: {{ time }}</pre> -->
    <!-- <pre>base: {{ baseDate | format("ll") }}</pre> -->
    <!-- <pre>min: {{ minDatetime | format("lll") }}</pre> -->
    <!-- <pre>max: {{ maxDatetime | format("lll") }}</pre> -->
    <!-- <pre>datetime: {{ datetime | format("lll") }}</pre> -->
    <!-- <pre>AMPM: {{ displayAmPm }}</pre> -->
    <!-- </div>   -->
  </v-sheet>
</template>

<script>
import moment from "moment";
export default {
  props: {
    value: { type: String, default: "00:00:00" }, // HH:mm:ss
    baseDate: { type: String, default: null }, // YYYY-MM-DD
    min: { type: String, default: null }, // YYYY-MM-DD HH:mm:ss
    max: { type: String, default: null }, // YYYY-MM-DD HH:mm:ss
  },
  data() {
    return {
      format1: "HH:mm:ss",
      format2: "hh:mm A",
      format3: "YYYY-MM-DD HH:mm:ss",
      time: "00:00:00",
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.time = val;
      },
      immediate: true,
    },
    time: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    baseDate: {
      handler: function (val) {
        val && this.autoAdjustTimeByBaseDate();
      },
      immediate: true,
    },
  },
  filters: {
    prefix(val) {
      if (typeof val === undefined) return "";
      return `${val}`.length === 2 ? val : `0${val}`;
    },
  },
  computed: {
    datetime() {
      if (!this.time) return null;
      else if (!this.baseDate) return null;
      return moment(`${this.baseDate} ${this.time}`, this.format3).seconds(0);
    },
    maxDatetime() {
      return this.max ? moment(this.max, this.format3).seconds(0) : null;
    },
    minDatetime() {
      return this.min ? moment(this.min, this.format3).seconds(0) : null;
    },
    displayHour() {
      return this.time ? moment(this.time, this.format1).format("hh") : null;
    },
    displayMinute() {
      return this.time ? moment(this.time, this.format1).format("mm") : null;
    },
    displayAmPm() {
      return this.time ? moment(this.time, this.format1).format("A") : null;
    },
    canAddHour() {
      if (!this.maxDatetime || !this.datetime) return true;
      return (
        this.datetime.clone().add(1, "hour").minute(0) <=
        this.maxDatetime.clone().minute(0)
      );
    },
    canSubHour() {
      if (!this.minDatetime || !this.datetime) return true;
      return (
        this.datetime.clone().subtract(1, "hour").minute(0) >=
        this.minDatetime.clone().minute(0)
      );
    },
    canAddMinute() {
      if (!this.maxDatetime || !this.datetime) return true;
      return this.datetime.clone().add(1, "minute") <= this.maxDatetime.clone();
    },
    canSubMinute() {
      if (!this.minDatetime || !this.datetime) return true;
      return (
        this.datetime.clone().subtract(1, "minute") >= this.minDatetime.clone()
      );
    },
    canSetAM() {
      if (!this.minDatetime || !this.datetime) return true;
      return this.getAmPmInRange(
        this.minDatetime.clone(),
        this.datetime.clone()
      ).includes("AM");
    },
    canSetPM() {
      if (!this.maxDatetime || !this.datetime) return true;
      return this.getAmPmInRange(
        this.datetime.clone(),
        this.maxDatetime.clone()
      ).includes("PM");
    },
    adjustedValForAM() {
      let time = moment(this.time, this.format1).subtract(12, "hour");
      if (!this.minDatetime) return time.format(this.format1);
      else if (time <= this.minDatetime)
        return this.minDatetime.clone().format(this.format1);
      else return time.format(this.format1);
    },
    adjustedValForPM() {
      let time = moment(this.time, this.format1).add(12, "hour");
      if (!this.maxDatetime) return time.format(this.format1);
      else if (time >= this.maxDatetime)
        return this.maxDatetime.clone().format(this.format1);
      else return time.format(this.format1);
    },
    adjustedValForSubHour() {
      let time = moment(this.datetime, this.format1).subtract(1, "hour");
      if (!this.minDatetime) return time.format(this.format1);
      else if (time <= this.minDatetime)
        return this.minDatetime.clone().format(this.format1);
      else return time.format(this.format1);
    },
    adjustedValForAddHour() {
      let time = moment(this.datetime, this.format1).add(1, "hour");
      if (!this.maxDatetime) return time.format(this.format1);
      else if (time >= this.maxDatetime)
        return this.maxDatetime.clone().format(this.format1);
      else return time.format(this.format1);
    },
  },
  methods: {
    downHour(where) {
      this.time = this.adjustedValForAddHour;
    },
    upHour(where) {
      this.time = this.adjustedValForSubHour;
    },
    downMinute(where) {
      this.time = moment(this.time, this.format1)
        .add(1, "minute")
        .format(this.format1);
    },
    upMinute(where) {
      this.time = moment(this.time, this.format1)
        .subtract(1, "minute")
        .format(this.format1);
    },
    setAM(where) {
      if (this.displayAmPm === "PM") {
        this.time = this.adjustedValForAM;
      }
    },
    setPM(where) {
      if (this.displayAmPm === "AM") {
        this.time = this.adjustedValForPM;
      }
    },
    getAmPmInRange(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(currentDate.clone().format("A"));
        currentDate = moment(currentDate).add(1, "hour");
      }
      return [...new Set(dateArray)];
    },
    autoAdjustTimeByBaseDate() {
      if (this.minDatetime && this.datetime < this.minDatetime) {
        this.time = this.minDatetime.clone().format(this.format1);
      } else if (this.maxDatetime && this.datetime > this.maxDatetime) {
        this.time = this.maxDatetime.clone().format(this.format1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
  font-size: 20px;
  line-height: 3em;
  color: #6a2c91;
}
#custom-vbtn {
  &.v-btn--disabled {
    pointer-events: none !important;
    cursor: not-allowed !important;

    &.active-disabled {
      background-color: #6a2c91 !important;
      color: #fff !important;
    }
  }
}
</style>
