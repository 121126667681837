<template>
  <v-footer padless color="#ffffff" :height="mdUp ? 100 : 200">
    <div
      class="xflex xtext-primary md:xgap-2 xgap-5 md:xflex-row xflex-col xw-full xjustify-between xitems-center xcontainer xmx-auto"
    >
      <div class="xtext-[16px] xtext-[#000] xfont-[400] xleading-[19px]">
        © {{ year }} {{ companyName }}. All Rights Reserved.
      </div>
      <div
        class="xflex xflex-row xgap-[1em] md:xjustify-end xjustify-center md:xflex-nowrap xflex-wrap xitems-center"
      >
        <a
          class="hover:!xtext-[#7334A5] xcursor-pointer xtext-[16px] !xtext-[#000] xfont-[400] xleading-[19px]"
          target="_blank"
          href="https://www.mesasix.com/contact/"
        >
          Contact
        </a>
        <span
          class="hover:!xtext-[#7334A5] xcursor-pointer xtext-[16px] xtext-[#000] xfont-[400] xleading-[19px]"
          @click="$router.push({ name: 'privacy-policy' })"
        >
          Privacy Policy
        </span>
        <span
          class="hover:!xtext-[#7334A5] xcursor-pointer xtext-[16px] xtext-[#000] xfont-[400] xleading-[19px]"
          @click="$router.push({ name: 'terms' })"
        >
          Terms and Condition
        </span>
        <a target="_blank" href="https://www.mesasix.com/">
          <div
            class="xflex xflex-row xjustify-center xitems-center hover:!xtext-[#7334A5] xcursor-pointer xtext-[#000] xfont-[400]"
          >
            <span class="xleading-[22px] xtext-[18px]">Powered by </span>
            <v-img
              contain
              class="xh-[20px] xw-[95px]"
              :src="mesasixLogo"
              alt="Logo"
            ></v-img>
          </div>
        </a>
      </div>
    </div>
  </v-footer>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    year() {
      return moment().get("year");
    },
    mesasixLogo() {
      return require("@/assets/mesasix.svg");
    },
  }, 
};
</script>

<style lang="scss" scoped></style>
