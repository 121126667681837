<template>
  <v-autocomplete
    hide-details="auto"
    dense
    v-model="selected"
    outlined
    item-text="fullname"
    item-value="name"
    v-bind="$attrs"
    append-icon="mdi-chevron-down"
    prepend-inner-icon="mdi-map-clock-outline"
    :items="timezones"
    :return-object="returnObject"
  >
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  inheritAttrs: false,
  props: {
    value: { type: String, default: undefined },
    returnObject: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: moment.tz.guess(),
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val || moment.tz.guess();
      },
      immediate: true,
      deep: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters("appconfigs", ["timezones"]),
  },
};
</script>

<style lang="scss" scoped></style>
