<template>
  <v-card flat min-height="100vh" class="xp-[1em]" color="transparent">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">App Database</h1>
    </v-card-title>
    <v-card flat class="xpy-[1em]">
      <v-card-text>
        <v-chart autoresize class="chart" :option="options" />
        <div
          class="xflex xflex-row xjustify-between xitems-center"
          v-if="isbackup_enabled"
        >
          <h1 class="xfont-bold xtext-[30px] xmy-[1em]">Backups</h1>
          <btn-tooltip
            color="primary"
            :loading="processing"
            :disabled="processing"
            @click="doBackup"
            tip="Although backup are automatically done, you can still manually create backup."
          >
            Create Backup
          </btn-tooltip>
        </div>
        <app-table
          v-if="isbackup_enabled"
          :loading="false"
          :headings="headers"
          :items="backups"
        ></app-table>
        <v-alert type="info" class="xtext-gray-700">
          <span class="xtext-[#000]">
            Manual backup are done via Serverless MySQL.
          </span>
        </v-alert>
      </v-card-text>
      <!-- <pre>{{ backups }}</pre> -->
    </v-card>
  </v-card>
</template>

<script>
import _ from "lodash";
import { THEME_KEY } from "vue-echarts";
export default {
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      items: [],
      backups: [],
      isbackup_enabled: false,
      processing: false,
      tabs: [{ name: "Database", to: { name: "admin-database" } }],
      headers: [
        { text: "Date", value: "datetime_formatted", sortable: true },
        { text: "File Name", value: "filename", sortable: true },
        { text: "File Extension", value: "extension", sortable: true },
        { text: "File Size", value: "filesize", sortable: true },
      ],
    };
  },
  computed: {
    options() {
      return {
        title: {
          text: "Database Tables",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.titles,
        },
        series: [
          {
            name: "Statistics",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: this.items,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    titles() {
      if (!this.items || !this.items.length) return [];
      return this.items.map((i) => i.name);
    },
  },
  created() {
    this.fetchItems();
    // this.fetchBackups();
  },
  methods: {
    fetchBackups() {
      this.processing = true;
      this.$axios
        .get(`admin/databases/backups`)
        .then(({ data }) => {
          this.backups = data;
        })
        .finally(() => (this.processing = false));
    },
    fetchItems() {
      this.$axios.get(`admin/databases`).then(({ data }) => {
        this.items = data;
      });
    },
    doBackup() {
      this.processing = true;
      this.$axios
        .post(`admin/databases/backups`, {})
        .then(({ data }) => {
          this.appToast(data.message, "success");
          this.fetchBackups();
        })
        .finally(() => (this.processing = false));
    },
  },
};
</script>

<style lang="css" scoped>
.chart {
  height: 100vh;
  width: 100%;
}
</style>
