<template>
  <v-card
    color="transparent"
    min-height="100vh"
    flat
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Team Records</h1>
    </v-card-title>
    <router-view :key="$route.fullPath"></router-view>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      count: 0,
      defaultTab: null,
    };
  },
  created() {
    this.$event.$on("team-record-count", (count) => (this.count = count));
  },
};
</script>

<style lang="scss" scoped></style>
