<template>
    <v-card>
        How To
    </v-card>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>