<template>
  <v-card v-bind="$attrs" flat color="#E5E5E5">
    <v-card-title
      class="xflex flex-row xjustify-between xitems-center xbg-[#E5E5E5]"
    >
      <span class="xfont-[700] xtext-[22px] xtext-[#000]">
        Projects
        <v-chip small color="#dcdcdc" class="xfont-[900]">
          {{ counts }}
        </v-chip>
      </span>
      <router-link :to="projectPage">
        <span class="xtext-[14px] xfont-[600] hover:xtext-[#000000]">
          View All
        </span>
      </router-link>
    </v-card-title>
    <v-card-text
      class="xbg-[#fff] xborder-[1px] !xmin-h-[600px] xborder-gray-100 !xp-[1em] xrounded-[10px]"
    >
      <app-table
        :loading="loading"
        empty-class="!xh-[600px]"
        :headings="headers"
        :items="projects"
      >
        <template v-slot:actions="{ item }">
          <icon-list icon="mdi-dots-horizontal">
            <v-list dense min-width="200">
              <v-list-item @click="navigateProject(item)">
                <v-list-item-title class="xtext-[#2E2E2E]">View</v-list-item-title>
              </v-list-item>
            </v-list>
          </icon-list>
        </template>
        <template v-slot:total_time_spent="{ item }">
          <span>{{ item.summary ? item.summary.total_human : "0m" }}</span>
        </template>
        <template v-slot:name="{ item }">
          <span>{{ item.name | ucwords }}</span>
        </template>
        <template v-slot:users="{ item }">
          <avatars size="40" :users="item.summary.users" :count="8"></avatars>
        </template>
      </app-table>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  data() {
    return {
      projectPage: {
        name: "app-project-report-daily",
        params: { date: moment().format("YYYY-MM-DD") },
      },
      limit: 10,
      loading: false,
      projects: [],
      headers: [
        {
          text: "Project Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Total Time Spent",
          value: "total_time_spent",
          sortable: false,
          align: "center",
          tdClass: "xtext-center",
        },
        {
          text: "Users",
          value: "users",
          sortable: false,
          tdClass: "xtext-left",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right",
          tdClass: "xtext-right",
        },
      ],
    };
  },
  computed: {
    counts() {
      return this.projects.length;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    navigateProject(item) {
      this.$router.push({
        name: "app-project-monthly",
        params: { year_month: moment().format("YYYY-MM"), project_id: item.id },
      });
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get(`api/projects/dashboard?limit=${this.limit}`)
        .then(({ data }) => {
          this.projects = data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
