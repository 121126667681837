<template>
  <div
    id="pricing"
    class="xbg-[#ffffff] xw-full xblock md:xh-[100vh] xh-full xmin-h-[900px]"
  >
    <div
      :class="{ '!xmax-w-[90%]': tablet }"
      class="xflex xflex-col xw-full xcontainer xmx-auto xpb-2 py-8 md:xpt-[4em]"
      :style="
        tablet || smDown
          ? {}
          : {
              background: `url(${centerBottom}) center bottom 50px no-repeat`,
            }
      "
    >
      <div
        class="xflex xflex-col xw-full xpy-[2em] xpx-[2em] xjustify-center xitems-center"
      >
        <h2 class="pricing-label xtracking-[0.43em]">PRICING</h2>
        <h1 class="pricing-plan">Pricing Plans</h1>
        <p class="pricing-description md:xw-6/12 xmx-auto xmy-2">
          We accept all major credit cards including Visa, MasterCard, American
          Express, Discover, JCB, and Diners Club. Debit cards with the Visa or
          MasterCard logo are also accepted.
        </p>
      </div>
      <div
        class="xflex xflex-col light--text md:xflex-row md:xitems-start xitems-center md:xjustify-center xw-full md:xpx-10 xpy-6"
      >
        <article
          :class="[smDown ? 'xrounded-lg' : '']"
          class="xw-4/5 md:xw-[31%] xmb-10 xpy-10 xtext-start xp-[2em] xbg-[#EAEAEA] xz-[1]"
        >
          <h5 class="pricing-label md:!xtext-left xtext-center">Team Trial</h5>
          <h2
            class="xfont-bold xpb-4 xmt-2 xflex md:xjustify-start xjustify-center"
          >
            <span
              :class="[tablet ? 'xtext-[39px]' : 'xtext-[42px]']"
              class="xtext-[#595959] xleading-[51px]"
            >
              $0/month
            </span>
          </h2>
          <p class="xtext-[#595959]">
            {{ app_trial_days || 7 }}-day free trial period
          </p>
          <ul
            class="xtext-[18px] xleading-[22px] xtext-left xtext-[#595959] xfont-[400]"
          >
            <li>
              <v-icon color="#000" left>mdi-check-circle</v-icon> Disable After
              {{ app_trial_days || 7 }} Days
            </li>
            <li>
              <v-icon color="#000" left>mdi-check-circle</v-icon> Team Time
              Checker
            </li>
            <li>
              <v-icon color="#000" left>mdi-check-circle</v-icon> Dashboard for
              Reports
            </li>
            <li>
              <v-icon color="#000" left>mdi-check-circle</v-icon> Team Time
              Tracker
            </li>
          </ul>
          <v-btn
            v-if="$auth.isAuthenticated()"
            depressed
            color="base_100"
            large
            block
            height="60"
            class="mt-5 text-none xfont-bold xtext-[#a240e3] px-10 xcursor-not-allowed xz-[1] !xtext-[18px] xrounded-[8px]"
          >
            Cannot Downgrade
          </v-btn>
          <v-btn
            v-else
            depressed
            color="primary"
            large
            block
            @click="$router.push({ name: 'install-app' })"
            height="60"
            class="mt-5 text-none xtracking-normal xpy-[22px] xpx-[24px] !xtext-[18px] xrounded-[8px] xfont-bold"
          >
            Try For Free!
          </v-btn>
        </article>
        <article
          class="md:xw-[31%] xw-4/5 xmb-10 xpx-[2em] xpt-[2em] xpb-[1em] md:x-mt-8 xtext-white xbg-[#000] xtext-start !xz-[2]"
        >
          <h5 class="pricing-label md:!xtext-left xtext-center">Team Basic</h5>
          <h2
            class="xfont-bold xpb-4 xmt-2 xflex md:xjustify-start xjustify-center"
          >
            <span
              :class="[tablet ? 'xtext-[35px]' : 'xtext-[42px]']"
              class="xtext-[#fff] xleading-[51px]"
            >
              $2/member*
            </span>
          </h2>
          <p>Monthly payment plan</p>
          <ul
            class="xtext-[18px] xleading-[22px] xtext-left xtext-[#dedede] xfont-[400]"
          >
            <li>
              <v-icon color="#fff" left>mdi-check-circle</v-icon> Monthly Setup
            </li>
            <li>
              <v-icon color="#fff" left>mdi-check-circle</v-icon> Team Time
              Checker
            </li>
            <li>
              <v-icon color="#fff" left>mdi-check-circle</v-icon> Dashboard for
              Reports
            </li>
            <li>
              <v-icon color="#fff" left>mdi-check-circle</v-icon> Team Time
              Tracker
            </li>
          </ul>
          <v-btn
            depressed
            large
            block
            @click="handleCheckout"
            color="primary"
            height="60"
            class="mt-5 text-none xtracking-normal xpy-[22px] xpx-[24px] !xtext-[18px] xrounded-[8px] xfont-bold"
          >
            Subscribe
          </v-btn>
          <p class="xmt-[3em] xitalic light--text">
            *select members in team settings
          </p>
        </article>
        <article
          :class="[smDown ? 'xrounded-lg' : '']"
          class="xw-4/5 md:xw-[31%] xmb-10 xpy-10 xtext-start xp-[2em] xbg-[#EAEAEA] xbg-opacity-50"
        >
          <h5 class="pricing-label md:!xtext-left xtext-center">
            Team Premium
          </h5>
          <h2
            class="xfont-bold xpb-4 xmt-2 xflex md:xjustify-start xjustify-center"
          >
            <span
              :class="[tablet ? 'xtext-[39px]' : 'xtext-[42px]']"
              class="xtext-[#595959] xleading-[51px]"
            >
              SOON
            </span>
          </h2>
          <p class="xtext-[#595959]">Monthly payment plan</p>
          <ul
            class="xtext-[18px] xleading-[22px] xtext-left xtext-[#595959] xfont-[400]"
          >
            <li>
              <v-icon color="#dddddd" left>mdi-check-circle</v-icon> Monthly
              Setup
            </li>
            <li>
              <v-icon color="#dddddd" left>mdi-check-circle</v-icon> More
              Options
            </li>
            <li>
              <v-icon color="#dddddd" left>mdi-check-circle</v-icon> More
              Features
            </li>
            <li>
              <v-icon color="#dddddd" left>mdi-check-circle</v-icon> More ...
            </li>
          </ul>
          <v-btn
            depressed
            large
            block
            height="60"
            class="mt-5 text-none xtracking-normal xpy-[22px] xpx-[24px] !xtext-[18px] !xtext-[#fff] xcursor-not-allowed xrounded-[8px] xfont-bold !xbg-[#D2D2D2]"
          >
            Upgrade Soon
          </v-btn>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("appconfigs", ["app_trial_days"]),
  },
  data() {
    return {
      windowH: 0,
      centerBottom: require("@/assets/covers/pricing-cb.svg"),
    };
  },
  methods: {
    handleCheckout() {
      if (!this.$auth.isAuthenticated()) {
        this.$router.push({ name: "install-app" });
      } else if (this.$can.and("update_subscription"))
        this.$router.push({ name: "checkout" });
      else
        this.appNotification(
          "You dont have capabilities to upgrade your team subscription. Please ask your Slack admins/owner to do this."
        );
    },
    onResize() {
      this.windowH = window.innerHeight;
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style lang="scss" scoped>
#pricing {
  .pricing-label {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.43em;
    color: #a240e3;
  }
  .pricing-plan {
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    color: #000000;
  }
  .pricing-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: #616161;
  }
  ul {
    padding: 0 !important;

    li {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      line-height: 22px;
    }
  }
}
</style>
