<template>
  <div
    v-bind="$attrs"
    class="xw-full xmb-[5px] xflex xgap-[5px] xpy-[5px] md:xflex-row xflex-col xjustify-start xitems-stretch"
  >
    <div class="md:xw-4/12 xw-full">
      <KirbyDatePicker v-model="date" display-format="ll"></KirbyDatePicker>
    </div>
    <div class="md:xw-3/12 xw-full">
      <TimeRangePicker v-model="range"></TimeRangePicker>
    </div>
    <div class="md:xw-3/12 xw-full">
      <KirbyTimePickerSelect
        v-model="time"
        :base-date="date"
        :disabled="!date"
        has-buttons
      ></KirbyTimePickerSelect>
    </div>
    <div
      class="md:xw-2/12 xw-full xflex xflex-row xgap-x-[0.5em] xpx-[1em] xjustify-end xitems-center"
    >
      <icon-tooltip
        clickable
        color="#2E2E2E40"
        v-if="duplicable"
        @click="$emit('duplicate')"
        icon="mdi-content-duplicate mdi-flip-h"
        tip="Duplicate row"
      ></icon-tooltip>
      <icon-tooltip
        v-if="deletable"
        clickable
        color="#2E2E2E40"
        @click="$emit('remove')"
        icon="mdi-trash-can-outline"
        tip="Remove row"
      ></icon-tooltip>
      <v-icon color="#2E2E2E40" v-else>mdi-delete-off-outline</v-icon>
    </div>
  </div>
</template>

<script>
import KirbyDatePicker from "./KirbyDatePicker.vue";
import ProjectSelect from "./ProjectSelect.vue";
import moment from "moment";
import TimeRangePicker from "./TimeRangePicker.vue";
import KirbyTimePickerSelect from "./KirbyTimePickerSelect.vue";
export default {
  inheritAttrs: false,
  components: {
    KirbyDatePicker,
    ProjectSelect,
    TimeRangePicker,
    KirbyTimePickerSelect,
  },
  props: {
    deletable: { type: Boolean, default: false },
    duplicable: { type: Boolean, default: false },
    previous: { type: Object, default: null },
    next: { type: Object, default: null },
    value: {
      type: Object,
      default: () => {
        return {
          date: moment().format("YYYY-MM-DD"),
          range: "08:00:00",
          time: "00:00:00",
        };
      },
    },
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      range: "08:00:00",
      time: "00:00:00",
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.date = val.date || moment().format("YYYY-MM-DD");
        this.range = val.range || "08:00:00";
        this.time = val.time || "00:00:00";
      },
      immediate: true,
    },
    date: {
      handler: function (val) {
        this.$emit("input", this.payload);
      },
      immediate: true,
    },
    range: {
      handler: function (val) {
        this.$emit("input", this.payload);
      },
      immediate: true,
    },
    time: {
      handler: function (val) {
        this.$emit("input", this.payload);
      },
      immediate: true,
    },
  },
  computed: {
    payload() {
      return {
        date: this.date,
        range: this.range,
        time: this.time,
        valid: this.valid,
      };
    },
    valid() {
      if (!this.date || !this.range || !this.time) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
