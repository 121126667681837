import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";
import { environment } from '@/environment'

Vue.use(Vuex)

const ls = new SecureLS({ isCompression: false, encodingType: 'rc4' });
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((modules, [name, mod]) => {

        let module = mod.default || mod

        if (module.namespaced === undefined) {
            module.namespaced = true
        }

        return { ...modules, [name]: module }
    }, {});

export default new Vuex.Store({
    state: {
        user: null,
        token: null,
        newInstall: false,
        step1: false,
        step2: false
    },
    getters: {
        user: (state) => state.user,
        token: (state) => state.token,
        newInstall: (state) => state.newInstall,
        authenticated: (state) => !!(state.user && state.token),
        step1: (state) => state.step1,
        step2: (state) => state.step2,
    },
    mutations: {
        set_newInstall: (state, payload) => {
            state.newInstall = payload;
        },
        set_step1: (state, payload) => {
            state.step1 = payload;
            if (!state.step1 && !state.step2) {
                state.newInstall = false;
            }
        },
        set_step2: (state, payload) => {
            state.step2 = payload;
            if (!state.step1 && !state.step2) {
                state.newInstall = false;
            }
        },
        set_user: (state, payload) => (state.user = payload),
        set_token: (state, payload) => (state.token = payload),
        clear_auth: (state) => {
            state.token = null;
            state.user = null
        }
    },
    actions: {},
    modules: modules,
    plugins: [createPersistedState({
        paths: ['user', 'token', 'permissions', 'newInstall', 'step1', 'step2'],
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
    })],
    strict: environment.developer_mode
})