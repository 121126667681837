<template>
  <div class="xflex xoverflow-hidden" v-if="!withName">
    <v-tooltip top color="primary">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          rounded
          color="transparent"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="on"
        >
          <v-img contain :src="user.image_url" :alt="user.real_name" />
        </v-avatar>
      </template>
      <span>{{ user.real_name }}</span>
    </v-tooltip>
  </div>
  <div
    v-else
    class="xflex xoverflow-hidden xgap-x-2 xflex-row xjustify-start xitems-center"
  >
    <v-avatar rounded color="transparent" v-bind="$attrs">
      <v-img contain :src="user.image_url" :alt="user.real_name" />
    </v-avatar>
    <div
      class="xfont-[400] xtext-[15px] xleading-[20px] xtext-[#000]"
      v-html="user.real_name"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  props: {
    user: { type: Object, default: undefined },
    withName: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.xavatar {
  // border: 2px solid #fff;
  border: none !important;
  background-color: #393939;
  color: #fff;
}
</style>
