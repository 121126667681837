<template>
  <div class="xflex xflex-col xw-full">
    <v-divider></v-divider>
    <v-row justify="space-between" align="center" class="xpx-[1em]" no-gutters>
      <v-col :cols="1" v-if="hasDate"></v-col>
      <v-col
        :cols="hasDate ? (hasDelete ? 10 : 11) : 11"
        class="xflex w-full xflex-col xflex-nowrap"
      >
        <div class="xflex xflex-row xjustify-center xitems-center">
          <div
            class="time-header xpy-[5px] xw-full xjustify-center xitems-center"
            v-for="(timeHead,index) in timeHeaders"
            :key="index"
            v-text="timeHead"
          ></div>
        </div>
      </v-col>
      <v-col v-if="hasDelete" cols="1"></v-col>
    </v-row>
    <v-row justify="space-between" align="center" no-gutters class="xpx-[1em]">
      <v-col
        :cols="1"
        v-if="hasDate"
        class="xflex xflex-col xjustify-center xtext-center xfont-semibold xitems-center"
      >
        {{ startDay }}
        <v-chip
          label
          small
          outlined
          class="xml-[10px]"
          color="red"
          v-if="entry.summary.is_pto"
        >
          PTO
        </v-chip>
      </v-col>
      <v-col :cols="hasDate ? (hasDelete ? 10 : 11) : 12">
        <div class="xflex xflex-row xbg-[#dddddd] xjustify-start xitems-center">
          <EachBlock
            v-for="(block, index) in entry.summary.breakdown"
            :key="index"
            :block="block"
            :has-edit-block="hasEdit"
            @edit-block="$emit('edit-block', block)"
          >
          </EachBlock>
        </div>
      </v-col>
      <v-col
        v-if="hasDelete"
        cols="1"
        class="xflex xflex-col xjustify-center xfont-semibold xitems-center"
      >
        <icon-tooltip
          clickable
          tip="Delete shift"
          @click="$emit('delete-shift')"
          color="error"
          icon="mdi-delete-alert"
        >
        </icon-tooltip>
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      align="center"
      no-gutters
      class="xpy-3 xpx-[1em]"
    >
      <v-col :cols="1" v-if="hasDate"></v-col>
      <v-col
        :cols="hasDate ? 11 : 12"
        class="xflex xflex-row xgap-3 xflex-wrap"
      >
        <v-chip
          v-for="(time, project) in entry.summary.projects"
          :key="project"
          label
        >
          {{ project }} ({{ time | secToHuman(" hrs", " mins") }})
        </v-chip>
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      align="center"
      no-gutters
      class="xpy-3 xpx-[1em]"
      v-if="entry.summary.edits.length"
    >
      <v-col :cols="1" v-if="hasDate"></v-col>
      <v-col
        :cols="hasDate ? 11 : 12"
        class="xflex xflex-row xgap-3 xflex-wrap"
      >
        <v-data-table
          hide-default-footer
          :headers="headers"
          dense
          class="xw-full xborder-[1px] xborder-[#dcd9d9]"
          disable-pagination
          disable-filtering
          disable-sort
          calculate-widths
          :items="entry.summary.edits"
          :items-per-page="500"
        >
          <template v-slot:[`item.command`]="{ item }">
            {{ item.command.toUpperCase() }}
          </template>
          <template v-slot:[`item.old_created_at`]="{ item }">
            {{ item.old_created_at | format("lll") }}
          </template>
          <template v-slot:[`item.new_created_at`]="{ item }">
            {{ item.new_created_at | format("lll") }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status || "Pending" | ucwords }}
          </template>
          <template v-slot:[`item.approvedBy`]="{ item }">
            {{ item.approved_by ? item.approved_by.real_name : "-" | ucwords }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <pre>{{ summary }}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
import EachBlock from "./subcomponents/EachBlock.vue";
export default {
  props: {
    entry: Object,
    hasDelete: { type: Boolean, default: true },
    hasEdit: { type: Boolean, default: true },
    hasDate: { type: Boolean, default: false },
  },
  data() {
    return {
      headers: [
        { text: "Command", value: "command" },
        { text: "Previous Time", value: "old_created_at" },
        { text: "New Time", value: "new_created_at" },
        { text: "Status", value: "status" },
        { text: "Manage By", value: "approvedBy" },
      ],
    };
  },
  computed: {
    timeHeaders() {
      return this.getTimeHeader(this.entry.summary.header, this.mobile);
    },
    startDay() {
      return moment(this.entry.range_from).format("MMM D");
    },
  },
  components: { EachBlock },
};
</script>

<style lang="scss" scoped>
.time-header {
  height: 35px;
  font-size: 13px;
  font-weight: 600;
  color: #6f6c71;
  text-align: center;
  overflow: visible;
  position: relative;
  float: left;
  display: flex;

  // &:nth-child(1) {
  // border-left: 1px solid #dcd9d9;
  // }

  &:after {
    content: "";
    position: absolute;
    top: 30px;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #dcd9d9;
  }
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
