<template>
  <v-card flat min-height="100vh" class="xp-[1em]" color="transparent">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Feedbacks</h1>
    </v-card-title>
    <v-card flat class="xpy-[1em] xmin-h-[90vh]">
      <v-card-text>
        <app-table :loading="loading" :headings="headers" :items="items">
          <template v-slot:user="{ item }">
            <avatar
              size="35"
              v-if="item.user"
              :user="item.user"
              with-name
            ></avatar>
            <span v-else>{{ item.user_id || "-" }}</span>
          </template>
          <template v-slot:user_id="{ item }">
            <clipboard :text="item.user_id"> </clipboard>
          </template>
          <template v-slot:email="{ item }">
            <clipboard :text="item.user.email" v-if="item.user"> </clipboard>
            <span v-else>-</span>
          </template>
          <template v-slot:team_id="{ item }">
            <span v-if="item.team">
              {{ item.team.name }}
            </span>
            <span v-else>{{ item.team_id || "-" }}</span>
          </template>
          <template v-slot:created_at="{ item }">
            <span>
              {{ item.created_at | format("LLL") }}
            </span>
          </template>
          <!-- <template v-slot:replied="{ item }">
          <v-icon color="primary">
            {{ item.replied ? "mdi-check" : "mdi-close" }}
          </v-icon>
        </template> -->
        </app-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      counts: 0,
      loading: false,
      loading_more: false,
      headers: [
        { text: "From", value: "user", sortable: true },
        { text: "ID", value: "user_id", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Team", value: "team_id", sortable: true },
        { text: "Message", value: "message", sortable: false },
        { text: "Date", value: "created_at", sortable: true },
      ],
      items: [],
    };
  },
  created() {
    this.fetchStats();
    this.fetch();
  },
  computed: {
    tabs() {
      return [
        {
          name: "Feedbacks",
          to: { name: "admin-feedbacks" },
          counts: this.counts,
        },
      ];
    },
  },
  methods: {
    fetchStats() {
      this.$axios.get(`admin/feedbacks/statistics`).then(({ data }) => {
        this.counts = data.feedbacks_count;
      });
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get(`admin/feedbacks`)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
