import request from '@/plugins/requests'
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        fetching: false,
        submiting: false,
        configs: null,
        auto_add_users: 0,
        channel: null,
        limit_per_break: 10,
        limit_per_shift: 16,
        notification_interval: 8,
        notification_type: 'off',
        required_clocking_purpose: 'optional',
        response_source: 'both',
        start_shift: 'off',
        end_shift: 'off',
        timezone: 'UTC',
        allow_user_create_project_tasks: 1,
        workspace_channels: []
    },
    getters: {
        fetching: (state) => state.fetching,
        submiting: (state) => state.submiting,
        configs: (state) => state.configs,
        workspace_channels: (state) => state.workspace_channels,
        auto_add_users: (state) => state.auto_add_users,
        channel: (state) => state.channel,
        limit_per_break: (state) => state.limit_per_break,
        limit_per_shift: (state) => state.limit_per_shift,
        notification_interval: (state) => state.notification_interval,
        notification_type: (state) => state.notification_type,
        required_clocking_purpose: (state) => state.required_clocking_purpose,
        response_source: (state) => state.response_source,
        start_shift: (state) => state.start_shift || 'off',
        end_shift: (state) => state.end_shift || 'off',
        timezone: (state) => state.timezone || 'UTC',
        allow_user_create_project_tasks: (state) => state.allow_user_create_project_tasks || 1,
        getByKey: state => (key, defaultVal) => {
            return state.hasOwnProperty(key) ? state[key] : defaultVal
        }
    },
    mutations: {
        cancel_updates: (state) => {
            for (const [key, value] of Object.entries(_.cloneDeep(state.configs))) {
                if (state.hasOwnProperty(key))
                    state[key] = value
            }
        },
        set_configs: (state, payload) => {
            state.configs = payload
            for (const [key, value] of Object.entries(payload)) {
                if (state.hasOwnProperty(key))
                    state[key] = value
            }
        },
        set_config: (state, { key, value }) => {
            if (state.hasOwnProperty(key))
                state[key] = value
        },
        set_submiting: (state, value) => (state.submiting = value),
        set_fetching: (state, value) => (state.fetching = value)
    },
    actions: {
        fetchWorkspaceConfigs: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/configs`)
                .then(({ data }) => {
                    commit('set_configs', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        updateWorkspaceConfigs: ({ commit }, { id, payload, cb }) => {
            commit('set_submiting', true)
            request.put(`api/workspaces/${id}/configs`, payload)
                .then(({ data }) => {
                    commit('set_configs', data)
                    if (typeof cb === 'function') cb(data)
                })
                .finally(() => commit('set_submiting', false))
        },
        updateWorkspaceConfig: ({ commit }, { id, payload, cb }) => {
            commit('set_submiting', true)
            request.put(`api/workspaces/${id}/config`, payload)
                .then(({ data }) => {
                    commit('set_configs', data)
                    if (typeof cb === 'function') cb(data)
                })
                .finally(() => commit('set_submiting', false))
        }
    }
}