<template>
  <v-card color="transparent" class="!xbg-transparent">
    <v-card-text class="xflex xflex-col xgap-y-[1em]">
      <div class="xmax-w-[1000px]">
        <p>
          <strong>
            PLEASE READ THIS POLICY CAREFULLY BEFORE USING
            {{ appName | uppercase }} SERVICES
          </strong>
        </p>

        <p>Last updated: May 25, 2018</p>

        <p>
          Please read this Privacy Policy carefully before using any
          {{ companyName }} services, including {{ appName }} or any other
          service, software, or product (the "Service") provided by
          {{ companyName }} ("us", "we", or "our").
        </p>

        <p>
          This Privacy Policy describes how and when {{ appName }} collects,
          uses and shares your information when you use our Services.
          {{ appName }} receives your information through the Slack APIs,
          buttons, widgets, and commerce services (the "Services" or "{{
            appName
          }}") and from our partners and other third parties. For example, you
          send us information when you use {{ appName }} from our website, or
          access {{ appName }} from an application. When using any of our
          Services you consent to the collection, transfer, manipulation,
          storage, disclosure and other uses of your information as described in
          this Privacy Policy. Irrespective of which country you reside in or
          supply information from, you authorize {{ appName }} to use your
          information in the United States and any other country where
          {{ companyName }} operates.
        </p>

        <p>
          Our Website contains links to third party websites. If you follow a
          link to any of those third party websites, please note that they have
          their own privacy policies and that we do not accept any
          responsibility or liability for their policies or processing of your
          personal information. Please check these policies before you submit
          any personal information to such third party websites.
        </p>

        <p>
          If you have questions or complaints regarding this privacy statement
          or our privacy practices, please contact us at
          {{ support_email }}.
        </p>

        <h3 class="xfont-[700] xmb-[5px]">Information Collection and Use</h3>

        <p><strong>Basic Account Information:</strong></p>
        <p>
          When you integrate {{ appName }} in your Slack team, the Slack API
          provides us information about your team and its members, specifically
          names, emails, usernames, timezones, profile pictures, team domains,
          and team names. This information is visible to the members of your
          Slack team and {{ companyName }}, but not anyone else.
        </p>

        <p><strong>Additional Information: </strong></p>
        <p>
          You may also provide us with information during your conversations
          with {{ appName }} on Slack and inside {{ appName }}'s slash commands.
          If you email us, we may keep your message, email address, and contact
          information to respond to your request. The additional information
          described in this section is also private.
        </p>

        <p><strong>Cookies: </strong></p>
        <p>
          Like many websites, we may use cookies and similar technologies to
          collect additional website usage data and to improve our Services, but
          we do not require cookies for many parts of our Services such as
          searching and looking at public user profiles. A cookie is a small
          data file that is transferred to your computer's hard disk.
          {{ appName }} may use both session cookies and persistent cookies to
          better understand how you interact with our Services, to monitor
          aggregate usage by our users and web traffic routing on our Services,
          and to customize and improve our Services. Most Internet browsers
          automatically accept cookies. You can instruct your browser, by
          changing its settings, to stop accepting cookies or to prompt you
          before accepting a cookie from the websites you visit. However, some
          Services may not function properly if you disable cookies.
        </p>

        <p>
          The following cookies are set automatically when you visit our
          Website:
        </p>

        <p>
          <em><strong>Third Party Cookies</strong></em>
        </p>

        Name: Hotjar<br />
        Domain: hotjar.com<br />
        For more information, please refer to the following link:
        https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies<br /><br />

        Name: Facebook Pixel<br />
        Domain: facebook.com<br />
        {{ appName }} uses Facebook pixel to help us track conversions from
        Facebook ads, optimize ads based on collected data, build targeted
        audiences for future ads, and remarket to qualified leads.<br /><br />

        Name: Mixpanel<br />
        Domain: mixpanel.com<br />
        {{ appName }} uses Mixpanel for analytics, it tracks user interactions
        with web and mobile applications and provides tools for targeted
        communication. Data collected is used to build custom reports and
        measure user engagement and retention.<br /><br />

        <p><strong>Log Data: </strong></p>
        <p>
          When you use our Services, we may receive information such as your IP
          address, browser type, operating system, the referring web page, pages
          visited, location, your mobile carrier, device information (including
          device and application IDs), search terms, and cookie information
          ("Log Data"). We receive Log Data when you interact with our Services,
          for example, when you visit our website, sign into our Services,
          interact with our emails or interact with {{ appName }} on Slack. We
          may also receive Log Data when you click on, view or interact with a
          link on our Services to a third-party application, such as when you
          choose to install another application through {{ appName }}.
          {{ appName }}
          uses Log Data to provide, understand, and improve our Services.
        </p>

        <p><strong>Third-Parties: </strong></p>
        <p>
          {{ appName }} uses a variety of third-party services to help provide
          our Services, such as hosting our site and blog, and to help us
          understand and improve the use of our Services, such as Hotjar. These
          third-party service providers may collect information sent by your
          browser as part of a web page request, such as cookies or your IP
          address. Third-party ad partners may share information with us, like a
          browser cookie ID, website URL visited, mobile device ID, or
          cryptographic hash of a common account identifier (such as an email
          address).
        </p>

        <h3 class="xfont-[700] xmb-[5px]">Information Storage</h3>

        <p>
          When you integrate {{ appName }} in your Slack team, the Slack API
          provides us information about your team and its members. Your
          information specifically your name, email, username, timezone, profile
          pictures, team domain, and team name is saved in our database.
        </p>

        <p>
          We do not store your password, Slack handles the authentication when
          you sign in to our dashboard. We do not disclose your account details,
          postal or email addresses to anyone except when legally required to do
          so. It is your responsibility to keep your Slack password secure.
        </p>

        <p><strong>How long we retain your personal data</strong></p>

        <p>
          We keep you personal information even when you unsubscribe to our plan
          or when your free trial expires. However, the information in our
          database will no longer be updated if your team has unsubscribed or
          deleted {{ appName }} in your Slack App directory.
        </p>

        <h3 class="xfont-[700] xmb-[5px]">
          Information Sharing and Disclosure
        </h3>

        <p><strong>Your Consent: </strong></p>
        <p>
          We may share or disclose your information at your direction, such as
          when you authorize a third-party web client or application to access
          your {{ appName }} account.
        </p>

        <p><strong>Service Providers:</strong></p>
        <p>
          We engage service providers to perform functions and provide services
          to us in the United States and abroad. We may share your private
          personal information with such service providers subject to
          confidentiality obligations consistent with this Privacy Policy, and
          on the condition that the third parties use your private personal data
          only on our behalf and pursuant to our instructions.
        </p>

        <p><strong>Law and Harm: </strong></p>
        <p>
          Notwithstanding anything to the contrary in this Policy, we may
          preserve or disclose your information if we believe that it is
          reasonably necessary to comply with a law, regulation or legal
          request; to protect the safety of any person; to address fraud,
          security or technical issues; or to protect {{ appName }}'s rights or
          property. However, nothing in this Privacy Policy is intended to limit
          any legal defenses or objections that you may have to a third party’s,
          including a government’s, request to disclose your information.
        </p>

        <p><strong>Business Transfers and Affiliates: </strong></p>
        <p>
          In the event that {{ appName }} is involved in a bankruptcy, merger,
          acquisition, reorganization or sale of assets, your information may be
          sold or transferred as part of that transaction. This Privacy Policy
          will apply to your information as transferred to the new entity. We
          may also disclose information about you to our corporate affiliates in
          order to help provide, understand, and improve our Services and our
          affiliates’ services.
        </p>

        <p><strong>Non-Private or Non-Personal Information: </strong></p>
        <p>
          We may share or disclose your non-private, aggregated or otherwise
          non-personal information after we have removed any private personal
          information (such as your name or your company name).
        </p>

        <p><strong>Disclosure of your information:</strong></p>
        <p>
          We may disclose your personal information to potential employers and
          agents, as well as to our service providers and business partners to
          assist us in performing any contract we enter into with them or you,
          including providing the Website and the Services it enables, analytics
          providers (to assist us in the improvement and optimisation of the
          Website and Services) and/or a member of our group, which means our
          subsidiaries, our ultimate holding company and its subsidiaries, as
          defined in section 1159 of the UK Companies Act 2006. A list of such
          third parties and agents is as follows:
        </p>
        <ul class="!xlist-disc">
          <li>
            <i class="fa fa-hand-o-right"></i> Amazon Web Services: Hosting
            services.
          </li>
          <li>
            <i class="fa fa-hand-o-right"></i> Boomtrain: a customer messaging
            service used by us to provide customer support.
          </li>

          <li>
            <i class="fa fa-hand-o-right"></i> Hotjar: an analytics tool we use
            to understand your use of our Website.
          </li>

          <li>
            <i class="fa fa-hand-o-right"></i> MailChimp: used to send
            administration of service emails such as email verification, product
            updates, password reset and welcome email; and other potential
            marketing messages where you have given your explicit consent for us
            to do so.
          </li>

          <li>
            <i class="fa fa-hand-o-right"></i> Mixpanel: tracks user
            interactions with web and mobile applications and provides tools for
            targeted communication with them. Data collected is used to build
            custom reports and measure user engagement and retention.
          </li>

          <li>
            <i class="fa fa-hand-o-right"></i> Stripe: used by us to process
            online payments.
          </li>
        </ul>

        <h3 class="xfont-[700] xmb-[5px]">
          Your Access to and Control Over Information
        </h3>

        <p>
          You may opt out of any future contacts from us at any time. You can do
          the following at any time by contacting us via the email address given
          on our website:
        </p>

        <ul class="!xlist-disc xmb-[10px]">
          <li>
            <i class="fa fa-hand-o-right"></i> See what data we have about you,
            if any.
          </li>
          <li>
            <i class="fa fa-hand-o-right"></i> Ensure that your personal
            information is updated and correct.
          </li>
          <li>
            <i class="fa fa-hand-o-right"></i> Change/correct any data we have
            about you.
          </li>
          <li>
            <i class="fa fa-hand-o-right"></i> Ask us to stop processing your
            personal data at any time.
          </li>
          <li>
            <i class="fa fa-hand-o-right"></i> Request to provide you a copy of
            all your personal data.
          </li>
          <li>
            <i class="fa fa-hand-o-right"></i> Have us delete any data we have
            about you.
          </li>
          <li>
            <i class="fa fa-hand-o-right"></i> Express any concern you have
            about our use of your data.
          </li>
        </ul>

        <p>
          If you are a registered user of our Services, we may provide you with
          tools and account settings to access or modify the personal
          information you provided to us and associated with your account. The
          profile information that we receive from Slack can be modified through
          your Slack account, our records will be updated when you sync your
          team information in your Team Settings and none of the previous
          information will be saved. You can also permanently delete your
          {{ appName }} account by emailing us at {{ support_email }}.
        </p>

        <p>
          Please note that {{ companyName }} will not charge you in any request
          we receive to exercise any of your rights stated above.
        </p>

        <h3 class="xfont-[700] xmb-[5px]">Security over the internet</h3>

        <p>
          No data transmission over the internet or website can be guaranteed to
          be secure from intrusion. However, we maintain commercially reasonable
          physical, electronic and procedural safeguards to protect your
          personal information in accordance with data protection legislative
          requirements.
        </p>

        <p>
          All information you provide to us is stored on our secure servers, and
          accessed and used subject to our security policies and standards.
        </p>

        <p>
          Sensitive information between your browser and our Website is
          transferred in encrypted form using Secure Socket Layer (“SSL”). When
          transmitting sensitive information, you should always make sure that
          your browser can validate the {{ appName }}
          certificate.
        </p>

        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although {{ companyName }} will do its best to
          protect your personal data, we cannot guarantee the security of your
          data transmitted to our Website or through our Services. Any
          transmission is at your own risk. Once we have received your
          information, we will use strict procedures and security features in an
          effort to prevent any unauthorized access.
        </p>

        <h3 class="xfont-[700] xmb-[5px]">Changes to this Policy</h3>

        <p>
          We may revise this Privacy Policy from time to time. The most current
          version of the policy will govern our use of your information and will
          always be at
          {{ endUserAggrementLink }}. If we make a change to this policy that,
          in our sole discretion, is material, we will notify you via email to
          the email address associated with your account. By continuing to
          access or use the Services after those changes become effective, you
          agree to be bound by the revised Privacy Policy.
        </p>

        <h3 class="xfont-[700] xmb-[5px]">Questions and Concerns</h3>

        <p>
          If you have questions or concerns about this Privacy Policy or
          information handling processes, you may contact us at the following
          addresses:
        </p>

        <p>
          {{ companyName }} LLC<br />
          2430 Victory Park Lane, #2601<br />
          Dallas, TX 75219<br />
          +1-443-637-2749<br /><br />

          {{ support_email }}
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("appconfigs", ["support_email"]),
    endUserAggrementLink() {
      return `${window.location.origin}/privacy-policy`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
