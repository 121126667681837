<template>
  <v-card flat>
    <v-card-text class="xflex xflex-col xjustify-center xitems-center">
      <KirbyDateTimePicker
        v-model="datetime"
        :min="min"
        :max="max"
        has-buttons
      ></KirbyDateTimePicker>
      <pre>{{ datetime }}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

import KirbyDateTimePicker from "@/views/GlobalComponents/Forms/KirbyDateTimePicker.vue";
export default {
  components: { KirbyDateTimePicker },
  data() {
    return {
      min: "2022-07-21 11:05:00",
      max: "2022-07-22 13:14:00",
      datetime: "2022-07-21 13:12:08",
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
