<template>
  <v-card flat min-height="100vh" class="xp-[1em]" color="transparent">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">
        App Configuration
      </h1>
    </v-card-title>
    <v-card flat>
      <v-card-text>
        <EachSetting
          title="App Number Of Days Trial"
          subtitle="Set number of days as FREE trial for newly registered workspace/team"
        >
          <template v-slot:left>
            <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
              Set number of days as FREE trial for newly registered
              workspace/team.<br />
              Value should be from 1 to 100 days
            </div>
          </template>
          <template v-slot:allright>
            <app-input
              type="number"
              min="0"
              max="100"
              class="mb-2 xmax-w-[300px] mx-auto"
              :rules="[requiredRule(), minMaxRule(1, 100)]"
              v-model="app_trial_days"
            />
          </template>
        </EachSetting>
        <v-divider />
        <EachSetting
          title="Default Global Project"
          subtitle="Set default project/task for newly registered workspace/team"
        >
          <template v-slot:left>
            <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
              Set default project/task for newly registered workspace/team
            </div>
          </template>
          <template v-slot:allright>
            <app-input
              v-model="default_global_project_task"
              class="mb-2 xmax-w-[300px] mx-auto"
              :rules="[requiredRule()]"
            />
          </template>
        </EachSetting>
        <v-divider />
        <EachSetting
          title="Number Of Member To Notify"
          subtitle="Set number of member to notify for random notification"
        >
          <template v-slot:left>
            <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
              Set number of member to notify for random notification <br />
              Value should be from 1 to 50
            </div>
          </template>
          <template v-slot:allright>
            <app-input
              type="number"
              min="1"
              max="100"
              class="mb-2 xmax-w-[300px] mx-auto"
              :rules="[requiredRule(), minMaxRule(1, 50)]"
              v-model="number_of_member_to_notify"
            />
          </template>
        </EachSetting>
        <v-divider />
        <EachSetting
          title="Minute To Respond"
          subtitle="Set number of minutes to respond to notification"
        >
          <template v-slot:left>
            <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
              Set number of minute to respond to notification <br />
              Value should be from 1 minute to 60 minutes
            </div>
          </template>
          <template v-slot:allright>
            <app-input
              type="number"
              min="1"
              max="100"
              class="mb-2 xmax-w-[300px] mx-auto"
              :rules="[requiredRule(), minMaxRule(1, 60)]"
              v-model="minute_to_respond"
            />
          </template>
        </EachSetting>
        <v-divider />
        <EachSetting title="Developers" subtitle="Set user ids for developers">
          <template v-slot:left>
            <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
              Set user ids for developers <br />
              Value should be comma separated strings. Developers can receive
              error logs to their Slack.<br /><br />
              Users that added on developers configuration DOES NOT give access
              to the admin area pages.<br /><br />
              To give access to admin area a user must assign a Super Admin Role
              manually via database. In <i>model_has_roles</i> table just insert
              a new entry (role_id: 1, model_type:'App\Models\User', model_id:
              'USER_ID') and to remove access just delete the entry.
            </div>
          </template>
          <template v-slot:allright>
            <app-input
              type="text"
              class="mb-2 xmax-w-[300px] mx-auto"
              :rules="[requiredRule()]"
              v-model="developers"
            />
          </template>
        </EachSetting>
        <v-divider />
        <EachSetting title="Support Email" subtitle="Set support email">
          <template v-slot:left>
            <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
              Set support email <br />
              Value should be a valid email address
            </div>
          </template>
          <template v-slot:allright>
            <app-input
              type="email"
              class="mb-2 xmax-w-[300px] mx-auto"
              :rules="[requiredRule(), emailRule()]"
              v-model="support_email"
            />
          </template>
        </EachSetting>
      </v-card-text>
      <v-divider />
      <v-card-actions
        class="xw-full xflex xflex-col xmy-2 xjustify-end xitems-center"
      >
        <v-btn
          :loading="submitting"
          :disabled="submitting"
          @click="saveChanges"
          class="text-none px-5"
          color="primary"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- <pre>{{ payload }}</pre> -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EachSetting from "../../AppDashboard/Settings/components/EachSetting.vue";
export default {
  data() {
    return {
      submitting: false,
      tabs: [{ name: "App", to: { name: "admin-configs" } }],
    };
  },
  computed: {
    ...mapGetters("appconfigs", [
      "configs",
      "default_global_project_task",
      "appConfigBy",
    ]),
    app_trial_days: {
      get() {
        return this.appConfigBy("app_trial_days");
      },
      set(val) {
        this.set_config({
          key: "app_trial_days",
          data: parseInt(val),
        });
      },
    },
    default_global_project_task: {
      get() {
        return this.appConfigBy("default_global_project_task");
      },
      set(val) {
        this.set_config({
          key: "default_global_project_task",
          data: val,
        });
      },
    },
    number_of_member_to_notify: {
      get() {
        return this.appConfigBy("number_of_member_to_notify");
      },
      set(val) {
        this.set_config({
          key: "number_of_member_to_notify",
          data: parseInt(val),
        });
      },
    },
    minute_to_respond: {
      get() {
        return this.appConfigBy("minute_to_respond");
      },
      set(val) {
        this.set_config({
          key: "minute_to_respond",
          data: parseInt(val),
        });
      },
    },
    developers: {
      get() {
        return this.appConfigBy("developers");
      },
      set(val) {
        this.set_config({
          key: "developers",
          data: val,
        });
      },
    },
    support_email: {
      get() {
        return this.appConfigBy("support_email");
      },
      set(val) {
        this.set_config({
          key: "support_email",
          data: val,
        });
      },
    },
    payload() {
      return {
        app_trial_days: this.app_trial_days,
        default_global_project_task: this.default_global_project_task,
        number_of_member_to_notify: this.number_of_member_to_notify,
        minute_to_respond: this.minute_to_respond,
        developers: this.developers,
        support_email: this.support_email,
      };
    },
  },
  created() {
    this.fetchConfigs();
  },
  methods: {
    ...mapMutations("appconfigs", [
      "set_config",
      "set_configs",
      "set_originals",
    ]),
    ...mapActions("appconfigs", ["fetchConfigs"]),
    saveChanges() {
      this.submitting = true;
      this.$axios
        .put(`api/configs`, this.payload)
        .then(({ data }) => {
          this.set_configs(data.configs || []);
          this.set_originals(data.configs || []);
          this.appToast("Configs successfully updated.", "success");
        })
        .finally(() => (this.submitting = false));
    },
  },
  components: { EachSetting },
};
</script>

<style lang="scss" scoped></style>
