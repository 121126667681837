<template>
  <v-card
    min-height="100vh"
    flat
    color="transparent"
    class="xw-full"
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Projects</h1>
    </v-card-title>
    <router-view :key="$route.fullPath"></router-view>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return { counts: 0 };
  },
  created() {
    this.$event.$on("project-report-count", (counts) => {
      this.counts = counts;
    });
    this.$event.$on("delete-project", (project) => {
      this.appConfirmation(
        "Delete this project? This cannot be undone.",
        () => {
          this.$axios.delete(`api/projects/${project.id}`).then(({ data }) => {
            this.$event.$emit("remove-project", project);
            this.deleteProject(project);
            this.appToast(data.message, "success");
          });
        }
      );
    });
  },
  computed: {
    ...mapGetters(["user"]),
    tabs() {
      return [
        {
          name: "Projects",
          to: {
            name: "app-project-report-daily",
            params: { date: moment().format("YYYY-MM-DD") },
          },
          alias: [
            "app-project-report-daily",
            "app-project-report-monthly",
            "app-project-report-weekly",
          ],
          counts: this.counts,
        },
      ];
    },
  },
  mounted() {
    this.fetchWorkspaceRegions({ id: this.user.team_id });
    this.fetchWorkspaceSites({ id: this.user.team_id });
    this.fetchWorkspaceDataCenters({ id: this.user.team_id });
  },
  methods: {
    ...mapMutations("projects", ["deleteProject"]),
    ...mapActions("workspace", [
      "fetchWorkspaceRegions",
      "fetchWorkspaceSites",
      "fetchWorkspaceDataCenters",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
