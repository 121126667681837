<template>
  <v-autocomplete
    prepend-inner-icon="mdi-magnify"
    dense
    outlined
    clearable
    :no-data-text="noDataText"
    @click:clear="$emit('cleared')"
    v-model="selected"
    v-bind="$attrs"
    :placeholder="placeholder"
    hide-details="auto"
    :items="data"
    append-icon="mdi-chevron-down"
    :loading="fetching"
    :search-input.sync="search"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="returnObject"
  >
    <!-- <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-title> Searching for... {{ search }}</v-list-item-title>
      </v-list-item>
    </template> -->
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    returnObject: { type: Boolean, default: true },
    api: { type: String, default: undefined },
    placeholder: { type: String, default: "Search ..." },
    noDataText: {
      type: String,
      default: "No data found, Try another keyword...",
    },
    itemText: { type: String, default: "text" },
    itemValue: { type: String, default: "id" },
  },
  data() {
    return {
      search: null,
      selected: null,
      data: [],
      fetching: false,
    };
  },
  watch: {
    search: {
      handler: function (val) {
        val && this.fetch(val);
      },
      immediate: true,
    },
    selected: {
      handler: function (val) {
        val && this.$emit("selected", val);
      },
      immediate: true,
    },
  },
  methods: {
    fetch(val) {
      if (this.fetching || !this.api) return;
      this.fetching = true;
      this.$axios
        .get(`${this.api}?keyword=${val}`)
        .then(({ data }) => {
          this.data = data;
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
