<template>
  <div
    class="xh-min-screen m-0 p-0 xflex xflex-row xjustify-between xitems-stretch xw-full"
  >
    <div
      class="xw-6/12 xp-3 xflex xflex-col xmin-h-screen xbg-black xtext-white"
    >
      <label>Response:</label>
      <pre
        class="xbreak-words xoverflow-x-auto xwhitespace-pre-wrap xtext-white"
      >
      {{ response }} 
      </pre>
    </div>
    <div
      class="xw-6/12 xp-3 xflex sticky xflex-col min-h-screen xbg-blue-800 xbg-opacity-70 xitems-center xjustify-center"
    >
      <v-btn
        small
        class="text-none"
        color="primary"
        @click="$router.push({ name: 'test-slash' })"
      >
        Go to Slash Command Test
      </v-btn>
      <div class="xflex xw-full xflex-col mb-3 px-5 xtext-white">
        <label>Select user</label>
        <v-select
          hide-details="auto"
          outlined
          dense
          color="white"
          class="xtext-white"
          :items="users"
          v-model="user_id"
        ></v-select>
      </div>
      <div class="xflex xw-full xflex-col mb-3 px-5 xtext-white">
        <label>Select Action</label>
        <v-select
          hide-details="auto"
          outlined
          dense
          color="white"
          class="xtext-white"
          :items="['menu', 'shiftextension']"
          v-model="action_id"
        ></v-select>
      </div>
      <div class="xflex xw-full xflex-col mb-3 px-5 xtext-white">
        <label>Select command</label>
        <v-select
          hide-details="auto"
          outlined
          dense
          color="white"
          class="xtext-white"
          :items="commands"
          v-model="command"
        ></v-select>
      </div>
      <v-btn :loading="submitting" color="primary" @click="sendTest">
        Submit
      </v-btn>
      <div class="flex pa-2">
        <label>Payload:</label>
        <pre
          class="xbreak-words xoverflow-x-auto xwhitespace-pre-wrap xtext-white"
        >
        {{ payload }} 
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commands: [
        "help",
        "clocking_project",
        "whosonwork",
        "extended",
        "ignored",
      ],
      users: [
        { text: "kirbycrypto12", value: "U03GG31LFDM" },
        { text: "kyraminerva", value: "U03J2MTCTJ4" },
      ],
      team_id: "T03GTNSAD61",
      command: "help",
      action_id: "menu",
      response: null,
      submitting: false,
      user_id: null,
      username: null,
    };
  },
  watch: {
    user_id: {
      handler: function (val) {
        this.username = val
          ? this.users.find((i) => i.value === val).text || null
          : null;
      },
      immediate: true,
    },
  },
  computed: {
    payload() {
      return {
        type: "block_actions",
        user: {
          id: this.user_id,
          username: this.username,
          name: this.username,
          team_id: this.team_id,
        },
        api_app_id: "A02",
        token: "Shh_its_a_seekrit",
        container: {
          type: "message",
          text: "The contents of the original message where the action originated",
        },
        trigger_id: "12466734323.1395872398",
        team: {
          id: this.team_id,
          domain: "cryptogeeksworld",
        },
        enterprise: null,
        is_enterprise_install: false,
        state: {
          values: {},
        },
        response_url: "https://eoafnl6yxi4y8ta.m.pipedream.net",
        actions: [
          {
            type: "button",
            block_id: "=cY",
            action_id: this.action_id,
            text: {
              type: "plain_text",
              text: "Approve",
              emoji: true,
            },
            value: this.command,
            style: "primary",
            action_ts: "1654878867.613482",
          },
        ],
      };
    },
  },
  created() {
    this.$auth.refreshCsrfToken();
  },
  methods: {
    sendTest() {
      this.submitting = true;
      this.$axios
        .post("api/webhook/slack-hook", this.payload)
        .then(({ data }) => {
          this.response = data || "OK";
        })
        .catch((err) => (this.response = err))
        .finally(() => (this.submitting = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
