<template>
  <v-card color="transparent" flat class="!xbg-transparent">
    <v-card-text class="xflex xflex-col xgap-y-[1em]">
      <Collapse
        class="xmb-1"
        title="What to do after successfully added Mesasix Time Tracker to my Slack workspace?"
      >
        <template v-slot:default>
          <div class="xw-fit xoverflow-hidden xflex xflex-col xgap-y-[0.5em]">
            <p class="xtext-[14px] xtext-[#e46464]">
              The following steps can be done by your Slack workspace Owner and
              Admins.
            </p>
            <p>
              Check our Getting Started with {{ appName }}
              <router-link tag="a" :to="{ name: 'getting_started' }">
                here
              </router-link>
            </p>
          </div>
        </template>
      </Collapse>
      <Collapse :title="`How does ${appName} work?`">
        <template v-slot:default>
          <div class="xw-full xflex xflex-col xitems-center xjustify-center">
            <p>
              {{ appName }} tracks and displays total work time based on the
              shift instead of calendar date. The total work time starts at
              clock in <chip-in small label text="/in"></chip-in> and ends at
              clock out <chip-out label small text="/out"></chip-out> . However,
              if you manually clock out or have been automatically clocked out
              and clocked in again before 12:00 midnight, the accumulated time
              will be added to the total work time of the current shift.
            </p>
            <div>
              When new members are added in your Slack team, simply follow the
              following steps:
              <ul>
                <li>
                  1. Invite them to join a dedicated Slack channel for clocking
                  or they can also clock in on their Slackbot or any other
                  channel whichever your team prefers.
                </li>
                <li>
                  2. Then add them to the allowed members in {{ appName }}'s
                  dashboard
                </li>
                <li>
                  3. Go to: <b>Settings > All Users</b> or on
                  <b>Billing > Manage Paying User</b>
                </li>
                <li>
                  4. Check <b>"Add to Paying"</b> who you want to use
                  {{ appName }}.
                </li>
                <li>5. Click the <b>"Save Changes"</b> button.</li>
              </ul>
              They can now start performing the clocking activities.
            </div>
            <p>
              The date and time are displayed based on the user's Slack account
              time zone or based on the time zone that is set in the Preferences
              tab.
            </p>

            <img :src="image1" class="xw-full xmx-auto xaspect-auto" />

            <p>
              Visit
              <router-link :to="{ name: 'home', hash: '#features' }">
                features
              </router-link>
              for more details.
            </p>
          </div>
        </template>
      </Collapse>
      <Collapse
        class="xmb-1"
        title="Is Mesasix Time Tracker online payment safe?"
      >
        <p>
          Yes, security is our top priority. We don’t store your credit card
          details, and our online payment uses Stripe to secure the payment
          transaction. You can read more about
          <span>
            <a
              href="https://stripe.com/docs/security"
              target="_blank"
              rel="noreferrer"
              class="xinline"
            >
              Stripe’s Security </a
            >.
          </span>
        </p>
      </Collapse>
      <Collapse title="Can I add time?">
        <template v-slot:default>
          Yes! You can add time by:
          <ul>
            <li>1. Sign in.</li>
            <li>2. Go to My Record</li>
            <li>
              3. Click the Add Time button and a modal form will be shown.
            </li>
            <li>4. Fill in the form</li>
            <li>5. Click the "Submit" or "Get Approval" button.</li>
          </ul>
        </template>
      </Collapse>

      <Collapse
        class="xmb-1"
        title="I added a time tracking channel but I can't see it in my channel list at Settings. What should I do?"
      >
        <template v-slot:default>
          Follow the following steps:
          <ul>
            <li>1. Sign in</li>
            <li>2. Go to "Settings"</li>
            <li>3. Go to "Preferences" tab</li>
            <li>
              4. On the <b>Set Time Tracking Channels</b> Click on the
              <v-icon color="primary">mdi-slack</v-icon> "Sync to Slack" button.
            </li>
            <li>5. Look for the channel in the channel list and select it.</li>
            <li>6. Click "Update Settings" button</li>
          </ul>
        </template>
      </Collapse>
      <Collapse
        class="xmb-1"
        title="Some team members forget to clock in. What can I do?"
      >
        <template v-slot:default>
          You can set when your shift start and the Mesasix bot will remind each
          users to clock in. To do so, follow the following steps:
          <ul>
            <li>1. Sign in</li>
            <li>2. Go to "Settings"</li>
            <li>3. Go to "Preferences" tab</li>
            <li>4. Look for "Notify User to Clock In (Timezone)"</li>
            <li>5. Specify desired time</li>
            <li>6. Click "Update Settings" button</li>
          </ul>
        </template>
      </Collapse>
      <Collapse title="Can team members edit their own time?">
        <template v-slot:default>
          Yes! Tell your team members to follow the following steps:
          <ul>
            <li>1. Sign in</li>
            <li>2. Go to "My Record"</li>
            <li>
              3. Click any progress bar and click "Edit" button, a modal will
              appear
            </li>
            <li>4. Adjust time</li>
            <li>6. Click the "Submit" or "Save Changes" button</li>
            <li>
              7. Continue editing and when you're done, Go to "Pending Edits"
              tab and submit their changes for approval
            </li>
            <li>8. A Slack notification will be sent to team admins</li>
            <li>9. Wait for any of team admins to approve the time change</li>
          </ul>
        </template>
      </Collapse>

      <Collapse title="How do I edit my team member's time?">
        <template v-slot:default>
          <blockquote>
            In order to edit your team member's time:
            <ul>
              <li>
                1. Sign in to
                <router-link :to="{ name: 'login' }">
                  <span>{{ appName }}</span>
                </router-link>
              </li>
              <li>2. Go to {{ appName }}'s dashboard</li>
              <li>3. Go to Member Report</li>
              <li>
                4. Select team members who you want to edit the time and specify
                the date.
              </li>
              <li>5. Click the "View Log Details" from dropdown.</li>
              <li>
                6. Click one of the strips in the progress bar and click "Edit".
              </li>
              <li>7. Edit your team member's time.</li>
              <li>8. Click the "SAVE CHANGES" button.</li>
            </ul>
            <br /><br />If you don't see any changes. Please reload the page.
          </blockquote>
        </template>
      </Collapse>
      <Collapse title="Where can I contact for support?">
        <template v-slot:default>
          <div>
            You can email us at
            <a :href="`mailto:${support_email}`"> {{ support_email }} </a>.
            <p class="xmt-[10px]">
              If {{ appName }} was installed on your Slack workspace, You can
              also contact us using
              <span class="xtext-[#ef4444]">/mesasix feedback </span> slash
              command in your Slack.
            </p>
            <p class="xmt-[10px]">
              You can also submit us a message using below contact form
            </p>
          </div>
        </template>
      </Collapse>
      <div
        class="xflex xflex-col xjustify-center xitems-center"
        v-if="!load_more"
      >
        <v-btn
          @click="load_more = !load_more"
          large
          text
          color="#000"
          depressed
          class="text-none xfont-[700] xtext-[20px] xleading-[160%]"
        >
          Show More
        </v-btn>
      </div>

      <Collapse title="What is a selected or allowed member?" v-if="load_more">
        <template v-slot:default>
          A member added to the list of allowed users to use the time tracker.
          Only admins and owners or those who have granted permission to update
          team subscription can add and remove a member to the list.
        </template>
      </Collapse>
      <Collapse title="When is the billing date?" v-if="load_more">
        <template v-slot:default>
          <p>
            We bill you at the beginning of every monthly billing cycle. Your
            bill amount depends on the number of selected members in your
            {{ appName }} team account. If the number of selected members
            increase/decrease in the middle of a billing cycle, the new payment
            will take effect for the rest of the cycle.
          </p>
        </template>
      </Collapse>
      <Collapse
        class="xmb-1"
        title="What happens if I don't find the service useful?"
        v-if="load_more"
      >
        <template v-slot:default>
          <p>
            You can cancel your account any time in your
            <b class="xinline-block">Billing > Cancel Subscription</b> or you
            can also email us at <i> {{ support_email }} </i>. You can also send
            a support ticket using below Contact Us form.
          </p>
        </template>
      </Collapse>
      <Collapse
        class="xmb-1"
        title="Do I get a refund if I cancel my subscription while in the middle of billing period?"
        v-if="load_more"
      >
        <template v-slot:default>
          No, but we offer a {{ app_trial_days | 7 }} day FREE trial. After that
          you will have to subscribe to one of our core plans and continue using
          the time tracker until your monthly billing subscription expires.
        </template>
      </Collapse>
      <Collapse
        class="xmb-1"
        title="I cancelled my subsciption, how do I subscribe again?"
        v-if="load_more"
      >
        <template v-slot:default>
          For you to subscribe again, follow the following steps:
          <ul>
            <li>1. Go to https://timetracker.mesasix.com</li>
            <li>2. Sign in through Slack</li>
            <li>3. Go to "Settings"</li>
            <li>4. Click on the "Payment Details" button.</li>
            <li>5. Click the "Subscribe" button.</li>
            <li>6. Fill up the neccessary fields.</li>
            <li>7. Click the "Pay" button.</li>
          </ul>
        </template>
      </Collapse>
      <Collapse
        class="xmb-1"
        title="I can't use /in, /out, etc even though the app is installed."
        v-if="load_more"
      >
        <template v-slot:default>
          You should re-register your team in our app again. Note: Only Admins
          and Owners should register because some features may not be available
          for ordinary users. If you're an admin or an owner, follow the
          following steps:
          <ul>
            <li>1. Go to https://[yourdomain].slack.com/apps/manage</li>
            <li>2. Remove Mesasix Time Tracker from your apps list</li>
            <li>3. Go to https://timetracker.mesasix.com/team/registration</li>
            <li>4. Accept the terms and condition.</li>
            <li>5. Click the "Add to Slack" button.</li>
            <li>
              6. You will be asked to sign in to your team if you're not yet
              signed in.
            </li>
            <li>7. Click the "Authorize" button.</li>
            <li>8. Click the "Proceed" button.</li>
            <li>9. You will be redirected to your Time Tracker Dashboard.</li>
          </ul>
        </template>
      </Collapse>
      <Collapse title="Does it cost money to use this app?" v-if="load_more">
        <template v-slot:default>
          <div class="xw-full xflex xflex-col xitems-start xjustify-start">
            <p class="text-left">
              Adding {{ appName }} to Slack is free and does not require a
              credit card, but to continue using the app commands after more
              than {{ app_trial_days || 7 }} days, you'll need to pick a plan.
              Below are the subscription plan details.
            </p>
            <p class="text-left">
              You will be charged $2.00 per selected team member per month.
            </p>
            <h2 class="text-left">How much does {{ appName }} cost?</h2>
            <v-simple-table class="xmy-[0.5em] xw-full">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Subscription</th>
                    <th class="text-center">Plan Cost Per Selected Member</th>
                    <th class="text-left">Limitation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TEAM TRIAL</td>
                    <td align="center">$0.00</td>
                    <td>{{ app_trial_days || 7 }} Day Trial</td>
                  </tr>
                  <tr>
                    <td>TEAM BASIC</td>
                    <td align="center">$2.00</td>
                    <td>Monthly</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="xtext-center xw-full">
              Visit
              <router-link :to="{ name: 'home', hash: '#pricing' }">
                pricing
              </router-link>
              for more details.
            </p>
          </div>
        </template>
      </Collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Collapse from "../Collapse.vue";
export default {
  components: { Collapse },
  data() {
    return {
      load_more: false,
      image1: require("@/assets/features/utc-to-user-timezone.png"),
      preferenceImg: require("@/assets/images/preferences.png"),
      payingImg: require("@/assets/images/add-to-paying.png"),
    };
  },
  computed: {
    ...mapGetters("appconfigs", ["app_trial_days", "support_email"]),
  },
};
</script>

<style lang="scss" scoped></style>
