import Vue from 'vue'
import store from '@/stores/'

const permissions = {
    user() {
        return store.getters.authenticated ? store.getters.user : null
    },
    is_super_admin() {
        return this.user() && this.user().is_super_admin ? true : false
    },
    permissions() {
        return store.getters['permissions/permissions'] || [];
    },
    userPermissions() {
        if (!this.user()) return [];
        return this.permissions()
    },
    and(permissions) {
        if (!this.user()) return false;
        if (this.user().is_super_admin) return true
        if (!permissions) return true;
        let permissions_split = permissions.split('|');
        return permissions_split.every((i) => this.userPermissions().includes(i))
    },
    or(permissions) {
        if (!this.user()) return false;
        if (this.user().is_super_admin) return true
        if (!permissions) return true;
        let permissions_split = permissions.split('|');
        return permissions_split.some((i) => this.userPermissions().includes(i))
    },
    fetchPermissions: (cb) => {
        store.dispatch('permissions/fetchPermissions')
            .then(() => {
                if (typeof cb === 'function') cb()
            })
    },
};

Vue.prototype.$can = permissions

export default permissions