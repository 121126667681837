<template>
  <Modal
    v-model="open"
    valid
    title="Success!"
    class="xrounded-lg"
    :fullscreen-on-mobile="false"
    :max-width="maxWidth"
    :has-footer="false"
    @cancelled="closed"
    :has-header="false"
  >
    <template v-slot:whole-content>
      <section>
        <div class="rt-container">
          <div class="col-rt-12">
            <div class="Scriptcontent">
              <!-- partial:index.partial.html -->
              <div id="card" class="animated fadeIn !xw-full xflex xflex-col">
                <div
                  id="upper-side"
                  class="xflex xflex-col xjustify-center xitems-center xw-full"
                >
                  <v-icon size="150" color="white">
                    mdi-check-circle-outline
                  </v-icon>
                  <h3 id="status" class="xmx-auto">Success</h3>
                </div>
                <div id="lower-side">
                  <p class="xtext-[18px] xtext-[#000]">
                    Congratulations, {{ appName }} has been successfully added to your Slack workspace.
                  </p>
                  <v-btn @click="closed" class="text-none" id="contBtn">
                    Continue
                  </v-btn>
                </div>
              </div>
              <!-- partial -->
            </div>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/views/GlobalComponents/Modals/Modal.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: { Modal },
  data() {
    return {
      maxWidth: 800,
    };
  },
  computed: {
    ...mapGetters(["newInstall"]),
    open: {
      get() {
        return this.newInstall;
      },
      set(val) {},
    },
  },
  methods: {
    ...mapMutations(["set_newInstall", "set_step1", "set_step2"]),
    closed() {
      this.set_newInstall(false);
      this.set_step1(false);
      this.set_step2(false);
      this.$router.push({ name: "getting_started" });
    },
  },
};
</script>

<style lang="css" scoped>
#card {
  position: relative;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
}

#upper-side {
  padding: 2em;
  background-color: #6a2c91;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

#checkmark {
  font-weight: lighter;
  fill: #fff;
}

#status {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 2em;
  margin-top: 0.2em;
  margin-bottom: 0;
}

#lower-side {
  padding: 2em 2em 5em 2em;
  background: #fff;
  display: block;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#message {
  margin-top: -0.5em;
  color: #757575;
  letter-spacing: 1px;
}

#contBtn {
  position: relative;
  top: 1.5em;
  text-decoration: none;
  background: #6a2c91;
  color: #fff;
  margin: auto;
  padding: 0.8em 3em;
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  border-radius: 25px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#contBtn:hover {
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
</style>
