<template>
  <div class="xflex xflex-row xjustify-start xgap-x-3 xitems-center">
    <div
      class="xflex xflex-col xw-4/12 xpx-[10px] xjustify-center xtext-left xitems-stretch"
    >
      <slot name="left">
        <label class="xtext-[15px] xtext-[#2e2e2e] xfont-semibold">{{ label }}</label>
      </slot>
    </div>
    <div class="xflex xflex-col xflex-1 xjustify-center xitems-stretch">
      <slot name="right">
        <app-input
          v-bind="$attrs"
          :value="value"
          :text-align="textAlign"
          @input="$emit('input', $event)"
          class="xw-full !xrounded-0"
        ></app-input>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: { type: String, default: null },
    value: { type: [String, Number, Object], default: null },
    textAlign: { type: String, default: "left" },
  },
};
</script>

<style lang="scss" scoped></style>
