import request from '@/plugins/requests'
import httpBuildQuery from 'http-build-query';

export default {
    namespaced: true,
    state: {
        userPermissions: []
    },
    getters: {
        permissions: (state) => state.userPermissions,
    },
    mutations: {
        set_permissions: (state, payload) => (state.userPermissions = payload)
    },
    actions: {
        fetchPermissions: ({ commit }) => {
            request.get('sanctum/csrf-cookie')
                .then(() => {
                    request.get('api/auth/permissions')
                        .then(({ data }) => {
                            commit('set_permissions', data.permissions)
                            commit('roles/set_managed_sites', data.managed_sites, { root: true })
                            commit('roles/set_managed_regions', data.managed_regions, { root: true }) 
                        })
                })
        },
    }
}