import Index from '@/views/HomeArea/Index.vue';
import Home from '@/views/HomeArea/Home.vue';
import Legal from '@/views/HomeArea/Legal.vue';
import InstallApp from '@/views/HomeArea/InstallApp.vue'
import Reauthentication from '@/views/HomeArea/Reauthentication.vue'
import ForgotPassword from '@/views/HomeArea/Auth/ForgotPassword.vue'
import SetPassword from '@/views/HomeArea/Auth/SetPassword.vue'
import GettingStarted from '@/views/HomeArea/GettingStarted.vue'

export const homeRoutes = {
    path: '/',
    component: Index,
    children: [
        {
            path: '/',
            component: Home,
            name: 'home',
            meta: { homes: true }
        },
        {
            path: 'features',
            component: Home,
            name: 'features',
            meta: { homes: true }
        },
        {
            path: 'pricing',
            component: Home,
            name: 'pricing',
            meta: { homes: true }
        },
        {
            path: 'support',
            component: Home,
            name: 'support',
            meta: { homes: true }
        },
        {
            path: 'copyright-policy',
            component: Legal,
            name: 'copyright-policy',
            meta: { homes: true }
        },
        {
            path: 'privacy-policy',
            component: Legal,
            name: 'privacy-policy',
            meta: { homes: true }
        },
        {
            path: 'terms',
            component: Legal,
            name: 'terms',
            meta: { homes: true }
        },
        {
            path: 'login',
            component: Home,
            name: 'login',
            meta: { homes: true }
        },
        {
            path: '/team/registration',
            component: InstallApp,
            name: 'install-app',
            meta: { auth: false, homes: true }
        },
        {
            path: '/reauthenticate/:team',
            component: Reauthentication,
            name: 'reauthenticate',
            meta: { auth: true, exact: "view_team_info", homes: true }
        },
        // {
        //     path: '/forgot-password',
        //     component: ForgotPassword,
        //     name: 'forgot_password',
        //     meta: { auth: false, homes: true }
        // },
        // {
        //     path: '/set-password/:email/:token',
        //     component: SetPassword,
        //     name: 'set_password',
        //     meta: { auth: false, homes: true }
        // },
        {
            path: 'getting-started',
            component: GettingStarted,
            name: 'getting_started',
            meta: { homes: true }
        },

    ],
    meta: {
        auth: false,
        homes: true
    }
}


